import React from 'react';
import EmptyMediaContent from './EmptyMediaContent';

import styles from './MediaContainer.module.scss';
import MediaUploader from './MediaUploader';

interface IProps {
  type: 'video' | 'image';
  mode: 'view' | 'edit';
  url?: string;
  className?: string;
  isLoadingUploadImage?: boolean;
  isLoadingDeleteImage?: boolean;
  isLoadingUploadVideo?: boolean;
  onUploadImage?: (FileList: FileList | null) => void;
  onUploadVideo?: (url?: string) => void;
  onChangeVideoUrl?: (url: string) => void;
  onDelete?: () => void;
  onCancel?: () => void;
}

const MediaContainer = ({
  type,
  mode,
  url,
  className,
  isLoadingUploadImage,
  isLoadingDeleteImage,
  onDelete,
  onUploadImage,
  isLoadingUploadVideo,
  onUploadVideo,
  onCancel
}: IProps) => {
  const getMedia = () => {
    if (type === 'video') {
      return (
        <iframe
          className={styles.commnStyles}
          title="video"
          src={url?.replace("watch?v=", "embed/")}
          allowFullScreen
        ></iframe>
      )
    } else {
      return <img className={styles.commnStyles} src={url} alt="img" />
    }
  }

  return (
    <div className={`${styles.mediaWrapper} ${className}`}>
      {url
        ? getMedia()
        : <EmptyMediaContent type={type} mode={mode} />}

      {mode === 'edit' &&
        <MediaUploader
          type={type}
          url={url}
          isLoadingUploadImage={isLoadingUploadImage}
          isLoadingDeleteImage={isLoadingDeleteImage}
          onDelete={onDelete}
          onUploadImage={onUploadImage}
          onUploadVideo={onUploadVideo}
          onCancel={onCancel}
          isLoadingUploadVideo={isLoadingUploadVideo}
        />
      }
    </div>
  )
}

export default MediaContainer;
