import React from 'react';
import {Dialog} from '../../../components/common';
import InviteDialogContent from './InviteDialogContent';
import InviteDialogTitle from './InviteDialogTitle';

interface IProps {
  inviteCode: string;
  isOpen: boolean;
  onClose: () => void;
}

const InviteDialog = (props: IProps) => {
  const {isOpen, inviteCode, onClose} = props;

  return (
    <Dialog
      isOpen={isOpen}
      showCloseIcon
      onClose={onClose}
      dialogTitle={<InviteDialogTitle />}
      dialogContent={<InviteDialogContent inviteCode={inviteCode}/>}
    />
  )
}

export default InviteDialog;
