import { observer } from "mobx-react-lite";
import React from "react";
import { Avatar, Button, Loader } from "../..";
import strings from "../../../../../i18n/strings";
import AvatarsStore from "../../../../store/AvatarsStore";

import styles from "./AvatarDialog.module.scss";

interface IProps {
  onSave: (url: string) => void;
  onClose: () => void;
  currentAvatar: string;
}

const AvatarDialogContent = observer(
  ({ currentAvatar, onSave, onClose }: IProps) => (
    <div className={styles.contentWrapper}>
      {AvatarsStore.loading ? (
        <Loader />
      ) : (
        <>
          {AvatarsStore.items?.map((avatar) => (
            <div className={styles.avatar}>
              <Avatar
                url={avatar.url}
                currentUserAvatar={currentAvatar}
                onClick={() => onSave(avatar.url)}
              />
            </div>
          ))}
        </>
      )}

      <div className={styles.footerWrapper}>
        <Button onClick={onClose}>{strings.buttons.cancel}</Button>
      </div>
    </div>
  )
);

export default AvatarDialogContent;
