import {observer} from 'mobx-react-lite';
import React from 'react';
import strings from '../../../../i18n/strings';
import { Button, Dialog } from '../../../components/common';
import DialogsStore from '../../../store/DialogsStore';
import TeamMembersStore from '../../../store/TeamsStores/TeamMembersStore';

import styles from './StudentConfirmDeleteDialog.module.scss';

const StudentConfirmDeleteDialog = observer(() => (
  <Dialog
    isOpen={DialogsStore.isOpenStudentDeleteConfirmDialog()}
    onClose={DialogsStore.onClose}
    dialogTitle={<div className={styles.title}>{strings.teams.dialogs.confirm_delete_title}</div>}
    dialogContent={
      <div className={styles.bodyWrapper}>
        <div>{strings.teams.dialogs.confirm_delete_student_message}</div>

        <div className={styles.footerWrapper}>
          <Button onClick={DialogsStore.onClose}>
            {strings.buttons.cancel}
          </Button>

          <Button
            type="accent"
            width={150}
            loading={TeamMembersStore.loadingDelete}
            onClick={TeamMembersStore.onDeleteStudent}

          >
            {strings.teams.dialogs.delete_student}
          </Button>
        </div>
      </div>
    }
  />
))

export default StudentConfirmDeleteDialog;
