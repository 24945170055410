import { action, computed, makeAutoObservable, observable } from "mobx";
import strings from "../../../i18n/strings";
import { apiClient } from "../../api/api";
import { IFunsraiserGoals } from "../../interfaces/IFundraisers";
import EventStore from "../EventStore";
import SnackBarStore from "../SnackBarStore";

class FundraiserGoalsStore {
  @observable isEditMode: boolean = false;
  @observable public isLoading?: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public onSwitchGoalsEditMode = () => (this.isEditMode = !this.isEditMode);

  @action
  public onClosehGoalsEditMode = () => (this.isEditMode = false);

  @computed
  public updateGoals = async (data: IFunsraiserGoals) => {
    try {
      this.isLoading = true;
      const formData = new FormData();
      //@ts-ignore
      Object.keys(data).forEach((key) => formData.append(key, data[key]));
      await apiClient.updateFundraiserGoals(EventStore.eventId, formData);
      await EventStore.fetchEventData();
      this.onSwitchGoalsEditMode();
      SnackBarStore.setSnackbar(
        strings.fundraiser_goals.success_message,
        "success"
      );
    } catch (e) {
      console.error("FundraiserStore.updateGoals", e);
    } finally {
      this.isLoading = false;
    }
  };
}

export default new FundraiserGoalsStore();
