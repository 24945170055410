import { observer } from "mobx-react-lite";
import strings from "../../../../i18n/strings";
import { Button } from "../../../components/common";
import { ReactComponent as CheckIcon } from '../../../../images/icons/checkBig.svg';

import styles from './FinishForm.module.scss';
import { ReactComponent as DownloadIcon } from '../../../../images/icons/students/download.svg';
import { useEffect, useCallback } from "react";
import SignUpStore from "../../../store/Registration/SignUpStore";
import { useHistory } from "react-router";
import { Path } from "../../../consts/router";
import AppStore from "../../../store/AppStore";
import UserStore from "../../../store/UserStore";

const FinishForm = observer(() => {
  const history = useHistory();

  const onRouteToStart = useCallback(() => {
    history.push(Path.main);
    AppStore.setLayout();
  }, [history])

  const listener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onRouteToStart();
    }
  }, [onRouteToStart])

  useEffect(() => {
    SignUpStore.setSignUpStep(4);

    window.addEventListener('keypress', listener)

    return () => {
      window.removeEventListener('keypress', listener)
    };
  }, [listener])

  return (
    <div className={styles.formWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.bodyWrapper}>
          <CheckIcon />

          <div className={styles.title}>
            {strings.RegistrationForms.finish.title}
          </div>

          <div className={styles.description}>
            {strings.RegistrationForms.finish.description}
          </div>

          <a href={strings.RegistrationForms.finish.download_link_url} target="_blank" rel="noreferrer" className={styles.link}>
            <DownloadIcon fill={styles.blueIcon} />
            <span>{strings.RegistrationForms.finish.download_link_text}</span>
          </a>
        </div>

        <div className={styles.footerWrapper}>
          <img src={UserStore.user?.avatar} alt="" />

          <Button
            width={233}
            type="primary"
            onClick={onRouteToStart}
          >
            {strings.RegistrationForms.finish.get_started}
          </Button>
        </div>
      </div>
    </div>
  )
})

export default FinishForm;
