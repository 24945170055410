import {action, computed, makeAutoObservable, observable} from "mobx";
import strings from "../../../i18n/strings";
import {apiClient} from "../../api/api";
import {ITeamsResponse} from "../../api/apiResponseTypes";
import {IOption} from "../../components/common/Select/Select";
import {SortDirectionEnum, SortTeamColsEnum} from "../../consts/TeamMembers";
import DialogsStore, {Dialogs} from "../DialogsStore";
import EventStore from "../EventStore";
import SnackBarStore from "../SnackBarStore";
import TeamEditStore from "./TeamEditStore";
import {TEAMS_SORT_COLUMN, TEAMS_SORT_ORDER} from "../../consts/localStorage";

class TeamStore {
    @observable public loading: boolean = false;
    @observable public loadingResend: boolean = false;

    @observable public items: ITeamsResponse[] = [];

    @observable public activeSortCol: SortTeamColsEnum =
        localStorage.getItem(TEAMS_SORT_COLUMN) as SortTeamColsEnum || SortTeamColsEnum.GRADE;

    @observable public sortDirection: SortDirectionEnum =
        localStorage.getItem(TEAMS_SORT_ORDER) as SortDirectionEnum || SortDirectionEnum.ASCENDING;

    @observable public TeamOptions: Array<IOption> = [];

    @observable public TeamOptionsReq: Array<IOption> = [];

    @observable public TeamOptionsWithDonations: Array<IOption> = [];

    constructor() {
        makeAutoObservable(this);
    }

    @computed
    public fetchItemsList = async () => {
        try {
            this.loading = true;
            this.items = await apiClient.getTeams(EventStore.eventId)
            this.sortByActiveSortCol();
            this.setTeamOptions();
        } catch (e) {
            console.error("TeamStore.fetchTeamsList", e);
        } finally {
            this.loading = false;
        }
    };

    @action.bound
    public setTeamOptions = () => {
        let options: Array<IOption> = [];
        let optionsWithDonations: Array<IOption> = [];
        this.items.forEach((item) => {
            const label = `${item.name} (${
                item.gradeDisplay
            } ${strings.teams.table_columns.grade.toLowerCase()})`;

            options.push({
                label,
                value: item.id.toString(),
                disabled: !item.selected
            });

            if (item.progress.raisedSum > 0) {
                optionsWithDonations.push({
                    label,
                    value: item.id.toString(),
                    disabled: !item.selected
                });
            }
        });

        this.TeamOptions = options;
        this.TeamOptionsReq = options.filter((item) => item.value !== "");
        this.TeamOptionsWithDonations = optionsWithDonations;
    };

    //TODO need think about refactor later (duplicate code in stores)
    @action.bound
    setActiveSortCol = (col: SortTeamColsEnum) => {
        if (this.activeSortCol === col) {
            if (this.sortDirection === SortDirectionEnum.DESCENDING) {
                this.setSortDirection(SortDirectionEnum.ASCENDING);
            } else {
                this.setSortDirection(SortDirectionEnum.DESCENDING);
            }
        } else {
            this.activeSortCol = col;
            this.setSortDirection(SortDirectionEnum.DESCENDING);
        }

        this.sortByActiveSortCol();
        localStorage.setItem(TEAMS_SORT_COLUMN, this.activeSortCol);
        localStorage.setItem(TEAMS_SORT_ORDER, this.sortDirection);
    };

    @action.bound
    setSortDirection = (direction: SortDirectionEnum) => {
        this.sortDirection = direction;
    };

    @action.bound
    public sortByActiveSortCol = () => {
        if (this.activeSortCol === SortTeamColsEnum.NAME) {
            if (this.sortDirection === SortDirectionEnum.ASCENDING) {
                this.items.sort((a, b) => a.name.localeCompare(b.name));
            }

            if (this.sortDirection === SortDirectionEnum.DESCENDING) {
                this.items.sort((a, b) => b.name.localeCompare(a.name));
            }
        }

        if (this.activeSortCol === SortTeamColsEnum.MINUTES) {
            if (this.sortDirection === SortDirectionEnum.ASCENDING) {
                this.items.sort((a, b) => a.progress.minsSum - b.progress.minsSum);
            }

            if (this.sortDirection === SortDirectionEnum.DESCENDING) {
                this.items.sort((a, b) => b.progress.minsSum - a.progress.minsSum);
            }
        }

        if (this.activeSortCol === SortTeamColsEnum.BOOKS) {
            if (this.sortDirection === SortDirectionEnum.ASCENDING) {
                this.items.sort((a, b) => a.progress.booksSum - b.progress.booksSum);
            }

            if (this.sortDirection === SortDirectionEnum.DESCENDING) {
                this.items.sort((a, b) => b.progress.booksSum - a.progress.booksSum);
            }
        }

        if (this.activeSortCol === SortTeamColsEnum.DONATIONS) {
            if (this.sortDirection === SortDirectionEnum.ASCENDING) {
                this.items.sort((a, b) => a.progress.raisedSum - b.progress.raisedSum);
            }

            if (this.sortDirection === SortDirectionEnum.DESCENDING) {
                this.items.sort((a, b) => b.progress.raisedSum - a.progress.raisedSum);
            }
        }

        if (this.activeSortCol === SortTeamColsEnum.GRADE) {
            if (this.sortDirection === SortDirectionEnum.ASCENDING) {
                this.items.sort((a, b) => a.grade - b.grade);
            }

            if (this.sortDirection === SortDirectionEnum.DESCENDING) {
                this.items.sort((a, b) => b.grade - a.grade);
            }
        }

        if (this.activeSortCol === SortTeamColsEnum.STUDENTS_COUNT) {
            if (this.sortDirection === SortDirectionEnum.ASCENDING) {
                this.items.sort((a, b) => a.size - b.size);
            }

            if (this.sortDirection === SortDirectionEnum.DESCENDING) {
                this.items.sort((a, b) => b.size - a.size);
            }
        }
    };

    @action.bound
    selectTLTeam = async (idTeam: number | string) => {
        await apiClient.selectTLTeam(idTeam);
        await this.fetchItemsList();
    };
    @action.bound
    deleteTLTeam = async (idTeam: number | string) => {
        await apiClient.deleteTLTeam(idTeam);
        await this.fetchItemsList();
    };

    @action.bound
    onPressDeleteById = (idTeam: number | string) => {
        const size = this.items.find((item) => item.id === idTeam)?.size;

        if (size) {
            DialogsStore.setActiveDialog(Dialogs.TEAM_DELETE_NOTIFICATION);
        } else {
            DialogsStore.setActiveDialog(Dialogs.TEAM_DELETE_CONFIRM);
        }
    };

    @action.bound
    onResendInvite = async () => {
        try {
            const formData = new FormData();
            this.loadingResend = true;

            formData.append("eventId", EventStore.eventId.toString());
            formData.append("emails", TeamEditStore.teamBySelectedId?.owner.email!);
            formData.append(
                "link",
                `https://rfms.pledgetree.com/sign-up/invite/${EventStore.event?.inviteCode}`
            );

            await apiClient.resendInviteTeamLeader(formData);
            SnackBarStore.setSnackbar(
                strings
                    .formatString(
                        strings.teams.dialogs.invite_success_message,
                        TeamEditStore.teamBySelectedId?.owner.email!
                    )
                    .toString(),
                "success"
            );

            DialogsStore.onClose();
        } catch (e) {
            console.error("TeamStore.onResendInvite", e);
        } finally {
            this.loadingResend = false;
        }
    };
}

export default new TeamStore();
