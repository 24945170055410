import React from "react";
import {
  Button as MuiButton,
  IconButton as MuiIconButton,
  ButtonTypeMap, CircularProgress,
} from "@material-ui/core";
import styles from "./Button.module.scss";
import { ExtendButtonBase, withStyles } from "@material-ui/core";
import { Loader } from "../index";

interface IProps {
  children?: any;
  className?: string;
  width?: number | string;
  border?: boolean;
  borderRadius?: number | string;
  borderColor?: "blue" | "purple" | "red";
  disabled?: boolean;
  hidden?: boolean;
  uploader?: boolean;
  loading?: boolean;
  componentType?: "base" | "uploader" | "iconButton";
  size?: "large" | "medium" | "small";
  buttonColor?: string;
  type?: "default" | "primary" | "accent" | "link" | "purple" | "darkBlue";
  textColor?: "white" | "red" | "blue" | "purple";
  startIcon?: JSX.Element | string;
  endIcon?: JSX.Element | string;
  hovered?: boolean;
  onClick?: () => void;
  onUpload?: (files: FileList | null) => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}

export const Button = ({
  className,
  buttonColor,
  children,
  type = "default",
  textColor,
  border,
  borderRadius,
  borderColor,
  width,
  disabled,
  hidden,
  uploader,
  componentType = "base",
  size = "medium",
  loading,
  startIcon,
  endIcon,
  hovered = true,
  onClick,
  onUpload,
  onMouseLeave,
  onMouseOver,
}: IProps) => {
  const getColorBtn = () => {
    if (buttonColor) {
      return buttonColor;
    }

    switch (type) {
      case "primary":
        return styles.primaryColorBtn;
      case "purple":
        return styles.purpleColorBtn;
      case "accent":
        return styles.accentColorBtn;
      case "link":
        return styles.linkColorBtn;
      case "darkBlue":
        return styles.darkBlue;

      default:
        return "transporent";
    }
  };

  const getTextColor = () => {
    if (type === "default" && !textColor) return styles.primaryTextColorBtn;
    if (type !== "default" && !textColor) return styles.whiteTextColorBtn;

    switch (true) {
      case textColor === "white":
        return styles.whiteTextColorBtn;
      case textColor === "red":
        return styles.redTextColorBtn;
      case textColor === "purple":
        return styles.purpleColorBtn;

      default:
        return styles.primaryTextColorBtn;
    }
  };

  const getBorder = () => {
    if (border) {
      return `1px solid ${getBorderColor()}`;
    }
  };

  const getBorderColor = () => {
    switch (true) {
      case borderColor === "blue":
        return styles.primaryColorBtn;
      case borderColor === "purple":
        return styles.purpleColorBtn;
      case borderColor === "red":
        return styles.accentColorBtn;

      default:
        return styles.primaryColorBtn;
    }
  };

  const rootStyles = {
    backgroundColor: getColorBtn(),
    color: getTextColor(),
    height: styles.heightButton,
    borderRadius: borderRadius || 6,
    border: getBorder(),
    width,
    padding: "13px 22px",

    "&.Mui-disabled": {
      color: getTextColor(),
      opacity: 0.5,
    },

    "&:hover": {
      backgroundColor: getColorBtn(),
      opacity: hovered ? 0.8 : 1,
    },
  };

  const StyledButton = withStyles({
    root: { ...rootStyles, textTransform: "none" },
  })(MuiButton) as ExtendButtonBase<ButtonTypeMap<{}, "button">>;

  const StyledIconButton = withStyles({
    root: {
      ...rootStyles,
      padding: 10,
      borderRadius: 30,
    },
    sizeSmall: {
      width: 40,
      height: 40,
    },
  })(MuiIconButton) as ExtendButtonBase<ButtonTypeMap<{}, "button">>;

  const uploaderChildren = (
    <>
      {children}
      <input
        accept="image/jpeg, image/png"
        type="file"
        hidden
        onChange={(e) => onUpload && onUpload(e.target.files)}
      />
    </>
  );

  const BaseBtnComponent = () => (
    <StyledButton
      className={`${styles.button} ${className} `}
      disabled={disabled}
      hidden={hidden}
      onClick={() => {
        if(!loading && onClick) onClick()
      }}
      component={uploader ? "label" : "button"}
      endIcon={endIcon}
      startIcon={startIcon}
      size={size}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {loading ? (
          <CircularProgress color="inherit" size={24}/>
      ) : uploader ? (
        uploaderChildren
      ) : (
        children
      )}
    </StyledButton>
  );

  const IconBtnComponent = () => (
    <StyledIconButton
      className={`${styles.button} ${className}`}
      disabled={disabled}
      hidden={hidden}
      onClick={onClick}
      component={uploader ? "label" : "button"}
      size={size}
      endIcon={endIcon}
      startIcon={startIcon}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {loading ? (
        <Loader
          className={styles.loader}
          type={type !== "primary" ? "primary" : "white"}
        />
      ) : uploader ? (
        uploaderChildren
      ) : (
        children
      )}
    </StyledIconButton>
  );

  return (
    <>
      {componentType === "iconButton" ? (
        <IconBtnComponent />
      ) : (
        <BaseBtnComponent />
      )}
    </>
  );
};
