import React from "react";

import styles from "./Avatar.module.scss";

interface IProps {
  url?: string;
  currentUserAvatar?: string;
  hoveredEdit?: boolean;
  onClick?: () => void;
}

export const Avatar = (props: IProps) => {
  const { url, currentUserAvatar, hoveredEdit, onClick } = props;

  const getAvatarWrapperStyle = () => {
    if ((currentUserAvatar && currentUserAvatar === url) || hoveredEdit) {
      return styles.currentAvatarWrapper;
    }

    return styles.avatarWrapper;
  };

  return (
    <div className={getAvatarWrapperStyle()} onClick={onClick}>
      {url && <img src={url} alt="" />}
    </div>
  );
};
