import { observer } from "mobx-react-lite";
import strings from "../../../../i18n/strings";
import { Button, Input, Select } from "../../../components/common";
import { ReactComponent as ArrowRightIcon } from "../../../../images/icons/arrows/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../images/icons/arrows/arrow-left.svg";

import styles from "./FundraiserInfoForm.module.scss";
import { useHistory } from "react-router";
import { Path } from "../../../consts/router";
import { useEffect, useCallback } from "react";
import SignUpStore from "../../../store/Registration/SignUpStore";
import FundraiserInfoFormStore from "../../../store/Registration/FundraiserInfoFormStore";
import { durations } from "../../../consts/SignUp";
import { ACCESS_TOKEN } from "../../../consts/localStorage";
import UserStore from "../../../store/UserStore";
import ProfileInfoFormStore from "../../../store/Registration/ProfileInfoFormStore";

const FundraiserInfoForm = observer(() => {
  const history = useHistory();

  const onRouteNextStep = useCallback(async () => {
    const data = await FundraiserInfoFormStore.onSaveStep();

    if (
      window.localStorage.getItem(ACCESS_TOKEN) &&
      UserStore.item &&
      UserStore.item.firstName &&
      UserStore.item.lastName
    ) {
      ProfileInfoFormStore.handleChangeAvatar(UserStore.item.avatar || "");
      ProfileInfoFormStore.handleChangeDisplayName(
        UserStore.item.displayName || ""
      );
      ProfileInfoFormStore.handleChangeFirstName(UserStore.item.firstName);
      ProfileInfoFormStore.handleChangeLastName(UserStore.item.lastName);
      history.push(Path.signUpFinish);
    } else {
      if (data) {
        history.push(Path.signUpProfileInfo);
      }
    }
  }, [history]);

  const listener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onRouteNextStep();
      }
    },
    [onRouteNextStep]
  );

  useEffect(() => {
    SignUpStore.setSignUpStep(2);

    window.addEventListener("keypress", listener);

    return () => {
      window.removeEventListener("keypress", listener);
    };
  }, [listener]);

  const onRouteBack = () => {
    history.push(Path.signUpSchoolInfo);
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>
          {strings.RegistrationForms.fundraiserInfo.title}
        </div>

        <div className={styles.descriptionWrpper}>
          {strings.RegistrationForms.fundraiserInfo.description}
        </div>
      </div>

      <div className={styles.bodyWrapper}>
        <Input
          label={strings.RegistrationForms.fundraiserInfo.your_fundraiser_name}
          value={FundraiserInfoFormStore.name}
          onChange={FundraiserInfoFormStore.handleChangeName}
        />

        <div className={styles.goals}>
          <Input
            width={225}
            label={strings.RegistrationForms.fundraiserInfo.fundraiser_goal}
            value={FundraiserInfoFormStore.goal}
            onChange={FundraiserInfoFormStore.handleChangeGoal}
            endAdornment="$"
          />

          <Select
            width={225}
            label={strings.RegistrationForms.fundraiserInfo.duration}
            value={FundraiserInfoFormStore.duration}
            options={durations}
            onChange={(e) =>
              FundraiserInfoFormStore.handleChangeDuration(e.target.value)
            }
          />
        </div>

        <div className={styles.bodyDescription}>
          {strings.RegistrationForms.fundraiserInfo.content_description}
        </div>
      </div>

      <div className={styles.footerWrapper}>
        <div onClick={onRouteBack} className={styles.back}>
          <ArrowLeftIcon fill={styles.blue} />
          <span>{strings.buttons.back}</span>
        </div>

        <Button
          width={170}
          type="primary"
          disabled={!FundraiserInfoFormStore.isValidForm()}
          loading={FundraiserInfoFormStore.loading}
          endIcon={
            FundraiserInfoFormStore.loading ? (
              <></>
            ) : (
              <ArrowRightIcon fill="white" />
            )
          }
          onClick={onRouteNextStep}
        >
          {strings.buttons.continue}
        </Button>
      </div>
    </div>
  );
});

export default FundraiserInfoForm;
