import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import AppLayout from "./components/navigation/AppLayout/AppLayout";
import ConfirmLogoutDialog from "./components/navigation/AppLayout/Header/Dialogs/ConfirmLogoutDialog";
import {
  ACCESS_TOKEN,
  ACCESS_TOKEN_URL_PARAM, AFFILIATE_PARTNER_ID, AFFILIATE_PARTNER_ID_PARAM,
  EVENT_ID,
} from "./consts/localStorage";
import { Path } from "./consts/router";
import RegistrationLayout from "./screens/Registration/RegistrationLayout";
import AppStore, { LayoutsEnum } from "./store/AppStore";

const Layouts = observer(() => {
  const history = useHistory();

  useEffect(() => {
    const url = new URL(window.location.href);
    const accessToken = url.searchParams.get(ACCESS_TOKEN_URL_PARAM);
    const affiliatePartnerId = url.searchParams.get(AFFILIATE_PARTNER_ID_PARAM);
    
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.removeItem(EVENT_ID);
      return;
    }
    if (affiliatePartnerId) {
      localStorage.setItem(AFFILIATE_PARTNER_ID, affiliatePartnerId);
    }

    if (
      !localStorage.getItem(ACCESS_TOKEN) &&
      !accessToken &&
      !(
        history.location.pathname.includes("google") ||
        history.location.pathname.includes("sign") ||
        history.location.pathname.includes("facebook") ||
        history.location.pathname.includes(Path.fogotPassword)
      )
    ) {
      history.push(Path.signIn);
      AppStore.setLayout();
      document.getElementById("preLoading")?.remove();
    }

    AppStore.setLayout();
  }, [history, history.location.pathname]);

  const renderLayout = () => {
    const hasAccessToken = localStorage.getItem(ACCESS_TOKEN);
    switch (true) {
      case AppStore.layoutDisplay === LayoutsEnum.REGISTRATION_LAYOUT ||
        !hasAccessToken:
        return <RegistrationLayout />;

      case AppStore.layoutDisplay === LayoutsEnum.MAIN_LAYOUT:
        return <AppLayout />;
    }
  };

  return (
    <>
      {renderLayout()}

      <ConfirmLogoutDialog />
    </>
  );
});

export default Layouts;
