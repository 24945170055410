import { observer } from "mobx-react-lite";
import strings from "../../../../i18n/strings";
import { Button, Input } from "../../../components/common";
import { ReactComponent as ArrowRightIcon } from "../../../../images/icons/arrows/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../images/icons/arrows/arrow-left.svg";

import styles from "./ResetForm.module.scss";
import { useHistory } from "react-router";
import { Path } from "../../../consts/router";
import { useEffect, useCallback } from "react";
import ResetFormStore from "../../../store/Registration/ResetFormStore";

const ResetForm = observer(() => {
  const history = useHistory();

  const onResetPassword = useCallback(() => {
    ResetFormStore.onResetPassword();
  }, []);

  const listener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onResetPassword();
      }
    },
    [onResetPassword]
  );

  useEffect(() => {
    window.addEventListener("keypress", listener);

    return () => {
      window.removeEventListener("keypress", listener);
    };
  }, [listener]);

  const onRouteBack = () => {
    history.push(Path.signIn);
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>
          {strings.RegistrationForms.reset_password.title}
        </div>

        <div className={styles.descriptionWrpper}>
          <span>{strings.RegistrationForms.reset_password.description1}</span>
        </div>
      </div>

      <div className={styles.bodyWrapper}>
        <Input
          className={styles.control}
          value={ResetFormStore.email}
          onChange={ResetFormStore.handleChangeEmail}
          label={strings.RegistrationForms.reset_password.email}
        />
      </div>

      <div className={styles.footerWrapper}>
        <div onClick={onRouteBack} className={styles.back}>
          <ArrowLeftIcon fill={styles.blue} />
          <span>{strings.buttons.back}</span>
        </div>

        <Button
          width={230}
          type="primary"
          endIcon={
            !ResetFormStore.loading ? (
              <ArrowRightIcon fill="white" />
            ) : undefined
          }
          onClick={onResetPassword}
          disabled={ResetFormStore.email === ""}
          loading={ResetFormStore.loading}
        >
          {strings.RegistrationForms.reset_password.send_reset_link}
        </Button>
      </div>
    </div>
  );
});

export default ResetForm;
