import React, { useState } from "react";
import { Checkbox } from "../../../components/common";
import { Event } from "../../../models/Event";
import { IFundraiserOptionFormData } from "../../../interfaces/IFundraisers";

import styles from "./FundraiserOptions.module.scss";
import strings from "../../../../i18n/strings";

interface IProps {
  eventData: Event | undefined;
  isClosed?: boolean;
  onChange: (data: IFundraiserOptionFormData) => void;
}

export const FundraiserOptions = ({
  eventData,
  isClosed,
  onChange,
}: IProps) => {
  const [formData, setFormData] = useState<IFundraiserOptionFormData>({
    hideComments: eventData?.hideComments || false,
    accessToDonationPage: eventData?.accessToDonationPage || false,
    showDonations: eventData?.showDonations || false,
    readingBooksEnabled: eventData?.readingBooksEnabled === 1,
  });

  const onHandleChangeOption = (key: keyof IFundraiserOptionFormData) => {
    setFormData({
      ...formData,
      [key]: !formData[key],
    });

    onChange({
      ...formData,
      [key]: !formData[key],
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className="contentTitle">
        {strings.fundraiser_options.fundraiser_view_options}
      </div>

      <div className={styles.rowWrapper}>
        <Checkbox
          disabled={isClosed}
          checked={formData?.hideComments}
          onChange={() => onHandleChangeOption("hideComments")}
          text={strings.fundraiser_options.show_donor_messages_on_Donation_page}
        />
      </div>

      <div className={styles.rowWrapper}>
        <Checkbox
          disabled={isClosed}
          checked={formData?.accessToDonationPage}
          onChange={() => onHandleChangeOption("accessToDonationPage")}
          text={
            strings.fundraiser_options
              .allow_students_to_see_the_teammates_donation_pages
          }
        />
      </div>

      <div className={styles.rowWrapper}>
        <Checkbox
          disabled={isClosed}
          checked={formData?.showDonations}
          onChange={() => onHandleChangeOption("showDonations")}
          text={
            strings.fundraiser_options
              .allow_students_to_see_raised_donations_of_teammates
          }
        />
      </div>
    </div>
  );
};
