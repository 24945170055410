import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, RadioGroup } from '../../../components/common';
import { ReactComponent as ArrowRightIcon } from '../../../../images/icons/arrows/arrow-right.svg';
import styles from './PricingModelForm.module.scss';
import strings from '../../../../i18n/strings';
import { useHistory } from 'react-router';
import { Path } from '../../../consts/router';
import { ReactComponent as RedirectIcon } from '../../../../images/icons/redirect.svg';
import SignUpStore, { PricingModelEnum } from '../../../store/Registration/SignUpStore';
import SchoolInfoFormStore from '../../../store/Registration/SchoolInfoFormStore';
import EventStore from '../../../store/EventStore';

const PricingModelForm = observer(() => {
  const history = useHistory();

  const onRouteNextStep = useCallback(() => {
    history.push(Path.signUpSchoolInfo);
    SignUpStore.onNextStep()
  }, [history])

  const listener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onRouteNextStep();
    }
  }, [onRouteNextStep])

  useEffect(() => {
    SignUpStore.setSignUpStep(1);
    window.addEventListener('keypress', listener)

    return () => {
      window.removeEventListener('keypress', listener)
    };
  }, [listener])

  useEffect(() => {
    if (EventStore.event) {
      SchoolInfoFormStore.handleChangeSchoolName(EventStore.event.schoolName);
      SchoolInfoFormStore.handleChangeCountry(EventStore.event.country);
      SchoolInfoFormStore.handleChangeZipCode(EventStore.event.zip);
    }
  }, []);

  const RadioGroupOptions = [
    // {
    //   label: strings.RegistrationForms.pricingModel.service_fee_pricing_model,
    //   value: PricingModelEnum.FEE,
    // },
    {
      label: strings.RegistrationForms.pricingModel.free_pricing_model,
      value: PricingModelEnum.TIPS,
    },
    {
      label: strings.RegistrationForms.pricingModel.service_fee_flat_pricing_model,
      value: PricingModelEnum.FLAT,
    },
  ]

  return (
    <div className={styles.formWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>
          {strings.RegistrationForms.pricingModel.title}
        </div>

        <div className={styles.descriptionWrpper}>
          {strings.RegistrationForms.pricingModel.description}
        </div>
      </div>

      <div className={styles.bodyWrapper}>
        <RadioGroup
          value={SignUpStore.pricingModeValue}
          defaultValue={RadioGroupOptions[0].value}
          onChange={(e) => SignUpStore.setPricingModeValue(e.target.value as PricingModelEnum)}
          options={RadioGroupOptions}
        />

        <div className={styles.bodyDescription}>
          {SignUpStore.pricingModeValue === PricingModelEnum.TIPS
            ? strings.RegistrationForms.pricingModel.free_pricing_model_description
            : (SignUpStore.pricingModeValue === PricingModelEnum.FLAT ?
              strings.RegistrationForms.pricingModel.service_flat_fee_pricing_model_description :
              strings.RegistrationForms.pricingModel.service_fee_pricing_model_description)
          }
        </div>

        <a href={strings.RegistrationForms.pricingModel.link_url} target="_blank" rel="noreferrer"
           className={styles.link}>
          {strings.RegistrationForms.pricingModel.link_text}
          <RedirectIcon fill={styles.blueRedirectIcon}/>
        </a>
      </div>

      <div className={styles.footerWrapper}>
        <Button
          width={170}
          type="primary"
          endIcon={<ArrowRightIcon fill="white"/>}
          onClick={onRouteNextStep}
        >
          {strings.buttons.continue}
        </Button>
      </div>
    </div>
  )
})

export default PricingModelForm;
