import { observer } from "mobx-react-lite";
import React from "react";
import strings from "../../../../i18n/strings";
import { Button, Dialog } from "../../../components/common";
import DialogsStore from "../../../store/DialogsStore";
import TeamEditStore from "../../../store/TeamsStores/TeamEditStore";
import TeamStore from "../../../store/TeamsStores/TeamStore";

import styles from "./TeamDeleteDialog.module.scss";

const TeamResendInviteDialog = observer(() => (
  <Dialog
    isOpen={DialogsStore.isOpenResendInviteTeamLeaderDialog()}
    onClose={DialogsStore.onClose}
    dialogTitle={
      <div className={styles.title}>{strings.teams.dialogs.resend_invite}</div>
    }
    dialogContent={
      <div className={styles.bodyWrapper}>
        <div>
          {strings.formatString(
            strings.teams.dialogs.resend_invite_message,
            TeamEditStore.teamBySelectedId?.owner.firstName || " ",
            TeamEditStore.teamBySelectedId?.owner.lastName!,
            TeamEditStore.teamBySelectedId?.owner.email!
          )}
        </div>

        <div className={styles.footerWrapper}>
          <Button onClick={DialogsStore.onClose}>
            {strings.buttons.cancel}
          </Button>

          <Button
            type="primary"
            width={150}
            loading={TeamStore.loadingResend}
            onClick={TeamStore.onResendInvite}
          >
            {strings.teams.dialogs.resend_invite}
          </Button>
        </div>
      </div>
    }
  />
));

export default TeamResendInviteDialog;
