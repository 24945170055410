import { computed, makeAutoObservable, observable } from "mobx";
import strings from "../../../i18n/strings";
import { apiClient } from "../../api/api";
import DialogsStore from "../DialogsStore";
import EventStore from "../EventStore";
import FundraiserDetailsStore from "./FundraiserDetailsStore";
import FundraiserGoalsStore from "./FundraiserGoalsStore";
import SnackBarStore from "../SnackBarStore";

class FundraiserCloseStore {
  @observable loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  onCloseFundraiser = async () => {
    try {
      this.loading = true;
      const eventId = EventStore.eventId.toString();
      const body = new FormData();
      body.append("closed", "true");
      
      await apiClient.closeFundraiser(eventId, body);

      EventStore.fetchEventData();
      FundraiserDetailsStore.onCloseDeteilsEditMode();
      FundraiserGoalsStore.onClosehGoalsEditMode();
      SnackBarStore.setSnackbar(
        strings.fundraiser_close.confirm_dialog.success,
        "success"
      );
      DialogsStore.onClose();
    } catch (e) {
      console.log("FundraiserCloseStore.onCloseFundraiser", e);
    } finally {
      this.loading = false;
    }
  };
}

export default new FundraiserCloseStore();
