import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import strings from "../../../../i18n/strings";
import TeamMembersStore from "../../../store/TeamsStores/TeamMembersStore";
import { SortColEnum, SortDirectionEnum } from "../../../consts/TeamMembers";
import moment from "moment";
import { IconButton, Menu } from "@material-ui/core";
import { ReactComponent as SortIcon } from "../../../../images/icons/sort.svg";
import { ReactComponent as MoreIcon } from "../../../../images/icons/more.svg";

import styles from "./StudentsTable.module.scss";
import { formatCurrency, transformNumberValue } from "../../../utils/common";
import EventStore from "../../../store/EventStore";
import { Button, MenuItem, Tooltip } from "../../../components/common";
import { ITeamMembersResponse } from "../../../api/apiResponseTypes";
import { Link, useHistory } from "react-router-dom";
import { Path } from "../../../consts/router";
import StudentConfirmDeleteDialog from "../StudentConfirmDeleteDialog/StudentConfirmDeleteDialog";
import DialogsStore, { Dialogs } from "../../../store/DialogsStore";
import { apiClient } from "../../../api/api";
import { ReactComponent as HelperIcon } from "../../../../images/icons/helper.svg";

interface IProps {
  data: ITeamMembersResponse[];
  sortDirection?: SortDirectionEnum;
  activeCol?: SortColEnum;
}

const StudentsTable = observer(({ data, sortDirection, activeCol }: IProps) => {
  const history = useHistory();
  const currency = formatCurrency(EventStore.event?.currency);
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const [tableData, setTableData] = useState<ITeamMembersResponse[]>(data);

  useEffect(() => {
    setTableData(data?.slice(0, 20));
  }, [data, sortDirection, activeCol]);

  const handleClick = (event: any, item: ITeamMembersResponse) => {
    setAnchorEl(event.currentTarget);
    TeamMembersStore.selectIdMember(item.user.id);
    TeamMembersStore.onSelectIdObject(item.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDonationPage = (id?: number) => {
    if (id) {
      return () => {
        apiClient.getStudentDataById(id + "").then((m) => {
          window.open(m.shareLink, "_blank");
        });
      };
    }
  };

  const onRedirectToEdit = (id?: number) => {
    if (id) {
      return () => history.push(`${Path.students}/${id}`);
    }
  };

  const getActiveSortColumnStyle = (column: SortColEnum) => {
    return TeamMembersStore.activeSortCol === SortColEnum[column]
      ? styles.activeSortCol
      : "";
  };

  const toggleShowMore = () => {
    setTableData(data);
  };

  const renderDonationValue = (item: ITeamMembersResponse) => {
    if (item.progress.raisedSum > 0) {
      return (
        <Link
          to={`${Path.donations}?searchBy=${item.user.firstName}+${item.user.lastName}`}
          className={styles.donationLink}
        >
          {currency + item.progress.raisedSum}
        </Link>
      );
    }

    return currency + transformNumberValue(item.progress.raisedSum);
  };

  const getClassNameActiveAt = (item: ITeamMembersResponse) =>
    `${!item.user.activeAt ? styles.grayColor : ""}
    ${
      item.user.activeAt &&
      moment(new Date()).diff(item.user.activeAt, "days") < 1
        ? styles.greenColor
        : ""
    }
    ${getActiveSortColumnStyle(SortColEnum.ACTIVE_AT)}`;

  return (
    <>
      <table
        className={
          data?.length === tableData?.length
            ? "tableWithHover"
            : "tableStudentsRoster"
        }
      >
        <thead>
          <tr>
            <th></th>
            <th
              className={`${styles.sortCol} ${getActiveSortColumnStyle(
                SortColEnum.NAME
              )}`}
              onClick={() =>
                TeamMembersStore.setActiveSortCol(SortColEnum.NAME)
              }
            >
              <div className={styles.flexWrapper}>
                <span>{strings.students.table_columns.name}</span>

                <div
                  hidden={
                    !(
                      TeamMembersStore.activeSortCol &&
                      TeamMembersStore.activeSortCol === SortColEnum.NAME
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamMembersStore.sortDirection ===
                      SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            <th
              className={`${styles.sortCol} ${getActiveSortColumnStyle(
                SortColEnum.ACTIVE_AT
              )}`}
              onClick={() =>
                TeamMembersStore.setActiveSortCol(SortColEnum.ACTIVE_AT)
              }
            >
              <div className={styles.flexWrapper}>
                <span>{strings.students.table_columns.active_at}</span>

                <div
                  hidden={
                    !(
                      TeamMembersStore.activeSortCol &&
                      TeamMembersStore.activeSortCol === SortColEnum.ACTIVE_AT
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamMembersStore.sortDirection ===
                      SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            <th
              className={`${styles.sortCol} ${getActiveSortColumnStyle(
                SortColEnum.GRADE
              )}`}
              onClick={() =>
                TeamMembersStore.setActiveSortCol(SortColEnum.GRADE)
              }
            >
              <div className={styles.flexWrapper}>
                <span>{strings.students.table_columns.grade}</span>

                <div
                  hidden={
                    !(
                      TeamMembersStore.activeSortCol &&
                      TeamMembersStore.activeSortCol === SortColEnum.GRADE
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamMembersStore.sortDirection ===
                      SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            <th
              className={`${styles.sortCol} ${getActiveSortColumnStyle(
                SortColEnum.MINUTES
              )}`}
              onClick={() =>
                TeamMembersStore.setActiveSortCol(SortColEnum.MINUTES)
              }
            >
              <div className={styles.flexWrapper}>
                <span>{strings.students.table_columns.minutes}</span>

                <div
                  hidden={
                    !(
                      TeamMembersStore.activeSortCol &&
                      TeamMembersStore.activeSortCol === SortColEnum.MINUTES
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamMembersStore.sortDirection ===
                      SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            <th
              className={`${styles.sortCol} ${getActiveSortColumnStyle(
                SortColEnum.BOOKS
              )}`}
              onClick={() =>
                TeamMembersStore.setActiveSortCol(SortColEnum.BOOKS)
              }
            >
              <div className={styles.flexWrapper}>
                <span>{strings.students.table_columns.books}</span>

                <div
                  hidden={
                    !(
                      TeamMembersStore.activeSortCol &&
                      TeamMembersStore.activeSortCol === SortColEnum.BOOKS
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamMembersStore.sortDirection ===
                      SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            <th
              className={`${styles.donationCol} ${getActiveSortColumnStyle(
                SortColEnum.DONATIONS
              )}`}
              onClick={() =>
                TeamMembersStore.setActiveSortCol(SortColEnum.DONATIONS)
              }
            >
              <div className={styles.flexWrapper}>
                <span>{strings.students.table_columns.donations}</span>

                <div
                  hidden={
                    !(
                      TeamMembersStore.activeSortCol &&
                      TeamMembersStore.activeSortCol === SortColEnum.DONATIONS
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamMembersStore.sortDirection ===
                      SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            <th
              className={`${styles.sortCol} ${getActiveSortColumnStyle(
                SortColEnum.ACHIEVEMENTS
              )}`}
              onClick={() =>
                TeamMembersStore.setActiveSortCol(SortColEnum.ACHIEVEMENTS)
              }
            >

              <div className={styles.flexWrapper}>
                <span>{strings.students.table_columns.achievements}</span>

                <div
                  hidden={
                    !(
                      TeamMembersStore.activeSortCol &&
                      TeamMembersStore.activeSortCol === SortColEnum.ACHIEVEMENTS
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamMembersStore.sortDirection ===
                      SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>

            </th>

            <th className={styles.actionCol}>

            </th>
          </tr>
        </thead>

        <tbody>
          <>
            {tableData?.length ? (
              <>
                {tableData.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className={styles.avatarWrapper}>
                        {item.user.avatar && (
                          <img src={item.user.avatar} alt="avatar" />
                        )}
                      </div>
                    </td>

                    <td
                      className={styles.nameCol}
                      onClick={onRedirectToEdit(item.id)}
                    >
                      {`${item.user.firstName} ${item.user.lastName} ${
                        !item.user.email ? "(M)" : ""
                      }`}
                    </td>

                    <td className={getClassNameActiveAt(item)}>
                      {item.user.activeAt
                        ? moment(item.user.activeAt).fromNow()
                        : "No activity"}
                    </td>

                    <td className={getActiveSortColumnStyle(SortColEnum.GRADE)}>
                      {item.team.gradeDisplay}
                    </td>

                    <td
                      className={`
              ${item.progress.minsSum === 0 ? styles.grayColor : ""}
              ${getActiveSortColumnStyle(SortColEnum.MINUTES)}
            `}
                    >
                      {transformNumberValue(item.progress.minsSum)}
                    </td>

                    <td
                      className={`
              ${item.progress.booksSum === 0 ? styles.grayColor : ""}
              ${getActiveSortColumnStyle(SortColEnum.BOOKS)}
            `}
                    >
                      {transformNumberValue(item.progress.booksSum)}
                    </td>

                    <td
                      className={`
              ${item.progress.raisedSum === 0 ? styles.grayColor : ""}
              ${getActiveSortColumnStyle(SortColEnum.DONATIONS)}`}
                    >
                      {renderDonationValue(item)}
                    </td>

                    <td
                      className={`
              ${item.achievements.length === 0 ? styles.grayColor : ""}
              ${getActiveSortColumnStyle(SortColEnum.ACHIEVEMENTS)}`}
                    >
                      {item.achievements.length ?
                        <Tooltip title={EventStore.getAchievementsNames(item.achievements)}>
                          <div className={styles.achNumbers}>{item.achievements.length}<HelperIcon/></div>
                        </Tooltip> : item.achievements.length
                      }

                    </td>

                    <td className={styles.actionCell}>
                      <IconButton onClick={(e) => handleClick(e, item)}>
                        <MoreIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <div className="noDataTable">
                <div>{strings.students.no_students_found}</div>
              </div>
            )}
          </>
        </tbody>
      </table>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div onClick={handleClose}>
          <MenuItem
            onClick={openDonationPage(TeamMembersStore.selectedIdObject)}
          >
            {strings.students.table_item_menu.donation_page}
          </MenuItem>
        </div>

        <div onClick={handleClose}>
          <MenuItem
            onClick={onRedirectToEdit(TeamMembersStore.selectedIdObject)}
          >
            {strings.students.table_item_menu.edit}
          </MenuItem>
        </div>

        <div onClick={handleClose}>
          <MenuItem
            onClick={() =>
              DialogsStore.setActiveDialog(Dialogs.STUDENT_DELETE_CONFIRM)
            }
          >
            {strings.students.table_item_menu.delete}
          </MenuItem>
        </div>
      </Menu>

      {!TeamMembersStore.loading &&
      tableData?.length &&
      tableData?.length !== data?.length ? (
        <Button className={styles.showMoreBtn} onClick={toggleShowMore}>
          {strings.buttons.show_more}
        </Button>
      ) : null}

      <StudentConfirmDeleteDialog />
    </>
  );
});

export default StudentsTable;
