import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ConfirmEmailComponentForm from "./ConfirmEmailForm/ConfirmEmailForm"
import SignUpComponentForm from "./SignUpForm/SignUpForm"
import SignUpStore from '../../../store/Registration/SignUpStore';

const SignUpForm = observer(() => {
  useEffect(() => {
    SignUpStore.setSignUpStep(0);
    SignUpStore.setShowForm('signUp');
  }, [])

  return (
    <>
      {SignUpStore.showForm === 'signUp'
        ? <SignUpComponentForm />
        : <ConfirmEmailComponentForm />
      }
    </>
  )
});

export default SignUpForm;