import React from "react";
import { Button, InfoCard } from "../../../components/common";

import { ReactComponent as DonationGoalIcon } from "../../../../images/icons/donationGoal.svg";
import { ReactComponent as ClockIcon } from "../../../../images/icons/clock.svg";
import { ReactComponent as BookIcon } from "../../../../images/icons/book.svg";
import { ReactComponent as EditIcon } from "../../../../images/icons/editPen.svg";
import styles from "./FundraiserGoals.module.scss";
import { IFunsraiserGoals } from "../../../interfaces/IFundraisers";
import strings from "../../../../i18n/strings";
import { formatCurrency, transformNumberValue } from "../../../utils/common";
import EventStore from "../../../store/EventStore";

interface IProps {
  data: IFunsraiserGoals;
  isClosed?: boolean;
  onChangeViewMode: () => void;
}

export const FundraserGoalsView = ({
  data,
  isClosed,
  onChangeViewMode,
}: IProps) => {
  const currency = formatCurrency(EventStore.event?.currency);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <div className="contentTitle">{strings.fundraiser_goals.title}</div>
        {!isClosed && (
          <Button
            type="primary"
            onClick={onChangeViewMode}
            startIcon={<EditIcon fill="white" />}
          >
            {strings.buttons.edit}
          </Button>
        )}
      </div>

      <div className={styles.flexWrapper}>
        <div className={styles.gridWrapper}>
          <InfoCard
            className={styles.cardWrapper}
            icon={
              <DonationGoalIcon
                fill={styles.blue}
                style={{ height: 34, width: 34 }}
              />
            }
            title={currency + transformNumberValue(data.fundraising)}
            description={strings.fundraiser_goals.fundraising_goal}
          />

          <InfoCard
            className={styles.cardWrapper}
            icon={
              <DonationGoalIcon
                fill={styles.blue}
                style={{ height: 34, width: 34 }}
              />
            }
            title={currency + transformNumberValue(data.fundraisingPerMember)}
            description={strings.fundraiser_goals.student_goal}
          />
        </div>

        <div className={styles.gridWrapper}>
          <InfoCard
            className={styles.cardWrapper}
            title={transformNumberValue(data.readingMins)}
            icon={<ClockIcon />}
            description={strings.fundraiser_goals.reading_minutes}
          />

          <InfoCard
            className={styles.cardWrapper}
            title={transformNumberValue(data.readingMinsPerMember)}
            icon={<ClockIcon />}
            description={strings.fundraiser_goals.minutes_per_student}
          />
        </div>

        <div className={styles.gridWrapper}>
          <InfoCard
            className={styles.cardWrapper}
            titleColor={data.readingBooks === 0 ? "secondary" : undefined}
            title={transformNumberValue(data.readingBooks)}
            icon={<BookIcon />}
            description={strings.fundraiser_goals.reading_books}
          />

          <InfoCard
            className={styles.cardWrapper}
            titleColor={data.readingBooks === 0 ? "secondary" : undefined}
            title={
              data.readingBooks === 0
                ? "0"
                : transformNumberValue(data.readingBooksPerMember)
            }
            icon={<BookIcon />}
            description={
              data.readingBooks === 0
                ? strings.fundraiser_goals.goal_disabled
                : strings.fundraiser_goals.books_per_student
            }
          />
        </div>
      </div>
    </div>
  );
};
