import React, {useEffect} from "react";
import {ReactComponent as WhiteLogoIcon} from "../../../../../images/icons/whiteLogo.svg";
import UserStore from "../../../../store/UserStore";
import EventStore from "../../../../store/EventStore";
import {Menu, MenuItem, ProfileMenu} from "../../../common";

import styles from "./Header.module.scss";
import AppStore from "../../../../store/AppStore";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router";
import {Path} from "../../../../consts/router";
import strings from "../../../../../i18n/strings";
import DialogsStore, {Dialogs} from "../../../../store/DialogsStore";
import ActionMenu from "./ActionMenu/ActionMenu";
import TeamMembersStore from "../../../../store/TeamsStores/TeamMembersStore";
import {formatCurrency, transformNumberValue} from "../../../../utils/common";
import OverviewStore from "../../../../store/OverviewStore";
import TeamStore from "../../../../store/TeamsStores/TeamStore";
import DonationsStore from "../../../../store/DonationsStore";

const Header = observer(() => {
  const history = useHistory();
  const { user } = UserStore;
  const {event } = EventStore;

  useEffect(() => {
    console.log(history);
  }, [history]);

  const getOptions = () =>
    user?.events?.map((item) => {
      return {
        label: item.name,
        value: item.id.toString(),
      };
    });

  const onRouteAccountSettings = () => {
    AppStore.setLayout();
    history.push(Path.account);
  };

  const onLogout = () => {
    DialogsStore.setActiveDialog(Dialogs.CONFIRM_LOGOUT);
  };

  const donateValue = transformNumberValue(DonationsStore.getTotal() || 0, 3);
  const studentCount = TeamMembersStore.data?.length || 0;
  const teamsCount = TeamStore.items?.length || 0;

  return (
    <div className={`${styles.wrapper} ${user ? styles.underLayout : ""}`}>
        <div className={styles.content}>
          <WhiteLogoIcon className={styles.logo} />

          <div className={styles.contentMenu}>
            <div className={styles.breadCrumb}>

              <Menu name={`${event?.name || '...'}`} width="283px">
                {getOptions()?.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                )) || []}
              </Menu>
              <ActionMenu
                donateValue={donateValue}
                studentCount={studentCount}
                teamsCount={teamsCount}
                isStripeConnected={!!EventStore.event?.accountId || UserStore.user?.role==='TL'}
                onStripeConnectClick={OverviewStore.handleRedirectToStripe}
                isClosed={EventStore.event?.closed}
                shareLink={EventStore.event?.shareLink}
                currency={formatCurrency(EventStore.event?.currency)}
                openInviteDialog={TeamMembersStore.handleOpenInviteDialog}
              />
            </div>

            <div className={styles.profileMenu}>
              {user &&
              <ProfileMenu
                avatar={user?.avatar}
                name={`${user?.firstName} ${user?.lastName}`}
              >
                <MenuItem divider>
                  <div className={styles.menuHeader}>
                    <span
                      className={styles.name}
                    >{`${UserStore.user?.firstName} ${UserStore.user?.lastName}`}</span>
                    <span className={styles.email}>
                      {UserStore.user?.email}
                    </span>
                  </div>
                </MenuItem>
                <MenuItem onClick={onRouteAccountSettings}>
                  {strings.profile_menu.account_settings}
                </MenuItem>

                <MenuItem onClick={onLogout}>
                  {strings.profile_menu.log_out}
                </MenuItem>
              </ProfileMenu>
              }
            </div>
          </div>
        </div>
    </div>
  );
});

export default Header;
