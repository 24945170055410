import React from 'react';

import styles from './RotatableForm.module.scss';
import { Tooltip } from '../Tooltip/Tooltip';
import { RotatableChevron } from './components/RotatableChevron';

interface IProps {
  title: string;
  tooltip: string;
  titleIcon?: JSX.Element;
  displayValue?: string | JSX.Element;
  renderEditForm?: JSX.Element;
  isEdit?: boolean;
  onOpen?: () => void;
}

export const RotatableForm = React.memo((props: IProps) => {
  const {
    title,
    tooltip,
    titleIcon,
    displayValue,
    renderEditForm,
    isEdit = false,
    onOpen,
  } = props;

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.wrapper} onClick={onOpen}>
      <div className={isEdit ? styles.openWrapper : styles.closedWrapper}>
        <Tooltip title={tooltip}>
          <div className={styles.title}>
            {title}
            {titleIcon}
          </div>
        </Tooltip>

        <div className={styles.value}>
          {isEdit
            ? ''
            : displayValue
          }

          {isEdit && <div onClick={stopPropagation}>{renderEditForm}</div>}
        </div>


        <RotatableChevron isEdit={isEdit} />
      </div>
    </div>
  )
});
