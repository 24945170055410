import axios from "axios";
import qs from "qs";
import {ACCESS_TOKEN, ACCESS_TOKEN_URL_PARAM} from "../consts/localStorage";
import {Path} from "../consts/router";
import SnackBarStore from "../store/SnackBarStore";
import {ITeamByIdResponse} from "./apiResponseTypes";

export default function api(conf: any): Promise<any> {
    const config = conf;
    const url = new URL(window.location.href);
    const accessTokenFromUrl = url.searchParams.get(ACCESS_TOKEN_URL_PARAM);

    let accessToken = localStorage.getItem(ACCESS_TOKEN);
    config.headers = {
        Authorization: `Bearer ${accessToken || accessTokenFromUrl}`,
    };
    config.paramsSerializer = (params: object) =>
        qs.stringify(params, {
            allowDots: true,
            arrayFormat: "repeat",
        });

    return axios(config)
        .then((response) => {
            if (
                response.status === 200 ||
                response.status === 201 ||
                response.status === 202
            )
                return response.data;

            return Promise.reject(response.data);
        })
        .catch((e) => {
            if (e.response.status === 400) {
                SnackBarStore.setSnackbar(e.response.data.error.message, "error");
            }

            if (e.response.status === 401) {
                localStorage.clear();

                if (!window.location.pathname.includes(Path.fogotPassword)) {
                    window.location.href = Path.signIn;
                } else {
                    SnackBarStore.setSnackbar("Unauthorized", "error");
                }
            }
        });
}

class ApiClient {
    // login
    login = (body: FormData): Promise<{ accessToken: string }> =>
        api({
            method: "POST",
            url: "/api/v1/auth/login",
            data: body,
        });

    authUrlWithProvider = (provider: "facebook" | "google"): string => {
        return `/auth/${provider}?inviteCode=ea`;
    };

    signUp = (body: FormData) =>
        api({
            method: "POST",
            url: "/api/v1/auth/signup",
            data: body,
        });

    //

    getUser = () => api({url: "/api/v1/users/me"});

    updateMeUser = (body: FormData) =>
        api({
            method: "PUT",
            url: "/api/v1/users/me",
            data: body,
        });

    updateStudentGoals = (eventId: number, body: FormData) =>
        api({
            method: "POST",
            url: `/api/v1/events/${eventId}/goals`,
            data: body,
        });

    loginByStudentId = (id: number) =>
        api({
            method: "POST",
            url: `/api/v1/users/${id}/login`,
        });

    updateStudentProfile = (userId: number, body: FormData) =>
        api({
            method: "PUT",
            url: `/api/v1/users/${userId}`,
            data: body,
        });

    getAvatars = () => api({url: "/api/v1/avatars/admins"});

    getAvatarsOthers = () => api({url: "/api/v1/avatars/others"});

    getAvatarsBoys = () => api({url: "/api/v1/avatars/boys"});

    getAvatarsGirls = () => api({url: "/api/v1/avatars/girls"});

    updateUser = (idUser: number, body: FormData) =>
        api({
            method: "PUT",
            url: `/api/v1/users/${idUser}`,
            data: body,
        });

    updateEmail = (body: FormData) =>
        api({
            method: "POST",
            url: `/api/v1/users/me`,
            data: body,
        });

    updatePassword = (body: FormData) =>
        api({
            method: "POST",
            url: `/api/v1/users/me/password`,
            data: body,
        });

    createEvent = (body: FormData) =>
        api({
            method: "POST",
            url: "/api/v1/events",
            data: body,
        });

    getEventData = (idEvent: number | string) =>
        api({url: `/api/v1/events/${idEvent}`});

    getEventByInviteCode = (code: string) =>
        api({url: `/api/v1/invites/${code}`});

    uploadEventPhoto = (idEvent: number, file: FormData) =>
        api({
            method: "POST",
            url: `/api/v1/events/${idEvent}/details/photo`,
            data: file,
        });

    updateEventDetailsData = (idEvent: number, body: FormData) =>
        api({
            method: "PUT",
            url: `/api/v1/events/${idEvent}/details`,
            data: body,
        });

    updateEventData = (idEvent: number, body: FormData) =>
        api({
            method: "PUT",
            url: `/api/v1/events/${idEvent}`,
            data: body,
        });

    updateFundraiserLocation = (idLoation: number, body: FormData) =>
        api({
            method: "PUT",
            url: `/api/v1/locations/${idLoation}`,
            data: body,
        });

    updateFundraiserOptions = (idEvent: number, body: FormData) =>
        api({
            method: "PUT",
            url: `/api/v1/events/${idEvent}/options`,
            data: body,
        });

    updateFundraiserGoals = (idEvent: number, body: FormData) =>
        api({
            method: "PUT",
            url: `/api/v1/events/${idEvent}/goals`,
            data: body,
        });

    // overview

    getPaymentsByDate = (idEvent: number) =>
        api({url: `/api/v1/events/${idEvent}/payments/bydate`});

    getReadingByDate = (idEvent: number) =>
        api({url: `/api/v1/events/${idEvent}/reading/bydate`});

    //team members (students)

    getTeamMembers = (idEvent: number) =>
        api({url: `/api/v1/events/${idEvent}/members`});

    getAchievements = (idEvent: number) =>
        api({url: `/api/v1/events/${idEvent}/achievements`});


    getStudentDataById = (id: string) => api({url: `/api/v1/members/${id}`});

    deleteStudentById = (id: number) =>
        api({
            method: "DELETE",
            url: `/api/v1/users/${id}`,
        });

    getEventTeamMembersCsv = (idEvent: number, from?: number, to?: number) =>
        api({
            url: `api/v1/events/${idEvent}/members.csv?${from ? `from=${from}&` : ''}${to ? `to=${to}&` : ''}accessToken=${localStorage.getItem(
                ACCESS_TOKEN
            )}`,
        });

    // teams
    getTeams = (idEvent: number) =>
        api({url: `/api/v1/events/${idEvent}/teams`});

    getTeachers = (idEvent: number) =>
        api({url: `/api/v1/events/${idEvent}/teachers`});

    deleteTeacher = (idEvent: number, userId: number) =>
        api({  method: "DELETE", url: `/api/v1/events/${idEvent}/teachers/${userId}`});


    getTeamById = (idTeam: number | string): Promise<ITeamByIdResponse> =>
        api({url: `/api/v1/teams/${idTeam}`});

    createNewTeam = (body: FormData) =>
        api({
            method: "POST",
            url: "/api/v1/teams",
            data: body,
        });

    editTeam = (idTeam: number | string, body: FormData) =>
        api({
            method: "PUT",
            url: `/api/v1/teams/${idTeam}`,
            data: body,
        });

    deleteTeamById = (idTeam: number | string) =>
        api({
            method: "DELETE",
            url: `/api/v1/teams/${idTeam}`,
        });

    selectTLTeam = (idTeam: number | string) =>
        api({
            method: "POST",
            url: `/api/v1/teams/${idTeam}/selection`,
        });

    deleteTLTeam = (idTeam: number | string) =>
        api({
            method: "DELETE",
            url: `/api/v1/teams/${idTeam}/selection`,
        });

    // donation

    getDonations = (idEvent: number) =>
        api({url: `/api/v1/events/${idEvent}/payments`});

    getDonationsCsv = (idEvent: number) =>
        api({
            url: `api/v1/events/${idEvent}/payments?output=csv&accessToken=${localStorage.getItem(
                ACCESS_TOKEN
            )}`,
        });

    stripeConnection = (redirectUrl: string) => {
        return api({
            method: "POST",
            url: `api/v1/integrations/stripe/connect?redirectUrl=${redirectUrl}`,
        });
    };

    resetPassword = (email: string) =>
        api({
            method: "POST",
            url: `/api/v1/auth/reset-password?email=${email}`,
        });

    resendInviteTeamLeader = (body: FormData) =>
        api({
            method: "POST",
            url: `/api/v1/invites`,
            data: body,
        });

    sendAnnouncement = (body: FormData) =>
        api({
            method: "POST",
            url: "/api/v1/broadcast",
            data: body,
        });

    deleteDonorComment = (idEvent: string, idPayment: string) =>
        api({
            method: "DELETE",
            url: `/api/v1/events/${idEvent}/payments/${idPayment}/comment`,
        });

    closeFundraiser = (idEvent: string, body: FormData) => api({
        method: "PUT",
        url: `/api/v1/events/${idEvent}`,
        data: body
    })
}

export const apiClient = new ApiClient();
