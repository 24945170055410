import React from 'react';
import {Tooltip as MuiTooltip, withStyles} from '@material-ui/core';

import styles from './Tooltip.module.scss';

interface IProps {
  children?: any;
  link?: string;
  title: string | string[];
  bgColor?: 'blue' | 'purple';
}

export const Tooltip = ({title, link, bgColor, children}: IProps) => {
  const getBgColor = () => {
    switch(true) {
      case bgColor === 'blue': return styles.blueBgTooltip;
      case bgColor === 'purple': return styles.purpleBgTooltip;

      default: return styles.blueBgTooltip;
    }
  }

  const StyledTooltip = withStyles({
    tooltip: {
      padding: '15px',
      fontFamily: "Montserrat",
      fontSize: 13,
      backgroundColor: getBgColor(),
    },
    arrow: {
      color: getBgColor(),
    }
  })(MuiTooltip);


  return (
    <StyledTooltip title={title} arrow className={styles.tooltipWrapper}>
      {link? <a href={link} rel="noreferrer"  target="_blank">{children}</a>: children}
    </StyledTooltip>
  )
}
