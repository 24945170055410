import React from "react";
import {observer} from "mobx-react-lite";
import {InfoCard, Progress} from "../../../components/common";
import OverviewStore from "../../../store/OverviewStore";
import EventStore from "../../../store/EventStore";
import strings from "../../../../i18n/strings";
import {ReadingReportChart} from "../ReportCharts/ReadingReportChart";
import TeamMembersStore from "../../../store/TeamsStores/TeamMembersStore";
import {ReactComponent as HelperIcon} from "../../../../images/icons/helper.svg";

import styles from "../Overview.module.scss";
import {transformNumberValue} from "../../../utils/common";
import UserStore from "../../../store/UserStore";

const ReadingFullWidget = observer(() => (
    <>
        {UserStore.user?.role === 'EA' &&
            <div className={styles.widgetsWrapper}>
                <div className={styles.progressWrapper}>
                    <Progress
                        value={OverviewStore.getReadingPercent()}
                        width="90px"
                        color="purple"
                    />

                    <InfoCard
                        titleColor="purple"
                        tooltipBgColor="purple"
                        title={
                            <div>
              <span className={styles.raisedFrom}>
                {transformNumberValue(EventStore.event?.minsSum, 1)}
              </span>
                                <span>{` / ${transformNumberValue(EventStore.event?.readingMins)}`}</span>
                            </div>
                        }
                        description={
                            <div className={styles.receivedDonation}>
                                <span>{strings.overview.minutes_read_goal}</span>
                                <HelperIcon fill={styles.tickStroke}/>
                            </div>
                        }
                        tooltip={strings.formatString(
                            strings.overview.minutes_read_goal,
                            transformNumberValue(EventStore.event?.readingMins)
                        )}
                    />
                </div>

                <InfoCard
                    titleColor="purple"
                    tooltipBgColor="purple"
                    title={EventStore.event?.recordsCount}
                    description={
                        <div className={styles.receivedDonation}>
                            <span>{strings.overview.reading_sessions}</span>
                            <HelperIcon fill={styles.tickStroke}/>
                        </div>
                    }
                    tooltip={strings.overview.tips.reading_sessions}
                />

                <InfoCard
                    titleColor="purple"
                    tooltipBgColor="purple"
                    title={EventStore.event?.booksSum}
                    description={
                        <div className={styles.receivedDonation}>
                            <span>{strings.overview.books_completed}</span>
                            <HelperIcon fill={styles.tickStroke}/>
                        </div>
                    }
                    tooltip={strings.overview.tips.books_completed}
                />

                <InfoCard
                    titleColor="purple"
                    tooltipBgColor="purple"
                    title={`${TeamMembersStore.getPercentReadingStudents().toFixed(0)}%`}
                    description={
                        <div className={styles.receivedDonation}>
                            <span>{strings.overview.students_are_reading}</span>
                            <HelperIcon fill={styles.tickStroke}/>
                        </div>
                    }
                    tooltip={strings.overview.tips.students_are_reading}
                />
            </div>}
        <div className={styles.chartWrapper}>
            <ReadingReportChart data={OverviewStore.getReadingChartData()}/>
        </div>

    </>
));

export default ReadingFullWidget;
