import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Path } from "./consts/router";
import PrintCodesLetter from "./screens/Students/PrintCodesLetter/PrintCodes";
import SnackBarStore from "./store/SnackBarStore";
import { SnackBar } from "./components/common";
import AppStore from "./store/AppStore";
import Layouts from "./Layouts";

const App = observer(() => {
  useEffect(() => {
    AppStore.setLayout();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to={{ pathname: Path.main }} />}
          />
          <Route
            exact
            path={Path.fundraiserInviteLetter}
            component={PrintCodesLetter}
          />

          <Layouts />
        </Switch>
      </BrowserRouter>

      <SnackBar
        isOpen={SnackBarStore.isOpenSnackBar}
        onClose={SnackBarStore.handleCloseSnackBar}
        severity={SnackBarStore.snackBarSeverity}
        alertText={SnackBarStore.snackBarAlertText}
      />
    </>
  );
});

export default App;
