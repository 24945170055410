import React, { useEffect, useState } from "react";
import {
  Button,
  ComboBox,
  DatePicker,
  Input,
  TextArea,
} from "../../../components/common";
import moment from "moment";

import styles from "./FundraiserDetails.module.scss";
import MediaContainer from "../../../components/MediaContainer/MediaContainer";
import { Event } from "../../../models/Event";
import { StateItems } from "../../../consts/stateItems";
import { getSelectOptions } from "../../../utils/common";
import strings from "../../../../i18n/strings";
import { observer } from "mobx-react-lite";
import FundraiserDetailsStore from "../../../store/FundraiserStores/FundraiserDetailsStore";
import { cloneDeep } from "lodash";
import ObjectiveItem from "./component/ObjectiveItem";

interface IProps {
  onChangeViewMode: () => void;
  onSave: (data?: Event) => Promise<"error" | "success" | undefined>;
  isLoading?: boolean;
  eventData?: Event;
}

const FundraiserDetails = observer(
  ({ eventData, isLoading, onChangeViewMode, onSave }: IProps) => {
    const [formData, setFormData] = useState<Event | undefined>(eventData);
    
    useEffect(() => {
      let newObjectives = cloneDeep(eventData);
      if (!newObjectives?.objectives) {
        newObjectives!.objectives = [];
      }

      if (newObjectives?.objectives && newObjectives.objectives?.length < 3) {
        while (newObjectives.objectives?.length < 3) {
          newObjectives.objectives.push("");
        }

        setFormData(newObjectives);
      }
    }, [eventData]);

    const handleEditFormData = (value: string | number, key: keyof Event) => {
      if (formData) {
        setFormData({ ...formData, [key]: value });
      }
    };

    const handleEditObjectives = (value: string, index: number) => {
      if (formData?.objectives) {
        const newObjective = formData.objectives;
        newObjective[index] = value;

        setFormData({ ...formData, objectives: newObjective });
      }
    };

    const onAddNewObjective = () => {
      if (formData) {
        let newObjective = formData.objectives;
        newObjective?.push("");

        setFormData({ ...formData, objectives: newObjective });
      }
    };

    const onCancelChangeVideoUrl = () => {
      FundraiserDetailsStore.videoUrl = eventData?.video;
    };

    const onHandleSave = async () => {
      formData?.objectives?.filter((item) => !item);
      const response = await onSave(formData);

      if (response !== "error") {
        onChangeViewMode.call({});
      }
    };

    const onHandleChangeDate = (date: Date | null) => {
      setFormData({ ...formData!, end: +moment(date) });
    };

    const objectives = formData?.objectives;

    return (
      <>
        <div className={styles.headerWrapper}>
          <div className={styles.editedHeaderInfo}>
            <div className={styles.location}>
              <div className={styles.editTitle}>
                <div>{strings.fundraiser_details.edit_title}</div>
              </div>

              <div className={styles.btnWrapper}>
                <Button onClick={onChangeViewMode}>
                  {strings.buttons.cancel}
                </Button>
                <Button
                  type="primary"
                  width={100}
                  onClick={onHandleSave}
                  loading={isLoading}
                >
                  {strings.buttons.save}
                </Button>
              </div>
            </div>

            <div className={styles.location}>
              <div className={styles.widthWrapper}>
                <Input
                  label={strings.fundraiser_details.fundraiser_name}
                  width="85%"
                  value={formData?.name}
                  onChange={(val) => handleEditFormData(val, "name")}
                />
                <DatePicker
                  className={styles.datePicker}
                  value={moment(formData?.end)}
                  label={strings.fundraiser_details.end_date}
                  format="MM/dd/yyyy"
                  onChange={(date) => onHandleChangeDate(date)}
                />
              </div>
            </div>

            <div>
              <span className={styles.bodyTitle}>
                {strings.fundraiser_details.school_location}
              </span>
            </div>

            <div>
              <div className={styles.location}>
                <Input
                  label={strings.fundraiser_details.school_name}
                  value={formData?.schoolName}
                  onChange={(val) => handleEditFormData(val, "schoolName")}
                />
              </div>
              <br />
              <div className={styles.location}>
                <Input
                  label={strings.fundraiser_details.country}
                  width="25%"
                  disabled
                  value={formData?.displayCountry}
                />
                <div className={styles.space} />
                {formData?.location.country === "US" ? (
                  <ComboBox
                    width="25%"
                    disableClearable
                    options={getSelectOptions(StateItems)}
                    label={strings.fundraiser_details.us_state}
                    value={formData?.state}
                    onChange={(value) => handleEditFormData(value, "state")}
                  />
                ) : (
                  <Input
                    label={strings.fundraiser_details.us_state}
                    width="25%"
                    disabled
                    value={"No state"}
                  />
                )}

                <div className={styles.space} />
                <Input
                  label={
                    formData?.location.country === "US"
                      ? strings.fundraiser_details.zip_code
                      : strings.fundraiser_details.postal_code
                  }
                  width="25%"
                  value={formData?.zip}
                  onChange={(val) => handleEditFormData(val, "zip")}
                />
                <div className={styles.space} />
                <Input
                  label={strings.fundraiser_details.city}
                  width="25%"
                  value={formData?.city}
                  onChange={(val) => handleEditFormData(val, "city")}
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <br />
          <br />
          <span className={styles.bodyTitle}>
            {strings.fundraiser_details.fundraiser_story}
          </span>
        </div>

        <div className={styles.editedBodyWrapper}>
          <TextArea
            label={strings.fundraiser_details.fundraiser_story}
            rows={12}
            width={650}
            value={formData?.summary}
            onChange={(val) => handleEditFormData(val, "summary")}
          />

          <div className={styles.mediaGridWrapper}>
            <MediaContainer
              type="image"
              mode="edit"
              isLoadingUploadImage={FundraiserDetailsStore.isLoadingUploadPhoto}
              isLoadingDeleteImage={FundraiserDetailsStore.isLoadingDeletePhoto}
              onUploadImage={FundraiserDetailsStore.uploadPhoto}
              onDelete={FundraiserDetailsStore.deleteEventPhoto}
              url={FundraiserDetailsStore.photoUrl}
            />

            <MediaContainer
              className={styles.videoWrapper}
              type="video"
              mode="edit"
              url={FundraiserDetailsStore.videoUrl}
              onChangeVideoUrl={(url) =>
                FundraiserDetailsStore.handleChangeVideoUrl(url)
              }
              onUploadVideo={FundraiserDetailsStore.onUploadVideo}
              onCancel={() => onCancelChangeVideoUrl()}
              isLoadingUploadVideo={FundraiserDetailsStore.isLoadingUploadVideo}
            />
          </div>
        </div>

        <div className={styles.fundraiserObjectivesEditWrapper}>
          <div className={styles.bodyTitle}>
            {strings.fundraiser_details.fundraiser_objectives}
          </div>

          {objectives?.length && (
            <div className={styles.objectivesItemsWrapper}>
              {objectives.map((item, index) => (
                <ObjectiveItem
                  key={index}
                  item={item}
                  index={index}
                  handleEditForm={handleEditObjectives}
                />
              ))}
            </div>
          )}

          <Button width={120} onClick={onAddNewObjective}>
            {strings.fundraiser_details.add_objectives}
          </Button>
        </div>
      </>
    );
  }
);

export default FundraiserDetails;
