import React from 'react';
import {Tooltip} from '../Tooltip/Tooltip';
import styles from './InfoCard.module.scss';

interface IProps {
  className?: string;
  icon?: JSX.Element;
  title?: string | number | JSX.Element;
  subTitle?: string | number;
  titleColor?: "blue" | "black" | 'purple' | 'secondary';
  description?: string | string[] | JSX.Element;
  tooltip?: string | string[];
  tooltipBgColor?: 'blue' | 'purple';
}

export const InfoCard = (
  {
    className,
    description,
    icon,
    title,
    subTitle,
    titleColor = 'blue',
    tooltipBgColor,
    tooltip,
  }: IProps) => {

  const getTitleColor = () => {
    switch (true) {
      case titleColor === 'blue': return styles.blueTitle;
      case titleColor === 'purple': return styles.purpleTitle;
      case titleColor === 'secondary': return styles.secondaryTitle;

      default: return styles.blackTitle;
    }
  }


  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={styles.titleWrapper}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <span className={getTitleColor()}>{title}</span>&nbsp;
        <span className={`${styles.subTitle} ${getTitleColor()}`}>{subTitle}</span>
      </div>

      {tooltip
        ? (
          <Tooltip title={tooltip} bgColor={tooltipBgColor}>
            <div className={styles.descriptionHoverWrapper}>
              {description}
            </div>
          </Tooltip>
        )
        : (
          <div className={styles.descriptionWrapper}>
            {description}
          </div>
        )}

    </div>
  )
}