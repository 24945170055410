import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { ReactComponent as HelperIcon } from "../../../images/icons/helper.svg";
import styles from "./AccountSettings.module.scss";
import strings from "../../../i18n/strings";
import UserStore from "../../store/UserStore";
import { AvatarWidget, RotatableForm } from "../../components/common";
import FullNameForm from "./miniForms/FullNameForm";
import DisplayNameForm from "./miniForms/DisplayNameForm";
import EmailForm from "./miniForms/EmailForm";
import PasswordForm from "./miniForms/Password";
import LanguageForm from "./miniForms/LanguageForm";
import AccountSettingsStore from "../../store/AccountSettingsStore";
import { AccountMiniForm } from "../../consts/accountSettings";

const AccountSettings = observer(() => {
  useEffect(() => {
    AccountSettingsStore.clearState();
  }, []);

  return (
    <div className="screenWrapper">
      <div className="content">
        <div className="contentWrapper">
          <div className={styles.headerWrapper}>
            <span className={styles.title}>
              {strings.account_settings.title}
            </span>
            <span className={styles.subTitle}>
              {strings.account_settings.sub_title}
            </span>
          </div>

          <div className={styles.bodyWrapper}>
            <AvatarWidget
              currentUrlAvatar={UserStore.user?.avatar}
              loading={AccountSettingsStore.avatarLoading}
              onOpenDialog={AccountSettingsStore.handleOpenAvatarsDialog}
              isOpenDialog={AccountSettingsStore.isOpenAvatarChangeDialog}
              onSave={AccountSettingsStore.saveAvatar}
              onCloseDialog={AccountSettingsStore.handleCloseAvatarsDialog}
            />
          </div>
        </div>

        <div className={styles.formsWrapper}>
          <RotatableForm
            title={strings.account_settings.display_name}
            tooltip={strings.account_settings.display_name_tooltip}
            titleIcon={<HelperIcon fill={styles.info} />}
            displayValue={AccountSettingsStore.data.displayName}
            renderEditForm={<DisplayNameForm />}
            isEdit={AccountSettingsStore.visibleEditMode(
              AccountMiniForm.DISPLAY_NAME
            )}
            onOpen={() =>
              AccountSettingsStore.selectEditedForm(
                AccountMiniForm.DISPLAY_NAME
              )
            }
          />

          <RotatableForm
            title={strings.account_settings.full_name}
            tooltip={strings.account_settings.full_name_tooltip}
            titleIcon={<HelperIcon fill={styles.info} />}
            displayValue={AccountSettingsStore.getFullName()}
            renderEditForm={<FullNameForm />}
            isEdit={AccountSettingsStore.visibleEditMode(
              AccountMiniForm.FULL_NAME
            )}
            onOpen={() =>
              AccountSettingsStore.selectEditedForm(AccountMiniForm.FULL_NAME)
            }
          />

          <RotatableForm
            title={strings.account_settings.email}
            tooltip={strings.account_settings.email_tooltip}
            titleIcon={<HelperIcon fill={styles.info} />}
            displayValue={AccountSettingsStore.data.email}
            renderEditForm={<EmailForm />}
            isEdit={AccountSettingsStore.visibleEditMode(AccountMiniForm.EMAIL)}
            onOpen={() =>
              AccountSettingsStore.selectEditedForm(AccountMiniForm.EMAIL)
            }
          />

          <RotatableForm
            title={strings.account_settings.password}
            tooltip={strings.account_settings.password_tooltip}
            titleIcon={<HelperIcon fill={styles.info} />}
            displayValue="*********"
            renderEditForm={<PasswordForm />}
            isEdit={AccountSettingsStore.visibleEditMode(
              AccountMiniForm.PASSWORD
            )}
            onOpen={() =>
              AccountSettingsStore.selectEditedForm(AccountMiniForm.PASSWORD)
            }
          />

          <RotatableForm
            title={strings.account_settings.ui_language}
            tooltip={strings.account_settings.ui_language_tooltip}
            titleIcon={<HelperIcon fill={styles.info} />}
            displayValue={AccountSettingsStore.getLanguage()}
            renderEditForm={<LanguageForm />}
            isEdit={AccountSettingsStore.visibleEditMode(
              AccountMiniForm.UI_LANGUAGE
            )}
            onOpen={() =>
              AccountSettingsStore.selectEditedForm(AccountMiniForm.UI_LANGUAGE)
            }
          />
        </div>
      </div>
    </div>
  );
});

export default AccountSettings;
