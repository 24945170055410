import React from 'react';
import {createStyles, withStyles, Theme} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import styles from './Progress.module.scss';

interface IProps {
  value: number;
  color?: 'primary' | 'purple';
  width?: string;
}

export const Progress = ({value, width, color}: IProps) => {
  const getBarColor = () => {
    switch(true) {
      case color === 'primary': return styles.primaryBarColor;
      case color === 'purple': return styles.purpleBarColor;

      default: return styles.primaryBarColor;
    }
  }

  const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
      root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      },
      bar: {
        borderRadius: 5,
        backgroundColor: getBarColor(),
      },
    }),
  )(LinearProgress);

  return (
    <BorderLinearProgress variant="determinate" value={value > 100 ? 100 : value} style={{width}} />
  )
}