import { action, makeAutoObservable, observable } from "mobx";
import strings from "../../../i18n/strings";
import { apiClient } from "../../api/api";
import SnackBarStore from "../SnackBarStore";

class ResetFormStore {
  @observable public loading?: boolean;
  @observable public email: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound
  handleChangeEmail = (value: string) => (this.email = value);

  @action.bound
  onResetPassword = async () => {
    try {
      this.loading = true;
      const data = await apiClient.resetPassword(this.email);

      if (data) {
        SnackBarStore.setSnackbar(
          strings.RegistrationForms.reset_password.success_reset_password,
          "success"
        );
      }
    } catch (e) {
      SnackBarStore.setSnackbar("err", "error");
      console.error("ResetFormStore.onResetPassword", e);
    } finally {
      this.loading = false;
    }
  };
}

export default new ResetFormStore();
