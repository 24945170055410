import {CircularProgress} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import styles from "./CircularProgress.module.scss";

interface IProps {
    type?: "primary" | "white";
    size?: string | number;
    className?: string;
}

export const Loader = ({
                           type = "primary",
                           size = "60px",
                           className,
                       }: IProps) => {
    const StyledCircularProgress = withStyles({
        root: {
            color: type === "primary" ? styles.primaryColor : styles.whiteColor,
            width: "28px",
            height: "28px",
        },
    })(CircularProgress);

    return (
       <div className={styles.bg}>
            <StyledCircularProgress
                className={`${className || styles.loadingWrapper}`}
                thickness={2.5}
                size={size}
            />
       </div>
    );
};
