import moment from "moment";
import { IOption } from "../components/common/Select/Select";

export enum SortDirectionEnum {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum SortColEnum {
  NAME = "NAME",
  ACTIVE_AT = "ACTIVE_AT",
  GRADE = "GRADE",
  MINUTES = "MINUTES",
  BOOKS = "BOOKS",
  DONATIONS = "DONATIONS",
  ACHIEVEMENTS = "ACHIEVEMENTS",
}

export enum SortTeamColsEnum {
  NAME = "NAME",
  STUDENTS_COUNT = "STUDENTS_COUNT",
  GRADE = "GRADE",
  MINUTES = "MINUTES",
  BOOKS = "BOOKS",
  DONATIONS = "DONATIONS",
}

export enum RegistrationTypeEnum {
  ANY = "Any",
  MANAGED_PROFILE = "Managed profile",
  SELF_REGiSTRATION = "Self registration",
}

export const registrationType: Array<IOption> = [
  {
    value: RegistrationTypeEnum.ANY,
    label: "Any",
  },
  {
    value: RegistrationTypeEnum.MANAGED_PROFILE,
    label: "Managed profile",
  },
  {
    value: RegistrationTypeEnum.SELF_REGiSTRATION,
    label: "Self registration",
  },
];

export const grades: IOption[] = [
  // {
  //   label: "SEN",
  //   value: "-10",
  // },
  // {
  //   label: "Home",
  //   value: "-9",
  // },
  // {
  //   label: "Inclusion",
  //   value: "-8",
  // },
  // {
  //   label: "Multi-grade",
  //   value: "-7",
  // },
  // {
  //   label: "Club",
  //   value: "-6",
  // },
  {
    label: "Other",
    value: "-3",
  },
  {
    label: "PK",
    value: "-1",
  },
  {
    label: "K",
    value: "0",
  },
  {
    label: "1st Grade",
    value: "1",
  },
  {
    label: "2nd Grade",
    value: "2",
  },
  {
    label: "3rd Grade",
    value: "3",
  },
  {
    label: "4th Grade",
    value: "4",
  },
  {
    label: "5th Grade",
    value: "5",
  },
  {
    label: "6th Grade",
    value: "6",
  },
  {
    label: "7th Grade",
    value: "7",
  },
  {
    label: "8th Grade",
    value: "8",
  },
  {
    label: "9th Grade",
    value: "9",
  },
  {
    label: "10th Grade",
    value: "10",
  },
  {
    label: "11th Grade",
    value: "11",
  },
  {
    label: "12th Grade",
    value: "12",
  },
];

export const unixFromNow = moment(new Date()).unix().valueOf();
