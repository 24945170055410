import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import styles from './DownloadReportModal.module.scss';

import strings from '../../../../i18n/strings';
import { Button, Dialog } from "../../../components/common";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import TeamMembersStore from "../../../store/TeamsStores/TeamMembersStore";

interface IProps {
  eventCreated: number,
  open: boolean,
  onClose: () => void;
}

const DownloadReportModal = ({
                               eventCreated, open, onClose
                             }: IProps) => {

  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(moment(eventCreated));
  const [to, setTo] = useState(moment());

  const handleDateFromChange = (v: MaterialUiPickersDate) => {
    setFrom(moment(v?.getTime() || 0).startOf('day'));
  }

  const handleDateToChange = (v: MaterialUiPickersDate) => {
    setTo(moment(v?.getTime() || 0).endOf('day'));
  }
  const handleDownload = async () => {
    setLoading(true)
    await TeamMembersStore.exportTeamMembers(from.valueOf(), to.valueOf());
    setLoading(false)
    onClose();
  }


  return (

    <Dialog
      isOpen={open}
      onClose={onClose}
      dialogTitle={
        <div className={styles.title}>
          {strings.overview.report_download_dialog.title}
        </div>
      }
      dialogContent={
        <div className={styles.bodyWrapper}>
          <div>{strings.overview.report_download_dialog.message}</div>
          <div className={styles.datePickers}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                autoOk={true}
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-from"
                label={strings.overview.report_download_dialog.date_from}
                value={from.toDate()}
                onChange={handleDateFromChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-to"
                label={strings.overview.report_download_dialog.date_to}
                value={to.toDate()}
                onChange={handleDateToChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className={styles.footer}>
            <Button onClick={onClose}>{strings.buttons.cancel}</Button>
            <Button onClick={handleDownload} type="primary" loading={loading}>Download</Button>
          </div>

        </div>
      }
    />
  )
}

export default DownloadReportModal;
