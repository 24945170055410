import { action, computed, makeAutoObservable, observable } from "mobx";
import { getItemSignUpFromLS, setItemToSignUpLS, validZipCode } from "../../utils/common";

interface ISchoolInfo {
  type?: string;
  schoolName?: string;
  zip?: string;
  country?: string;
}
class SchoolInfoFormStore {
  @observable public dataFromLS?: ISchoolInfo = getItemSignUpFromLS();

  @observable public schoolType?: string = this.dataFromLS?.type;

  @observable public schoolName?: string = this.dataFromLS?.schoolName;

  @observable public zipCode?: string = this.dataFromLS?.zip;

  @observable public country?: string = this.dataFromLS?.country;

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound
  handleChangeSchoolName = (value: string) => {
    this.schoolName = value
  };

  @action.bound
  handleChangeSchoolType = (value: string) => {
    this.schoolType = value;
  };

  @action.bound
  handleChangeZipCode = (value: string) => {
    this.zipCode = value
  };

  @action.bound
  handleChangeCountry = (value: string) => {
    this.country = value
  };

  @computed
  onNextStep = () => {
    setItemToSignUpLS({
      country: this.country,
      zip: this.zipCode,
      type: this.schoolType,
      schoolName: this.schoolName
    });
  }

  @computed
  isValidForm = () => 
    this.schoolType &&
    this.schoolName &&
    this.zipCode &&
    this.country &&
    validZipCode(this.zipCode, this.country);
}

export default new SchoolInfoFormStore();
