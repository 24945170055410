import { computed, makeAutoObservable, observable } from "mobx";
import strings from "../../../i18n/strings";
import { apiClient } from "../../api/api";
import { IFundraiserOptionFormData } from "../../interfaces/IFundraisers";
import EventStore from "../EventStore";
import SnackBarStore from "../SnackBarStore";

class FundraiserOptionsStore {
  @observable public isLoading?: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  public updateOptions = async (data?: IFundraiserOptionFormData) => {
    try {
      if (data) {
        this.isLoading = true;
        const formData = new FormData();
        //@ts-ignore
        Object.keys(data).forEach((key) => formData.append(key, data[key]));
        await apiClient.updateFundraiserOptions(EventStore.eventId, formData);
        await EventStore.fetchEventData();
        SnackBarStore.setSnackbar(
          strings.fundraiser_options.success_message,
          "success"
        );
      }
    } catch (e) {
      console.error("FundraiserStore.updateOptions", e);
    } finally {
      this.isLoading = false;
    }
  };
}

export default new FundraiserOptionsStore();
