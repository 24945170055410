import {observer} from 'mobx-react-lite';
import React from 'react';
import strings from '../../../../i18n/strings';
import {Button, Dialog} from '../../../components/common';
import DialogsStore, {Dialogs} from '../../../store/DialogsStore';

import styles from './index.module.scss';
import TeachersStore from "../../../store/TeamsStores/TeachersStore";
import {ITeacher} from "../../../api/apiResponseTypes";

interface IProps {
    user?: ITeacher | null;
}


const ConfirmDeleteDialog = observer(({user}: IProps) => (
    <Dialog
        isOpen={DialogsStore.isOpen(Dialogs.TEACHER_DELETE_CONFIRM)}
        onClose={DialogsStore.onClose}
        dialogTitle={<div className={styles.title}>{strings.teachers.delete_dialog.title}</div>}
        dialogContent={
            <div className={styles.bodyWrapper}>
                <div>{strings.teachers.delete_dialog.message}</div>

                <div className={styles.footerWrapper}>
                    <Button onClick={DialogsStore.onClose}>
                        {strings.buttons.cancel}
                    </Button>

                    <Button
                        type="accent"
                        width={150}
                        loading={TeachersStore.loadingDelete}
                        onClick={() => TeachersStore.delete(user?.id!!)}
                    >
                        {strings.teachers.delete_dialog.confirm_btn}
                    </Button>
                </div>
            </div>
        }
    />
))

export default ConfirmDeleteDialog;
