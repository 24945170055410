import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, withRouter } from "react-router";

import styles from "./RegistrationLayout.module.scss";
import strings from "../../../i18n/strings";
import { ReactComponent as RfmsWhiteLogoIcon } from "../../../images/icons/whiteLogo.svg";
import { Button, Stepper } from "../../components/common";
import { signUpTimelint } from "../../consts/SignUp";
import { Path } from "../../consts/router";
import SignInForm from "./SignInForm/SignInForm";
import { useHistory } from "react-router-dom";
import PricingModelForm from "./PricingModelForm/PricingModelForm";
import SchoolInfoForm from "./SchoolInfoForm/SchoolInfoForm";
import ResetForm from "./ResetForm/ResetForm";
import FundraiserInfoForm from "./FundraiserInfoForm/FundraiserInfoForm";
import ProfileInfoForm from "./ProfileInfoForm/ProfileInfoForm";
import FinishForm from "./FinishForm/FinishForm";
import SignUpStore from "../../store/Registration/SignUpStore";
import SignUpForm from "./SignUpForm/index";
import {
  ACCESS_TOKEN,
  ACCESS_TOKEN_URL_PARAM,
} from "../../consts/localStorage";
import UserStore from "../../store/UserStore";
import ProfileMenuComponent from "../../components/navigation/ProfileMenu/ProfileMenu";

const RegistrationLayout = observer(() => {
  const history = useHistory();

  useEffect(() => {
    document.getElementById("preLoading")?.remove();
    const url = new URL(window.location.href);
    const accessToken = url.searchParams.get(ACCESS_TOKEN_URL_PARAM);
    if (localStorage.getItem(ACCESS_TOKEN) || accessToken) {
      UserStore.fetchData();
    } else {
      if (
        !history.location.pathname.includes(Path.fogotPassword) &&
        !history.location.pathname.includes("sign")
      ) {
        history.push(Path.signIn);
      }
    }
  }, [history]);

  const isSignInRoute = history.location.pathname === Path.signIn;

  return (
    <div className={styles.layoutWrapper}>
      <div className={styles.headerWrapper}>
        <a href="https://www.readformyschool.com/">
          <RfmsWhiteLogoIcon className={styles.logo} />
        </a>

        <div className={styles.rightHeaderContainer}>
          {localStorage.getItem(ACCESS_TOKEN) ? (
            <ProfileMenuComponent />
          ) : (
            <Button
              type="darkBlue"
              borderRadius={30}
              onClick={() =>
                history.push(isSignInRoute ? Path.signUp : Path.signIn)
              }
            >
              {isSignInRoute
                ? strings.RegistrationForms.signIn.sign_up
                : strings.RegistrationForms.signUp.log_in}
            </Button>
          )}
        </div>
      </div>

      {SignUpStore.showStepper(history.location.pathname) && (
        <div className={styles.leftContainer}>
          <div className={styles.timelintDescription}>
            <div className={styles.title}>
              {strings.RegistrationForms.signUp.timeline.title}
            </div>
            <div className={styles.description}>
              {strings.RegistrationForms.signUp.timeline.description}
            </div>
          </div>

          <Stepper
            content={signUpTimelint}
            activeStep={SignUpStore.signUpStep}
          />
        </div>
      )}

      <div className={styles.formWidthWrapper}>
        <div className={styles.formWrapper}>
          <Switch>
            <Route exact path={Path.signIn} component={SignInForm} />
            <Route exact path={Path.signUp} component={SignUpForm} />
            <Route exact path={Path.fogotPassword} component={ResetForm} />
            <Route
              exact
              path={Path.signUpPricingModel}
              component={PricingModelForm}
            />
            <Route
              exact
              path={Path.signUpSchoolInfo}
              component={SchoolInfoForm}
            />
            <Route
              exact
              path={Path.signUpFundraiserInfo}
              component={FundraiserInfoForm}
            />
            <Route
              exact
              path={Path.signUpProfileInfo}
              component={ProfileInfoForm}
            />
            <Route exact path={Path.signUpFinish} component={FinishForm} />
          </Switch>
        </div>
      </div>

      <div className={styles.footer}>
        <a href="https://www.readformyschool.com/">
          {strings.formatString(
            strings.RegistrationForms.footerLabel,
            new Date().getFullYear()
          )}
        </a>
      </div>
    </div>
  );
});

export default withRouter(RegistrationLayout);
