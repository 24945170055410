import {IEvents, IUserResponse} from "../api/apiResponseTypes";
import {Event} from "../models/Event";


export class IntercomHelper {

    public static getBootProps(user: IUserResponse, color: string) {
        let event = (user.events && user.events[0]) || {} as IEvents;
        console.log('bootIntercom, user: ', user);
        return {
            email: user.email,
            userId: user.id + '',
            country: event.location?.country,
            name: (user.firstName ? user.firstName + ' ' + user.lastName : user.email) + ' (' + user.role + ')',
            customAttributes: {
                role: user.role,
                fundraiser_id: event.id,
                closed: event.closed,
                fundraiser_name: event.name,
                currency: event?.currency,
                school_name: event.location?.schoolName,
                school_zip: event.location?.zip,
                school_country: event.location?.country
            },
            backgroundColor: color,
            actionColor: color
        }
    }

    public static getUpdateProps(event: Event) {
        console.log('updateIntercom');
        return {
            customAttributes: {
                fundraiser_id: event.id,
                closed: event.closed,
                fundraiser_name: event.name,
                currency: event?.currency,
                stripe_account: event.stripeAccount ?  event.stripeAccount.email : null,
                stripe_account_id: event.stripeAccount ? event.stripeAccount.accountId : null,
                business_model: event.stripeAccount ? event.stripeAccount.businessModel: null,
                fundraiser_donations: event.raisedSum,
                fundraiser_minutes: event.minsSum,
                school_name: event.location?.schoolName,
                school_zip: event.location?.zip,
                school_country: event.location?.country
            }
        }
    }
}
