import React from "react";
import strings from "../../../../i18n/strings";
import {Button} from "../../../components/common";
import {Link} from "react-router-dom";
import {ReactComponent as CopyIcon} from "../../../../images/icons/students/copy.svg";
import {ReactComponent as PrintIcon} from "../../../../images/menuIcons/print.svg";

import styles from "./InviteDialog.module.scss";
import {Path} from "../../../consts/router";
import SnackBarStore from "../../../store/SnackBarStore";
import EventStore from "../../../store/EventStore";

interface IProps {
  inviteCode: string;
}

const InviteDialogContent = ({inviteCode}: IProps) => {
  const copyToBufferLink = () => {
    navigator.clipboard.writeText(url);

    SnackBarStore.setSnackbar(
      strings.students.invite_dialog.copy_success,
      "success"
    );
  };

  const url = strings
    .formatString(
      window.location.host.startsWith('dev-') ?
        strings.students.invite_dialog.link_dev :
        strings.students.invite_dialog.link, inviteCode)
    .toString();
  const code = EventStore.event?.inviteCode;
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.link} onClick={() => copyToBufferLink()}>
        <span className={styles.urlText}>{url}</span>
        <CopyIcon className={styles.copyIcon}/>
      </div>

      <Button type="primary" startIcon={<PrintIcon fill="white"/>}>
        <Link
          to={`${Path.fundraiserInviteLetter}?code=${code}`}
          target="_blank"
          className={styles.letterLink}
        >
          {strings.students.invite_dialog.print_parrent_letter}
        </Link>
      </Button>

      <div className={styles.inviteCodeWrapper}>
        {strings.formatString(
          strings.students.invite_dialog.invite_code,
          <span>{inviteCode}</span>
        )}
      </div>
    </div>
  );
};

export default InviteDialogContent;
