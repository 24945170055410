import {observer} from "mobx-react-lite";
import React from "react";
import {IconButton, Menu} from "@material-ui/core";
import strings from "../../../../i18n/strings";
import Avatar from "@material-ui/core/Avatar";
import TeamStore from "../../../store/TeamsStores/TeamStore";
import {SortDirectionEnum, SortTeamColsEnum,} from "../../../consts/TeamMembers";
import {ReactComponent as MoreIcon} from "../../../../images/icons/more.svg";
import {ReactComponent as SortIcon} from "../../../../images/icons/sort.svg";

import styles from "./TeamsTable.module.scss";
import {formatCurrency, getAvatarBgColorByName, transformNumberValue,} from "../../../utils/common";
import {useHistory} from "react-router";
import {Path} from "../../../consts/router";
import TeamEditStore from "../../../store/TeamsStores/TeamEditStore";
import EventStore from "../../../store/EventStore";
import {Checkbox, MenuItem} from "../../../components/common";
import TeamNotificationDeleteDialog from "../TeamsDialog/TeamNotificationDeleteDialog";
import TeamConfirmDeleteDialog from "../TeamsDialog/TeamConfirmDeleteDialog";
import {ITeamsResponse} from "../../../api/apiResponseTypes";
import {Link} from "react-router-dom";
import DialogsStore, {Dialogs} from "../../../store/DialogsStore";
import TeamResendInviteDialog from "../TeamsDialog/TeamResendInviteDialog";
import TeamMembersStore from "../../../store/TeamsStores/TeamMembersStore";
import DonationsStore from "../../../store/DonationsStore";
import UserStore from "../../../store/UserStore";

const TeamsTable = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const history = useHistory();
  const currency = formatCurrency(EventStore.event?.currency);

  const handleClick = (event: any, id: number) => {
    setAnchorEl(event.currentTarget);
    TeamEditStore.selectIdTeam(id);
  };

  const onRouteEditTeam = () => {
    history.push(`${Path.teams}/${TeamEditStore.selectedIdTeam}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getActiveSortColumnStyle = (column: SortTeamColsEnum) => {
    return TeamStore.activeSortCol === SortTeamColsEnum[column]
      ? styles.activeSortCol
      : "";
  };

  const minSumClassName = (item: ITeamsResponse) => `${
    item.progress.minsSum === 0 ? styles.grayColor : ""
  }
  ${getActiveSortColumnStyle(SortTeamColsEnum.MINUTES)}
`;

  const bookSumClassName = (item: ITeamsResponse) => `${
    item.progress.minsSum === 0 ? styles.grayColor : ""
  }
${getActiveSortColumnStyle(SortTeamColsEnum.BOOKS)}
`;

  const raisedSumClassName = (item: ITeamsResponse) => `${
    item.progress.minsSum === 0 ? styles.grayColor : ""
  }
${getActiveSortColumnStyle(SortTeamColsEnum.DONATIONS)}
`;

  const renderDonationValue = (item: ITeamsResponse) => {
    if (item.progress.raisedSum > 0) {
      return (
        <div className="donationLink">
          <Link
            to={`${Path.donations}?teamId=${item.id}`}
            onClick={() => DonationsStore.handleChangeFilterByTeamId(item.id)}
          >
            {currency + transformNumberValue(item.progress.raisedSum)}
          </Link>
        </div>
      );
    }

    return currency + item.progress.raisedSum;
  };

  const renderStudentsValue = (item: ITeamsResponse) => {
    if (item.size > 0) {
      return (
        <div className="donationLink">
          <Link
            to={UserStore.user?.role === 'TL' && !item.selected ? Path.teams : `${Path.students}?teamId=${item.id}`}
            onClick={() =>
              TeamMembersStore.handleChangeFilterByTeamId(item.id.toString())
            }
          >
            {`${item.size} of ${item.targetSize}`}
          </Link>
        </div>
      );
    }

    return `${item.size} of ${item.targetSize}`;
  };

  const isAvailableResend =
    TeamEditStore.teamBySelectedId?.owner.email &&
    !TeamEditStore.teamBySelectedId?.owner.activeAt;

  function onSelect(item: ITeamsResponse) {
    return () => {
      if (item.selected) {
        TeamStore.deleteTLTeam(item.id)
      } else {
        TeamStore.selectTLTeam(item.id)
      }
      setTimeout(() => {
        DonationsStore.fetchDataList();
        TeamMembersStore.fetchTeamMember()
      }, 1000)
    };
  }

  return (
    <>
      <table className="tableWithHover">
        <thead>
          <tr>
            <th
              className={`${
                styles.teamLeadNameColumn
              } ${getActiveSortColumnStyle(SortTeamColsEnum.NAME)}`}
              onClick={() => TeamStore.setActiveSortCol(SortTeamColsEnum.NAME)}
            >
              <div className={styles.flexWrapper}>
                <span>{strings.teams.table_columns.name}</span>

                <div
                  hidden={
                    !(
                      TeamStore.activeSortCol &&
                      TeamStore.activeSortCol === SortTeamColsEnum.NAME
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamStore.sortDirection === SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            {UserStore.user?.role==='TL' &&
            <th>
              <div className={styles.flexWrapper}>
                <span>{strings.teams.table_columns.my}</span>
              </div>
            </th>}

            <th
              className={`${
                styles.registredCountColumn
              } ${getActiveSortColumnStyle(SortTeamColsEnum.STUDENTS_COUNT)}`}
              onClick={() =>
                TeamStore.setActiveSortCol(SortTeamColsEnum.STUDENTS_COUNT)
              }
            >
              <div className={styles.flexWrapper}>
                <span>{strings.teams.table_columns.registredCount}</span>

                <div
                  hidden={
                    !(
                      TeamStore.activeSortCol &&
                      TeamStore.activeSortCol ===
                        SortTeamColsEnum.STUDENTS_COUNT
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamStore.sortDirection === SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            <th
              className={`${styles.sortCol} ${getActiveSortColumnStyle(
                SortTeamColsEnum.GRADE
              )}`}
              onClick={() => TeamStore.setActiveSortCol(SortTeamColsEnum.GRADE)}
            >
              <div className={styles.flexWrapper}>
                <span>{strings.teams.table_columns.grade}</span>

                <div
                  hidden={
                    !(
                      TeamStore.activeSortCol &&
                      TeamStore.activeSortCol === SortTeamColsEnum.GRADE
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamStore.sortDirection === SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            <th
              className={`${styles.sortCol} ${getActiveSortColumnStyle(
                SortTeamColsEnum.MINUTES
              )}`}
              onClick={() =>
                TeamStore.setActiveSortCol(SortTeamColsEnum.MINUTES)
              }
            >
              <div className={styles.flexWrapper}>
                <span>{strings.teams.table_columns.minutes}</span>

                <div
                  hidden={
                    !(
                      TeamStore.activeSortCol &&
                      TeamStore.activeSortCol === SortTeamColsEnum.MINUTES
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamStore.sortDirection === SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            <th
              className={`${styles.sortCol} ${getActiveSortColumnStyle(
                SortTeamColsEnum.BOOKS
              )}`}
              onClick={() => TeamStore.setActiveSortCol(SortTeamColsEnum.BOOKS)}
            >
              <div className={styles.flexWrapper}>
                <span>{strings.teams.table_columns.books}</span>

                <div
                  hidden={
                    !(
                      TeamStore.activeSortCol &&
                      TeamStore.activeSortCol === SortTeamColsEnum.BOOKS
                    )
                  }
                >
                  <SortIcon
                    className={
                      TeamStore.sortDirection === SortDirectionEnum.ASCENDING
                        ? styles.sortDirection
                        : ""
                    }
                  />
                </div>
              </div>
            </th>

            <th
              className={`${styles.donationCol} ${getActiveSortColumnStyle(
                SortTeamColsEnum.DONATIONS
              )}`}
              onClick={() =>
                TeamStore.setActiveSortCol(SortTeamColsEnum.DONATIONS)
              }
            >
              <span>{strings.teams.table_columns.donations}</span>
            </th>

            <th className={styles.actionCol}>
              <div
                hidden={
                  !(
                    TeamStore.activeSortCol &&
                    TeamStore.activeSortCol === SortTeamColsEnum.DONATIONS
                  )
                }
              >
                <SortIcon
                  className={
                    TeamStore.sortDirection === SortDirectionEnum.ASCENDING
                      ? styles.sortDirection
                      : ""
                  }
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <>
            {TeamStore.items.length ? (
              <>
                {TeamStore.items.map((item) => (
                  <tr key={item.id} className={UserStore.user?.role==='TL' && !item.selected ? styles.disabledTeamRow: ''}>
                    <td className={styles.teamLeadNameCell}>
                      <Avatar
                        style={{
                          backgroundColor: getAvatarBgColorByName(item.name),
                        }}
                      >
                        {`${item.name.substr(0, 1).toUpperCase()}`}
                      </Avatar>

                      <Link
                          to={UserStore.user?.role === 'TL' && !item.selected ? Path.teams : `${Path.teams}/${item.id}`}
                          className={styles.nameWrapper}
                      >
                        <span className={styles.name}>{item.name}</span>
                        {/*<br />*/}
                        {/*{item.owner.firstName?.length > 1 && (*/}
                        {/*    <span className={styles.teacherName}>*/}
                        {/*    {strings.teams.team_lead}: {item.owner.firstName}{" "}*/}
                        {/*      {item.owner.lastName}*/}
                        {/*      {item.owner.email && !item.owner.activeAt && (*/}
                        {/*          <Tooltip*/}
                        {/*              title={strings.teams.pending_icon_tooltip}*/}
                        {/*          >*/}
                        {/*            <PendingIcon className={styles.pendingIcon} />*/}
                        {/*          </Tooltip>*/}
                        {/*      )}*/}
                        {/*  </span>*/}
                        {/*)}*/}
                        {/*<span className={styles.email}>{item.owner.email}</span>*/}
                      </Link>
                    </td>

                    {UserStore.user?.role==='TL' &&
                    <td>
                      <Checkbox checked={item.selected} onChange={onSelect(item)}/>
                    </td>
                    }

                    <td
                      className={getActiveSortColumnStyle(
                        SortTeamColsEnum.STUDENTS_COUNT
                      )}
                    >
                      {renderStudentsValue(item)}
                    </td>

                    <td
                      className={getActiveSortColumnStyle(
                        SortTeamColsEnum.GRADE
                      )}
                    >
                      {item.gradeDisplay}
                    </td>

                    <td className={minSumClassName(item)}>
                      {transformNumberValue(item.progress.minsSum)}
                    </td>

                    <td className={bookSumClassName(item)}>
                      {transformNumberValue(item.progress.booksSum)}
                    </td>

                    <td className={raisedSumClassName(item)}>
                      {renderDonationValue(item)}
                    </td>

                    <td className={styles.actionCell}>
                      {UserStore.user?.role === 'TL' && !item.selected ? '':
                      <IconButton onClick={(e) => handleClick(e, item.id)}>
                        <MoreIcon />
                      </IconButton>}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <div className="noDataTable">
                <>
                  <div>{strings.teams.no_data1}</div>
                </>
              </div>
            )}
          </>
        </tbody>
      </table>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div onClick={handleClose}>
          <MenuItem onClick={onRouteEditTeam}>
            {strings.teams.edit_team}
          </MenuItem>

          {isAvailableResend && (
            <MenuItem
              onClick={() =>
                DialogsStore.setActiveDialog(Dialogs.RESEND_INVITE_TEAM_LEADER)
              }
            >
              {strings.teams.resend_invite_to_team_leader}
            </MenuItem>
          )}

          {UserStore.user?.role === 'EA' &&
          <MenuItem
            onClick={() =>
              TeamStore.onPressDeleteById(TeamEditStore.selectedIdTeam!)
            }
          >
            {strings.teams.delete_team}
          </MenuItem>}
        </div>
      </Menu>

      <TeamNotificationDeleteDialog />
      <TeamConfirmDeleteDialog />
      <TeamResendInviteDialog />
    </>
  );
});

export default TeamsTable;
