import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";

import styles from "./SignInForm.module.scss";
import strings from "../../../../i18n/strings";
import { Button, Input } from "../../../components/common";
import { Divider } from "@material-ui/core";
import { ReactComponent as ArrowRightIcon } from "../../../../images/icons/arrows/arrow-right.svg";
import { ReactComponent as FaceBookIcon } from "../../../../images/signIn/faceBookIcon.svg";
import { ReactComponent as GoogleIcon } from "../../../../images/signIn/googleIcon.svg";
import SignInStore from "../../../store/Registration/SignInStore";
import { useHistory } from "react-router";
import { Path } from "../../../consts/router";
import { Link } from "react-router-dom";
import AppStore from "../../../store/AppStore";
import { onRedirectToAuth } from "../../../utils/common";

const SignInForm = observer(() => {
  const history = useHistory();

  const onSignInWithEmail = useCallback(async () => {
    const data = !SignInStore.validForm() && (await SignInStore.login());
    if (data) {
      history.push(Path.main);
      AppStore.setLayout();
    }
  }, [history]);

  const listener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onSignInWithEmail();
      }
    },
    [onSignInWithEmail]
  );

  useEffect(() => {
    window.addEventListener("keypress", listener);

    return () => window.removeEventListener("keypress", listener);
  }, [listener]);

  return (
    <div className={styles.formWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>
          {strings.RegistrationForms.signIn.title}
        </div>

        <div className={styles.descriptionWrpper}>
          <div>{strings.RegistrationForms.signIn.description1}</div>

          <div>
            {strings.formatString(
              strings.RegistrationForms.signIn.description2,
              <Link to={Path.signUp}>
                {strings.RegistrationForms.signIn.creat_your_account}
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className={styles.bodyWrapper}>
        <div className={styles.loginMethodsWrapper}>
          <Button
            width={225}
            buttonColor={styles.facebook_btnColor}
            textColor="white"
            startIcon={<FaceBookIcon />}
            onClick={() => onRedirectToAuth("facebook")}
          >
            {strings.RegistrationForms.signIn.signIn_with_facebook}
          </Button>

          <Button
            width={225}
            type="accent"
            startIcon={<GoogleIcon />}
            onClick={() => onRedirectToAuth("google")}
          >
            {strings.RegistrationForms.signIn.signIn_with_google}
          </Button>
        </div>

        <div className={styles.dividerWrapper}>
          <Divider className="divider" />
          <div className={styles.or}>{strings.RegistrationForms.signIn.or}</div>
        </div>

        <div className={styles.signinEmailWrapper}>
          <Input
            placeholder={strings.RegistrationForms.signIn.email}
            label={strings.RegistrationForms.signIn.email}
            value={SignInStore.email}
            onChange={SignInStore.handleChangeEmail}
          />

          <Input
            placeholder={strings.RegistrationForms.signIn.password}
            label={strings.RegistrationForms.signIn.password}
            value={SignInStore.password}
            type="password"
            onChange={SignInStore.handleChangePassword}
          />
        </div>
      </div>

      <div className={styles.footerWrapper}>
        <div>
          <Link to={Path.fogotPassword} className={styles.forgotPasswordBtn}>
            {strings.RegistrationForms.signIn.forgot_password}
          </Link>

          <Button
            width={225}
            type="primary"
            disabled={SignInStore.validForm()}
            loading={SignInStore.loading}
            onClick={onSignInWithEmail}
            endIcon={
              SignInStore.loading ? <></> : <ArrowRightIcon fill="white" />
            }
          >
            {strings.RegistrationForms.signIn.signIn_with_email}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default SignInForm;
