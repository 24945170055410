import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory } from 'react-router';
import strings from '../../../../i18n/strings';
import { Path } from '../../../consts/router';
import AppStore from '../../../store/AppStore';
import DialogsStore, { Dialogs } from '../../../store/DialogsStore';
import UserStore from '../../../store/UserStore';
import { MenuItem, ProfileMenu } from '../../common';

import styles from './ProfileMenu.module.scss';

const ProfileMenuComponent = observer(() => {
  const history = useHistory();

  const onRouteAccountSettings = () => {
    history.push(Path.account);
    AppStore.setLayout();
  };

  const onLogout = () => {
    DialogsStore.setActiveDialog(Dialogs.CONFIRM_LOGOUT);
  };

  return (
    <div>
      <ProfileMenu avatar={UserStore.user?.avatar} name={UserStore.titleName}>
        <MenuItem divider>
          <div className={styles.profileMenu}>
            <span className={styles.name}>{UserStore.displayName}</span>
            <span className={styles.email}>{UserStore.email}</span>
          </div>
        </MenuItem>
        <MenuItem onClick={onRouteAccountSettings}>{strings.profile_menu.account_settings}</MenuItem>
        {/*<MenuItem>{strings.profile_menu.billing_settings}</MenuItem>*/}

        <MenuItem onClick={onLogout}>
          {strings.profile_menu.log_out}
        </MenuItem>
      </ProfileMenu>
    </div>
  )
})

export default ProfileMenuComponent;