import React from 'react';
import {observer} from 'mobx-react-lite';
import strings from '../../../../i18n/strings';
import {Button, Input} from '../../../components/common';

import styles from './MiniForms.module.scss';
import AccountSettingsStore from '../../../store/AccountSettingsStore';

const PasswordForm = observer(() => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.bodyWrapper}>
        <Input
          width={200}
          type='password'
          label={strings.account_settings.enter_password}
          placeholder={strings.account_settings.enter_password}
          value={AccountSettingsStore.data.password}
          onChange={(val) => AccountSettingsStore.handleChangeData(val, 'password')}
        />

        <Input
          width={200}
          type='password'
          label={strings.account_settings.confirm_password}
          placeholder={strings.account_settings.confirm_password}
          value={AccountSettingsStore.data.repeatPassword}
          onChange={(val) => AccountSettingsStore.handleChangeData(val, 'repeatPassword')}
        />
      </div>

      <div className={styles.footerWrapper}>
        <Button
          type='primary'
          width={90}
          onClick={AccountSettingsStore.savePassword}
          loading={AccountSettingsStore.loading}

        >
          {strings.buttons.save}
        </Button>

        <Button
          disabled={AccountSettingsStore.loading}
          onClick={() => AccountSettingsStore.clearState()}>
          {strings.buttons.cancel}
        </Button>
      </div>
    </div>
  )
})

export default PasswordForm;
