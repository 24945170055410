import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core";

import styles from "./ComboBox.module.scss";

interface IProps {
  options: { label: string; value: string | number }[];
  width?: number | string;
  label?: string;
  value?: string | number;
  size?: "small" | "medium";
  disableClearable?: boolean;
  onChange?: (value: string) => void;
}

const StyledTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "14px",
      display: "flex",
      // height: '44px',
      alignContent: "center",

      "& fieldset": {
        borderColor: styles.borderColor,
      },
      "&:hover fieldset": {
        borderColor: styles.borderColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: styles.borderColorFocused,
      },
    },

    "& .MuiFormLabel-root": {
      color: styles.labelTextColor,
      fontSize: "14px",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: styles.focusedTextColor,
    },
  },
})(TextField);

export const ComboBox = (props: IProps) => {
  const {
    options,
    width,
    label,
    value,
    size = "small",
    disableClearable,
    onChange,
  } = props;

  const getSelectedValue = () =>
    options.find((opt) => opt.value.toString() === value?.toString());

  return (
    <Autocomplete
      id="combo-box"
      options={options}
      getOptionLabel={(option) => option.label || ""}
      style={{ width: width }}
      disableClearable={disableClearable}
      value={getSelectedValue()}
      onChange={(e, value: any) => {
        if (onChange) {
          onChange(value?.value.toString() || "Any");
        }
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={label}
          size={size}
          InputLabelProps={{
            style: {
              color: styles.labelTextColor,
              fontFamily: "Montserrat",
              fontWeight: 400,
              fontSize: "14px",
            },
          }}
          variant="outlined"
        />
      )}
    />
  );
};
