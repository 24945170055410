export const pathLS = 'rfms.ea'
export const ACCESS_TOKEN = `${pathLS}.accessToken`;

export const TEAMS_SORT_COLUMN = `${pathLS}.teamsSortColumn`;
export const TEAMS_SORT_ORDER = `${pathLS}.teamsSortOrder`;

export const ACCESS_TOKEN_URL_PARAM = 'accessToken';
export const AFFILIATE_PARTNER_ID =  `${pathLS}.affiliatePartnerId`;
export const AFFILIATE_PARTNER_ID_PARAM = 'affiliatePartnerId';
export const EVENT_ID = `${pathLS}.currentEventId`;

export const langLS = 'lang'
export const LANGUAGE = `${pathLS}.${langLS}`;


export const BUSINESS_MODEL = `${pathLS}.businessModel`

export const signUpData = `${pathLS}.signUpData`;
