import { action, computed, makeAutoObservable, observable } from "mobx";
import strings from "../../i18n/strings";
import { apiClient } from "../api/api";
import SnackBarStore from "./SnackBarStore";

class AnnouncementStore {
  @observable subject: string = "";
  @observable message: string = "";

  @observable isCheckedMe: boolean = false;
  @observable isCheckedStudents: boolean = false;
  @observable isCheckedTeachers: boolean = false;
  @observable isCheckedDonors: boolean = false;

  @observable isEditMode: boolean = true;
  @observable loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound
  onChangeMessage = (value: string) => (this.message = value);

  @action.bound
  onChangeSubject = (value: string) => (this.subject = value);

  @action.bound
  onCheckMe = () => (this.isCheckedMe = !this.isCheckedMe);

  @action.bound
  onCheckStudents = () => (this.isCheckedStudents = !this.isCheckedStudents);

  @action.bound
  onCheckTeachers = () => (this.isCheckedTeachers = !this.isCheckedTeachers);


  @action.bound
  onCheckDonors = () => (this.isCheckedDonors = !this.isCheckedDonors);

  @action.bound
  onClearData = () => {
    this.message = "";
    this.subject = "";
    this.isCheckedMe = false;
    this.isCheckedStudents = false;
    this.isCheckedTeachers = false
    this.isCheckedDonors = false;
  };

  @computed
  checkAvailableSave = () =>
    (this.isCheckedDonors || this.isCheckedMe || this.isCheckedStudents || this.isCheckedTeachers) &&
    !!this.subject &&
    !!this.message;

  @action.bound
  onSendAnnoncement = async () => {
    if (!this.isEditMode) {
      this.isEditMode = true;
      this.onClearData();
      return;
    }

    try {
      this.loading = true;
      const body = new FormData();
      const toParameter = [];

      if (this.isCheckedDonors) {
        toParameter.push("DR");
      }

      if (this.isCheckedStudents) {
        toParameter.push("TM");
      }

      if (this.isCheckedTeachers) {
        toParameter.push("TL");
      }

      if (this.isCheckedMe) {
        toParameter.push("EA");
      }

      body.append("subject", this.subject);
      body.append("message", this.message);
      body.append("to", toParameter.toString());

      await apiClient
        .sendAnnouncement(body)
        .then(() => (this.isEditMode = false));

      SnackBarStore.setSnackbar(
        strings.announcement.success_message,
        "success"
      );
    } catch (e) {
      console.error("AnnouncementStore.onSendAnnoncement", e);
    } finally {
      this.loading = false;
    }
  };
}

export default new AnnouncementStore();
