import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import strings from "../../../i18n/strings";
import { Tooltip, Button } from "../../components/common";
import { ReactComponent as HelperIcon } from "../../../images/icons/helper.svg";
import styles from "./Announcement.module.scss";
import AnnouncementStore from "../../store/AnnouncementStore";
import AnnouncementForm from "./AnnouncementForm";
import AnnouncementInfo from "./AnnouncementInfo";
import TeachersStore from "../../store/TeamsStores/TeachersStore";
import TeamMembersStore from "../../store/TeamsStores/TeamMembersStore";

const Announcement = observer(() => {
  //const isDisabledSendBtn = AnnouncementStore.checkAvailableSave();
  const isEditMode = AnnouncementStore.isEditMode;
  useEffect(() => {
    TeachersStore.fetchList()
    TeamMembersStore.fetchTeamMember()
  });

  return (
    <div className="screenWrapper">
      <div className="content">
        <>
          <div className="contentWithShadowWrapper" style={{ minHeight: 400 }}>
            <div className={styles.wrapper}>
              <div className={styles.titleWrapper}>
                <span className={styles.title}>
                  {strings.announcement.title}
                </span>
                <Tooltip title={strings.announcement.tips.title_tip}>
                  <HelperIcon fill={styles.blue} />
                </Tooltip>
              </div>

              {isEditMode ? <AnnouncementForm /> : <AnnouncementInfo />}

              <Button
                type="primary"
                className={styles.sendBtn}
                disabled={!AnnouncementStore.checkAvailableSave()}
                loading={AnnouncementStore.loading}
                width={190}
                onClick={AnnouncementStore.onSendAnnoncement}
              >
                {isEditMode
                  ? strings.announcement.send_announcement
                  : strings.announcement.send_another}
              </Button>
            </div>
          </div>
        </>
      </div>
    </div>
  );
});

export default Announcement;
