import React from "react";
import { observer } from "mobx-react-lite";
import { Button, InfoCard } from "../../../components/common";

import styles from "../Overview.module.scss";
import strings from "../../../../i18n/strings";
import { Divider } from "@material-ui/core";
import { ReactComponent as HelperIcon } from "../../../../images/icons/helper.svg";
import EventStore from "../../../store/EventStore";
import { transformNumberValue } from "../../../utils/common";

const ReadingEmptyWidget = observer(() => (
  <>
    <div className={styles.widgetsWrapper}>
      <div className={styles.progressWrapper}>
        <InfoCard
          titleColor="secondary"
          tooltipBgColor="purple"
          title={
            <div>
              <span className={styles.raisedFrom}>0</span>
              <span>
                {` / ${transformNumberValue(EventStore.event?.readingMins)}`}
              </span>
            </div>
          }
          description={
            <div className={styles.receivedDonation}>
              <span>{strings.overview.minutes_read_goal}</span>
              <HelperIcon fill={styles.tickStroke} />
            </div>
          }
          // description={strings.formatString(strings.overview.minutes_read_goal, transformNumberValue(EventStore.event?.readingMins || 0))}
          tooltip={strings.overview.tips.minutes_read_Goal}
        />
      </div>

      <InfoCard
        titleColor="secondary"
        title={0}
        tooltipBgColor="purple"
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.reading_sessions}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.reading_sessions}
      />

      <InfoCard
        titleColor="secondary"
        title={0}
        tooltipBgColor="purple"
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.books_completed}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.books_completed}
      />

      <InfoCard
        titleColor="secondary"
        title={"0%"}
        tooltipBgColor="purple"
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.students_are_reading}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.students_are_reading}
      />
    </div>

    <div className={styles.readingEmptyWrapper}>
      <Divider className={styles.divider} />

      <div className={styles.flexWrapper}>
        <div className={styles.readingWrapper}>
          <div className={styles.title}>
            {strings.overview.empty_widget.title_reading}
          </div>

          <div className={styles.description}>
            {strings.overview.empty_widget.description_reading1}
          </div>
        </div>

        <a
          href={strings.overview.unpaid_widget.how_it_works_Link}
          target="_blank"
          rel="noreferrer"
        >
          <Button border borderColor="purple" textColor="purple">
            {strings.overview.unpaid_widget.how_it_works}
          </Button>
        </a>
      </div>
    </div>
  </>
));

export default ReadingEmptyWidget;
