import React, { useEffect } from "react";
import strings from "../../../i18n/strings";
import TeamMembersStore from "../../store/TeamsStores/TeamMembersStore";
import {
  Button,
  ComboBox,
  Input,
  Loader,
  Tooltip,
} from "../../components/common";
import StudentsTable from "./StudentsTable/StudentsTable";
import { ReactComponent as SearchIcon } from "../../../images/icons/search.svg";
import { ReactComponent as DownloadIcon } from "../../../images/icons/students/download.svg";
import { ReactComponent as InviteIcon } from "../../../images/icons/invite.svg";

import styles from "./Students.module.scss";
import { observer } from "mobx-react-lite";
import { throttle } from "lodash";
import TeamStore from "../../store/TeamsStores/TeamStore";
import { ReactComponent as HelperIcon } from "../../../images/icons/helper.svg";
import { ReactComponent as PlusIcon } from "../../../images/icons/plus.svg";
import { useHistory } from "react-router";
import { Path } from "../../consts/router";
import EventStore from "../../store/EventStore";
import { IOption } from "../../components/common/Select/Select";
import UserStore from "../../store/UserStore";

const Students = observer(() => {
  useEffect(() => {
    TeamMembersStore.clearFilters();
    EventStore.fetchAchievements();
    TeamMembersStore.fetchTeamMember();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const teamId = url.searchParams.get("teamId");

    if (teamId) {
      TeamMembersStore.handleChangeFilterByTeamId(teamId);
    }
  }, []);

  const history = useHistory();
  const hasStudents = TeamMembersStore.data?.length !== 0;
  const hasTeams = TeamStore.items.length !== 0;

  const routeCreateTeam = () => {
    history.push(Path.teamsCreate);
  };

  let teams = TeamStore.TeamOptions;
  if (UserStore.user?.role === 'TL') {
    teams = teams.filter(team => !team.disabled);
  }
  return (
    <div className="screenWrapper">
      <div className="content">
        <div className="contentWrapper">
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <div className={styles.titleWrapper}>
                <div className={styles.titleWithTooltip}>
                  <span className={styles.title}>
                    {strings.students.head.title}
                  </span>
                  <Tooltip
                    title={strings.students.head.tooltip}
                    link={strings.students.head.tooltip_link}
                  >
                    <HelperIcon fill={styles.blueMain}/>
                  </Tooltip>
                </div>

                {!hasStudents && hasTeams && (
                  <span className={styles.titleDescription}>
                    {strings.students.head.no_students}
                  </span>
                )}

                {!hasTeams && (
                  <span className={styles.titleDescription}>
                    {strings.students.head.no_teams}
                  </span>
                )}
              </div>

              <div className={styles.headerControls}>
                {hasStudents && (
                  <Button
                    border
                    loading={TeamMembersStore.isExportLoading}
                    startIcon={
                      !TeamMembersStore.isExportLoading ? (
                        <DownloadIcon fill={styles.downLoadIconColor}/>
                      ) : (
                        <></>
                      )
                    }
                    onClick={TeamMembersStore.exportTeamMembers}
                  >
                    {strings.students.head.export_btn}
                  </Button>
                )}

                {hasTeams ? (
                  <Button
                    type="primary"
                    onClick={TeamMembersStore.handleOpenInviteDialog}
                    startIcon={<InviteIcon/>}
                  >
                    {strings.students.head.invite_btn}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    startIcon={<PlusIcon/>}
                    onClick={routeCreateTeam}
                  >
                    {strings.teams.head.create_btn}
                  </Button>
                )}
              </div>
            </div>

            <div className={styles.filtersWrapper} hidden={!hasStudents}>
              <Input
                startAdornment={<SearchIcon/>}
                onChange={throttle(
                  TeamMembersStore.handleChangeFilterByName,
                  100
                )}
                placeholder={strings.students.filters.search_by_name_email}
                width={"38%"}
              />

              <div style={{ width: 10 }}></div>

              <ComboBox
                width={"38%"}
                label={strings.students.filters.team}
                options={teams}
                value={TeamMembersStore.filterByTeamId}
                onChange={TeamMembersStore.handleChangeFilterByTeamId}
              />

              <div style={{ width: 10 }}></div>

              <ComboBox
                width={"25%"}
                label={strings.students.filters.achievement}
                options={EventStore.achievements.map(a => {
                  return { value: a.id, label: a.name } as IOption
                })}
                value={TeamMembersStore.filterByAchievementId}
                onChange={TeamMembersStore.handleChangeFilterByAchievementId}
              />
            </div>
          </div>
        </div>

        <div className={styles.tableWrapper}>
          {TeamMembersStore.loading ? (
            <Loader/>
          ) : (
            <StudentsTable
              data={TeamMembersStore.filteredData}
              sortDirection={TeamMembersStore.sortDirection}
              activeCol={TeamMembersStore.activeSortCol}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default Students;
