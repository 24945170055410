import React from 'react';
import ReactDOM from 'react-dom';
import App from './js/App';
import './styles/index.scss';
import {IntercomProvider} from "react-use-intercom";

const INTERCOM_APP_ID = 'hlicm2hh';

ReactDOM.render(
    <React.StrictMode>
        <IntercomProvider appId={INTERCOM_APP_ID}>
            <App/>
        </IntercomProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
