import { action, computed, makeAutoObservable, observable } from "mobx";
import { Path } from "../consts/router";
import { formatCurrency } from "../utils/common";

export enum LayoutsEnum {
  MAIN_LAYOUT = "MAIN LAYOUT",
  REGISTRATION_LAYOUT = "REGISTRATION LAYOUT",
}

class AppStore {
  @observable public activePath: string =
    window.location.pathname || Path.fundraiser;

  @observable public currency: string = "";

  @observable public layoutDisplay?: LayoutsEnum;

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound
  setLayout = () => {
    if (window.location.href.includes("/sign")) {
      this.layoutDisplay = LayoutsEnum.REGISTRATION_LAYOUT;
    } else {
      this.layoutDisplay = LayoutsEnum.MAIN_LAYOUT;
    }
  };

  @action.bound
  setCurrency = (currency: string) => {
    this.currency = formatCurrency(currency);
  };

  @computed
  getBreadCrumbByPath = (path: string) => {
    switch (path) {
      case Path.main:
        return (this.activePath = "/ Overview");
      case Path.fundraiser:
        return (this.activePath = "/ Fundraiser Details");
      case Path.students:
        return (this.activePath = "/ Students");
      case Path.teams:
        return (this.activePath = "/ Teams");
      case Path.donations:
        return (this.activePath = "/ Donations");
      case Path.teamsCreate:
        return (this.activePath = "/ Teams / Create a team");
      case Path.teamsEdit:
        return (this.activePath = "/ Teams / Edit a team");
      case Path.account:
        return (this.activePath = "/ Account settings");

      default:
        return "/ none describe bread crumb";
    }
  };
}

export default new AppStore();
