import { observer } from "mobx-react-lite";
import strings from "../../../../i18n/strings";
import { Divider } from "@material-ui/core";
import styles from "../Overview.module.scss";
import { Button, InfoCard } from "../../../components/common";
import EventStore from "../../../store/EventStore";
import { transformNumberValue } from "../../../utils/common";
import { ReactComponent as HelperIcon } from "../../../../images/icons/helper.svg";

const ReadingUnfullWidget = observer(() => (
  <>
    <div className={styles.widgetsWrapper}>
      <div className={styles.progressWrapper}>
        <InfoCard
          titleColor="purple"
          tooltipBgColor="purple"
          title={<span className={styles.raisedFrom}>0</span>}
          description={
            <div className={styles.receivedDonation}>
              <span>
                {strings.formatString(
                  strings.overview.minutes_read_from,
                  transformNumberValue(EventStore.event?.readingMins)
                )}
              </span>
              <HelperIcon fill={styles.tickStroke} />
            </div>
          }
          tooltip={strings.overview.tips.minutes_read_from}
        />
      </div>

      <InfoCard
        titleColor="purple"
        title={0}
        tooltipBgColor="purple"
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.reading_sessions}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.reading_sessions}
      />

      <InfoCard
        titleColor="purple"
        title={0}
        tooltipBgColor="purple"
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.books_completed}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.books_completed}
      />

      <InfoCard
        titleColor="purple"
        title={"0%"}
        tooltipBgColor="purple"
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.students_are_reading}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.students_are_reading}
      />
    </div>

    <div className={styles.readingUnpaidWrapper}>
      <Divider className={styles.divider} />

      <div className={styles.flexWrapper}>
        <div className={styles.readingWrapper}>
          <div className={styles.title}>
            {strings.overview.unpaid_widget.title_reading}
          </div>

          <div className={styles.description}>
            {strings.overview.unpaid_widget.description_reading1}
          </div>
        </div>

        <a
          href={strings.overview.unpaid_widget.how_it_works_Link}
          target="_blank"
          rel="noreferrer"
        >
          <Button border borderColor="purple" textColor="purple">
            {strings.overview.unpaid_widget.how_it_works}
          </Button>
        </a>
      </div>
    </div>
  </>
));

export default ReadingUnfullWidget;
