import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import {
  AvatarWidget,
  Button,
  ComboBox,
  Input,
  Loader,
} from "../../../components/common";
import StudentStore from "../../../store/TeamsStores/StudentStore";
import { ReactComponent as LeftArrowIcon } from "../../../../images/icons/arrows/arrow-left.svg";
import { ReactComponent as RedirectIcon } from "../../../../images/icons/redirect.svg";
import { ReactComponent as RedirectToLoginIcon } from "../../../../images/icons/students/redirect.svg";
import styles from "./StudentEditForm.module.scss";
import strings from "../../../../i18n/strings";
import TeamStore from "../../../store/TeamsStores/TeamStore";
import { observer } from "mobx-react-lite";
import DialogsStore, { Dialogs } from "../../../store/DialogsStore";
import { formatCurrency } from "../../../utils/common";
import EventStore from "../../../store/EventStore";

const StudentEditForm = observer(() => {
  const params: { id?: string } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (params.id) {
      StudentStore.fetchStudentInfo(params.id);
    }
  }, [params.id]);

  const onBack = () => history.goBack();

  const onSubmit = async () => {
    await StudentStore.onSave();
    onBack();
  };

  const loginIcon = StudentStore.loadingLoginBtn ? "" : <RedirectToLoginIcon />;
  const firstName = StudentStore.formProfileData?.firstName;
  const displayName = StudentStore.formProfileData?.displayName;
  const firstAndlastName = `${StudentStore.formProfileData?.firstName} ${StudentStore.formProfileData?.lastName}`;
  const title = `${firstAndlastName} (${displayName})`;
  const currency = formatCurrency(EventStore.event?.currency);

  return (
    <div className="screenWrapper">
      <div className="content">
        <div className="contentWrapper">
          <>
            {StudentStore.loading ? (
              <Loader />
            ) : (
              <div className={styles.wrapper}>
                <Button componentType="iconButton" onClick={onBack}>
                  <LeftArrowIcon fill={styles.blue} />
                </Button>

                <div className={styles.bodyWrapper}>
                  <div className={styles.headerWrapper}>
                    <div className={styles.titleWrapper}>
                      <span className={styles.title}>{title}</span>

                      <span className={styles.description}>
                        {StudentStore.data?.user.email}
                      </span>
                    </div>

                    <Button
                      className={styles.loginBtnWrapper}
                      border
                      width={250}
                      startIcon={loginIcon}
                      loading={StudentStore.loadingLoginBtn}
                      onClick={StudentStore.onLoginByStudentId}
                    >
                      {strings.formatString(
                        strings.students.edit_form.login_btn,
                        firstName || "student"
                      )}
                    </Button>
                  </div>

                  <div className={styles.avatarWidgetWrapper}>
                    <AvatarWidget
                      currentUrlAvatar={StudentStore.formProfileData?.avatar}
                      isOpenDialog={DialogsStore.isOpenAvatarSelectionDialog()}
                      onOpenDialog={() =>
                        DialogsStore.setActiveDialog(Dialogs.AVATAR_SELECTION)
                      }
                      onCloseDialog={DialogsStore.onClose}
                      loading={false}
                      onSave={(url) =>
                        StudentStore.handleChangeProfileStudentData(
                          url,
                          "avatar"
                        )
                      }
                      role={StudentStore.data?.user.role}
                    />

                    <div className={styles.description}>
                      {strings.students.edit_form.avatar_description}
                      &nbsp;
                      <a
                        href={StudentStore.data?.shareLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {strings.students.edit_form.donation_page}
                        <RedirectIcon fill={styles.redirectSvgColor} />
                      </a>
                    </div>
                  </div>

                  <div className={styles.contentWrapper}>
                    <div className={styles.section}>
                      <span className={styles.contentSubTitle}>
                        {strings.students.edit_form.student_profile}:
                      </span>

                      <div className={styles.controlsWrapper}>
                        <ComboBox
                          width="100%"
                          label={strings.students.filters.team}
                          options={TeamStore.TeamOptionsReq}
                          disableClearable
                          value={StudentStore.formProfileData?.teamId}
                          onChange={(value) =>
                            StudentStore.handleChangeProfileStudentData(
                              value,
                              "teamId"
                            )
                          }
                        />
                      </div>

                      <div className={styles.controlsWrapper}>
                        <Input
                          className={styles.control}
                          width={380}
                          label={strings.students.edit_form.first_and_last_name}
                          onChange={(value) =>
                            StudentStore.handleChangeProfileStudentData(
                              value,
                              "firstName"
                            )
                          }
                          value={StudentStore.firstAndLastName}
                        />

                        <Input
                          className={styles.control}
                          width={330}
                          value={StudentStore.formProfileData?.displayName}
                          label={strings.students.edit_form.display_name}
                          onChange={(value) =>
                            StudentStore.handleChangeProfileStudentData(
                              value,
                              "displayName"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className={styles.section}>
                      <span className={styles.contentSubTitle}>
                        {strings.students.edit_form.personal_goals}:
                      </span>

                      <div className={styles.controlsWrapper}>
                        <Input
                          className={styles.control}
                          width={235}
                          value={
                            StudentStore.formGoalsData?.fundraisingPerMember
                          }
                          placeholder={
                            StudentStore.data?.team.goals.fundraisingPerMember
                          }
                          label={strings.students.edit_form.fundraising}
                          onChange={(val) =>
                            StudentStore.handleChangeGoals(
                              val,
                              "fundraisingPerMember"
                            )
                          }
                          endAdornment={currency}
                        />

                        <Input
                          className={styles.control}
                          width={235}
                          value={
                            StudentStore.formGoalsData?.readingMinsPerMember
                          }
                          placeholder={
                            StudentStore.data?.team.goals.readingMinsPerMember
                          }
                          label={strings.students.edit_form.reading_minutes}
                          onChange={(val) =>
                            StudentStore.handleChangeGoals(
                              val,
                              "readingMinsPerMember"
                            )
                          }
                          endAdornment="M"
                        />

                        <Input
                          className={styles.control}
                          width={235}
                          value={
                            StudentStore.formGoalsData?.readingBooksPerMember
                          }
                          label={strings.students.edit_form.reading_books}
                          placeholder={
                            StudentStore.data?.team.goals.readingBooksPerMember
                          }
                          onChange={(val) =>
                            StudentStore.handleChangeGoals(
                              val,
                              "readingBooksPerMember"
                            )
                          }
                          endAdornment="B"
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.footer}>
                    <Button
                      width={140}
                      type="primary"
                      onClick={onSubmit}
                      loading={StudentStore.saveLoading}
                      disabled={StudentStore.disabledStateSaveBtn}
                    >
                      {params.id
                        ? strings.buttons.save
                        : strings.teams.edit_form.create_team}
                    </Button>

                    <Button disabled={false} onClick={onBack}>
                      {strings.buttons.cancel}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
});

export default StudentEditForm;
