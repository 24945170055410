import React from 'react';
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import {
  Dialog as MuiDialog,
  DialogContent,
} from "@material-ui/core";
import {DialogTitle} from './components/DialogTitle';

import scss from './Dialog.module.scss';


interface IProps extends WithStyles<typeof styles> {
  isOpen: boolean;
  dialogTitle?: JSX.Element | string;
  dialogContent?: JSX.Element | string;
  showCloseIcon?: boolean;
  onClose?: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
  });

export const Dialog = withStyles(styles)(({
  isOpen,
  dialogTitle,
  dialogContent,
  classes,
  showCloseIcon,
  onClose
}: IProps) => {

  return (
    <MuiDialog className={classes.root} onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <div className={scss.wrapper}>
        {dialogTitle && (
          <DialogTitle id="customized-dialog-title" onClose={onClose} showCloseIcon={showCloseIcon}>
            {dialogTitle}
          </DialogTitle>
        )}

        {dialogContent && (
          <DialogContent>
            {dialogContent}
          </DialogContent>
        )}
      </div>
    </MuiDialog>
  )
})
