import React, { useState } from "react";
import strings from "../../../../../i18n/strings";
import { Input } from "../../../../components/common";

interface IProps {
  item: string;
  index: number;
  handleEditForm: (value: string, index: number) => void; 
}

const ObjectiveItem = ({ item, index, handleEditForm }: IProps) => {
  const [value, setValue] = useState<string>(item);

  const onChange = (value: string) => {
    setValue(value);
    handleEditForm(value, index);
  }


  return (
    <Input
      width={650}
      label={strings.fundraiser_details.objective}
      value={value}
      onChange={onChange}
    />
  );
};

export default ObjectiveItem;
