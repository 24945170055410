import React from 'react';
import {MenuItem as MuiMenuItem, withStyles} from '@material-ui/core';

interface IProps {
  divider?: boolean;
  value?: string;
  children: JSX.Element | string;
  onClick?: (value?: any) => void;
}

const StyledMuiMenuItem = withStyles({
  root: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: '14px',
    padding: '10px 20px 10px 15px',
    color: 'black',
  }
})(MuiMenuItem)

export const MenuItem = (props: IProps) => {
  const {children, value, divider, onClick} = props;

  return (
    <StyledMuiMenuItem value={value} onClick={onClick} divider={divider}>
      {children}
    </StyledMuiMenuItem>
  )
}