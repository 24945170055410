import React from 'react';

import {ReactComponent as ChevronRightIcon} from '../../../../../images/icons/chevron/chevron-right.svg';
import {Button} from '../..';
import styles from '../RotatableForm.module.scss';

interface IProps {
  isEdit: boolean
}

export const RotatableChevron = React.memo((props: IProps) => (
  <Button width={40} className={props.isEdit ? styles.chevronButtonOpen : styles.chevronButtonClose} componentType='iconButton'>
    <ChevronRightIcon />
  </Button>
));
