import React, { useEffect, useState } from "react";
import { Avatar } from "..";
import { ReactComponent as EditFilledPenIcon } from "../../../../images/icons/editFilledPen.svg";
import AvatarsStore from "../../../store/AvatarsStore";
import AvatarDialog from "./avatarDialog/AvatarDialog";
import styles from "./AvatarWidget.module.scss";

interface IProps {
  currentUrlAvatar?: string;
  loading: boolean;
  isOpenDialog: boolean;
  role?: string;
  onSave: (url: string) => void;
  onCloseDialog: () => void;
  onOpenDialog: () => void;
}

export const AvatarWidget = ({
  currentUrlAvatar,
  loading,
  isOpenDialog,
  role,
  onOpenDialog,
  onCloseDialog,
  onSave,
}: IProps) => {
  useEffect(() => {
    AvatarsStore.fetchItems(role);
  }, [role]);

  const [hoveredEdit, setHoveredEdit] = useState<boolean>(false);

  const changeHoveredState = (value: boolean) => setHoveredEdit(value);

  return (
    <>
      <div className={styles.avatarWrapper} onClick={onOpenDialog}>
        <Avatar url={currentUrlAvatar} hoveredEdit={hoveredEdit} />

        <div
          className={styles.editIconWrapper}
          onMouseEnter={() => changeHoveredState(true)}
          onMouseLeave={() => changeHoveredState(false)}
        >
          <EditFilledPenIcon fill={styles.fillEditIcon} />
        </div>
        {/* <Button width={45} componentType="iconButton" loading={loading} >
          <EditFilledPenIcon fill={styles.fillEditIcon} />
        </Button> */}
      </div>

      <AvatarDialog
        role={role}
        isOpen={isOpenDialog}
        currentAvatar={currentUrlAvatar!}
        onSave={onSave}
        onClose={onCloseDialog}
      />
    </>
  );
};
