import React from "react";
import Radio from "@material-ui/core/Radio";
import { RadioGroup as MuiRadioGroup, withStyles } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import styles from "./RadioGroup.module.scss";

interface IProps {
  className?: string;
  label?: string;
  value?: string;
  defaultValue: string;
  options: { label: string; value: string }[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGroup = (props: IProps) => {
  const { label, value, options, defaultValue, className, onChange } = props;

  const StyledRadio = withStyles({
    root: {
      color: styles.colorMain,

      "&$checked": {
        color: styles.colorMain,
      },
    },
    checked: {},
  })(Radio);

  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <MuiRadioGroup
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        className={className}
      >
        {options.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            label={item.label}
            control={<StyledRadio />}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};
