import { observer } from "mobx-react-lite";
import strings from "../../../../i18n/strings";
import { Button, ComboBox, Input, Select } from "../../../components/common";
import { ReactComponent as ArrowRightIcon } from "../../../../images/icons/arrows/arrow-right.svg";

import styles from "./SchoolInfo.module.scss";
import { useHistory } from "react-router";
import { Path } from "../../../consts/router";
import { useCallback, useEffect } from "react";
import SignUpStore from "../../../store/Registration/SignUpStore";
import { countries, OrganizationTypes } from "../../../consts/SignUp";
import SchoolInfoFormStore from "../../../store/Registration/SchoolInfoFormStore";
import EventStore from "../../../store/EventStore";

const SchoolInfoForm = observer(() => {
  const history = useHistory();

  const onRouteNextStep = useCallback(() => {
    history.push(Path.signUpFundraiserInfo);
    SchoolInfoFormStore.onNextStep();
  }, [history]);

  const listener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onRouteNextStep();
      }
    },
    [onRouteNextStep]
  );

  useEffect(() => {
    SignUpStore.setSignUpStep(1);

    window.addEventListener("keypress", listener);

    return () => {
      window.removeEventListener("keypress", listener);
    };
  }, [listener]);


  const schoolName =
    SchoolInfoFormStore.schoolName || EventStore.event?.schoolName || "";
  const country =
    SchoolInfoFormStore.country || EventStore.event?.country || "";
  const zipCode = SchoolInfoFormStore.zipCode || EventStore.event?.zip || "";

  const isValidForm = SchoolInfoFormStore.isValidForm();

  return (
    <div className={styles.formWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>
          {strings.RegistrationForms.schoolInfo.title}
        </div>

        <div className={styles.descriptionWrpper}>
          {strings.RegistrationForms.schoolInfo.description}
        </div>
      </div>

      <div className={styles.bodyWrapper}>
        <Select
          className={styles.control}
          value={SchoolInfoFormStore.schoolType}
          label={strings.RegistrationForms.schoolInfo.your_org_type}
          options={OrganizationTypes}
          onChange={(e) =>
            SchoolInfoFormStore.handleChangeSchoolType(e.target.value)
          }
        />

        <Input
          className={styles.control}
          value={schoolName}
          onChange={SchoolInfoFormStore.handleChangeSchoolName}
          label={strings.RegistrationForms.schoolInfo.your_school_name}
        />

        <div className={styles.flexWrapper}>
          <ComboBox
            width={225}
            disableClearable
            label={strings.RegistrationForms.schoolInfo.country}
            options={countries}
            value={country}
            onChange={SchoolInfoFormStore.handleChangeCountry}
          />

          <Input
            className={styles.inputZipCode}
            width={225}
            value={zipCode}
            onChange={SchoolInfoFormStore.handleChangeZipCode}
            label={strings.RegistrationForms.schoolInfo.zip_code}
          />
        </div>
      </div>

      <div className={styles.footerWrapper}>
        {/*<div onClick={onRouteBack} className={styles.back}>*/}
        {/*  <ArrowLeftIcon fill={styles.blue} />*/}
        {/*  <span>{strings.buttons.back}</span>*/}
        {/*</div>*/}

        <Button
          width={170}
          type="primary"
          endIcon={<ArrowRightIcon fill="white" />}
          onClick={onRouteNextStep}
          disabled={!isValidForm}
        >
          {strings.buttons.continue}
        </Button>
      </div>
    </div>
  );
});

export default SchoolInfoForm;
