import { action, computed, makeAutoObservable, observable } from "mobx";
import { apiClient } from "../../api/api";
import EventStore from "../EventStore";
import { Event } from "../../models/Event";
import moment from "moment";
import AppStore from "../AppStore";
import TeamMembersStore from "../TeamsStores/TeamMembersStore";
import SnackBarStore from "../SnackBarStore";
import strings from "../../../i18n/strings";
import { transformNumberValue } from "../../utils/common";

class FundraiserDetailsStore {
  @observable isEditMode: boolean = false;

  @observable public isLoading?: boolean;

  @observable public photoUrl?: string;

  @observable public videoUrl?: string;

  @observable public isLoadingUploadPhoto?: boolean;

  @observable public isLoadingDeletePhoto?: boolean;

  @observable public isLoadingUploadVideo?: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public onSwitchDeteilsEditMode = () => {
    this.photoUrl = EventStore.event?.photo || "";
    this.videoUrl = EventStore.event?.video || "";
    this.isEditMode = !this.isEditMode;
  };

  @action
  public onCloseDeteilsEditMode = () => this.isEditMode = false;

  @computed
  public uploadPhoto = async (files: FileList | null) => {
    let maxFileSize = 0.5 * 1024 * 1024 * 1024;
    let formData = new FormData();

    if (files?.length) {
      const file = files[0];

      if (
        file.size <= maxFileSize &&
        ["image/png", "image/jpeg"].includes(file.type)
      ) {
        formData.append("file", file);
      }

      this.isLoadingUploadPhoto = true;

      const url: { photo: string } = await apiClient.uploadEventPhoto(
        EventStore.eventId,
        formData
      );

      this.isLoadingUploadPhoto = false;

      EventStore.fetchEventData();

      this.photoUrl = url.photo;
    }
  };

  @computed
  public onUploadVideo = async (videoUrl?: string) => {
    try {
      this.isLoadingUploadVideo = true;
      const eventDetailsFormData = new FormData();
      eventDetailsFormData.append("video", videoUrl || "");
      await apiClient.updateEventDetailsData(
        EventStore.eventId,
        eventDetailsFormData
      );

      EventStore.fetchEventData();

      this.videoUrl = videoUrl;
    } catch (e) {
      console.error("EventStore.onUploadVideo", e);
    } finally {
      this.isLoadingUploadVideo = false;
    }
  };

  @computed
  public deleteEventPhoto = async () => {
    this.isLoadingDeletePhoto = true;

    try {
      const eventDetailsFormData = new FormData();
      eventDetailsFormData.append("photo", "");
      await apiClient.updateEventDetailsData(
        EventStore.eventId,
        eventDetailsFormData
      );
      this.photoUrl = "";
      this.isLoadingDeletePhoto = false;
    } catch (e) {
      console.error("EventStore.updateEventDetailsData", e);
    }
  };

  @computed
  public avgPerStudent = () => {
    if (!TeamMembersStore.getStudentCount()) {
      return 0;
    }

    return (
      AppStore.currency +
      transformNumberValue(
        Math.round(
          (EventStore.event?.raisedSum || 0) /
            TeamMembersStore.getStudentCount()
        )
      )
    );
  };

  @computed
  public avgDonation = () => {
    return (
      AppStore.currency +
      transformNumberValue(
        Math.round(
          EventStore.event?.raisedSum! / EventStore.event?.donationsCount!
        )
      )
    );
  };

  @computed
  public updateEventData = async (data?: Event) => {
    if (data) {
      //save event data info with anither api each value (need refactor later)
      this.isLoading = true;

      try {
        try {
          const eventDetailsFormData = new FormData();
          eventDetailsFormData.append("summary", data.summary);
          eventDetailsFormData.append("video", this.videoUrl || "");
          eventDetailsFormData.append("photo", this.photoUrl || "");

          if (data.objectives?.filter((item) => !!item).length) {
            data.objectives.forEach((o) => {
              if (o) eventDetailsFormData.append("objectives", o);
            });
          } else {
            eventDetailsFormData.append("objectives", [].toString());
          }

          const response = await apiClient.updateEventDetailsData(
            EventStore.eventId,
            eventDetailsFormData
          );

          if (!response) {
            return "error";
          }
        } catch (e) {
          console.error("EventStore.updateEventDetailsData", e);
        }

        try {
          const eventFormData = new FormData();

          eventFormData.append("name", data.name);
          eventFormData.append(
            "endDate",
            moment(data.end).format("MM/DD/yyyy")
          );

          const response = await apiClient.updateEventData(
            EventStore.eventId,
            eventFormData
          );

          if (!response) {
            return "error";
          }
        } catch (e) {
          console.error("EventStore.updateEventData", e);
        }

        try {
          const locationFormData = new FormData();

          if (data.state) locationFormData.append("state", data.state);
          locationFormData.append("schoolName", data.schoolName);
          if (data.city) locationFormData.append("city", data.city);
          locationFormData.append("zip", data.zip);

          const response = await apiClient.updateFundraiserLocation(
            data.locationId,
            locationFormData
          );

          if (!response) {
            return "error";
          }
        } catch (e) {
          console.error("EventStore.updateFundraiserLocation", e);
        }

        SnackBarStore.setSnackbar(
          strings.fundraiser_details.success_message,
          "success"
        );
      } catch (e) {
        console.error("EventStore.updateFundraiserLocation", e);
      } finally {
        this.isLoading = false;
      }

      await EventStore.fetchEventData();
      return "success";
    }
  };

  @action.bound
  public handleChangeVideoUrl = (url: string) => {
    this.videoUrl = url;
  };
}

export default new FundraiserDetailsStore();
