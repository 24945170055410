import { observer } from "mobx-react-lite";
import React from "react";
import strings from "../../../../i18n/strings";
import { InfoCard } from "../../../components/common";
import AppStore from "../../../store/AppStore";
import EventStore from "../../../store/EventStore";

import { ReactComponent as RedirectIcon } from "../../../../images/icons/redirect.svg";
import { ReactComponent as HelperIcon } from "../../../../images/icons/helper.svg";
import styles from "../Overview.module.scss";
import StripeStatus from "./StripeStatus";
import { transformNumberValue } from "../../../utils/common";

const FundraiserUnpaidWidget = observer(() => (
  <>
    <div className={styles.widgetsWrapper}>
      <div className={styles.progressWrapper}>
        <InfoCard
          title={
            <div>
              <span className={styles.raisedFrom}>{AppStore.currency + 0}</span>
              <span>
                {` / ${transformNumberValue(EventStore.event?.fundraising)}`}
              </span>
            </div>
          }
          description={
            <div className={styles.receivedDonation}>
              <span>{strings.overview.raised_from}</span>
              <HelperIcon fill={styles.tickStroke} />
            </div>
          }
          tooltip={strings.overview.raised_from}
        />
      </div>

      <InfoCard
        title={AppStore.currency + 0}
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.avg_donations_size}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.avg_donation_size}
      />

      <InfoCard
        title={AppStore.currency + 0}
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.avg_per_student}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.avg_per_student}
      />

      <InfoCard
        title={`0%`}
        description={
          <span className={styles.receivedDonation}>
            <span>{strings.overview.received_a_donation}</span>
            <HelperIcon fill={styles.tickStroke} />
          </span>
        }
        tooltip={strings.overview.tips.received_a_donation}
      />
    </div>

    <div className={styles.descriptionUnpaidWrapper}>
      <div className={styles.title}>{strings.overview.unpaid_widget.title}</div>

      <div className={styles.description}>
        <div className={styles.flexWrapper}>
          <span>{strings.overview.unpaid_widget.description1}</span>&nbsp;
          <span className={styles.link}>
            <a
              href={EventStore.event?.shareLink}
              target="_blank"
              rel="noreferrer"
            >
              {strings.overview.unpaid_widget.donation_page}
              <RedirectIcon fill={styles.blue} />
            </a>
          </span>
        </div>
      </div>
    </div>

    <StripeStatus />
  </>
));

export default FundraiserUnpaidWidget;
