import React from 'react';
import {createStyles, Snackbar as MuiSnackBar, Theme, withStyles} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

interface IProps {
  isOpen: boolean;
  alertText?: string;
  autoHideDuration?: number;
  severity?: 'error' | 'info' | 'success' | 'warning';

  onClose?: () => void;
}

export const SnackBar = (props: IProps) => {
  const {
    isOpen,
    severity,
    alertText,
    autoHideDuration = 3000,
    onClose
  } = props;

  // const getBgColor = () => {
  //   switch(true){
  //     case severity === 'success': return styles.successBg;
  //     case severity === 'error': return styles.errorBg;

  //     default: return '';
  //   }
  // }
  //
  // TODO: так не красиво смотриться потом возможно нужно побольше на это время и кастомиировать

  const StyledAlert = withStyles((theme: Theme) =>
    createStyles({
      root: {
        fontFamily: "Montserrat",
        fontSize: 14,
        // backgroundColor: getBgColor(),
      },
    }),
  )(Alert);

  return (
    <MuiSnackBar open={isOpen} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
      <StyledAlert severity={severity}>
        {alertText}
      </StyledAlert>
    </MuiSnackBar>
  )
}
