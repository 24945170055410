import { action, computed, makeAutoObservable, observable } from "mobx";
import moment from "moment";
import { apiClient } from "../../api/api";
import { AFFILIATE_PARTNER_ID, EVENT_ID, signUpData } from "../../consts/localStorage";
import { setItemToSignUpLS } from "../../utils/common";

class FundraiserInfoFormStore {
  @observable public name?: string;

  @observable public goal?: string;

  @observable public duration?: string;

  @observable public loading?: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound
  handleChangeName = (value: string) => {
    this.name = value;
  };

  @action.bound
  handleChangeGoal = (value: string) => {
    this.goal = Number(value.replace(/\D/g,'')).toLocaleString();
  };

  @action.bound
  handleChangeDuration = (value: string) => {
    this.duration = value;
  };

  @computed
  onSaveToLS = () => {
    setItemToSignUpLS({
      duration: this.duration,
      fundraising: this.goal,
      name: this.name,
    });
  };

  @computed
  isValidForm = () =>
      !!this.name && !!this.duration && Number(this.goal?.replace(/\D/g,'')) > 10;

  @action.bound
  onSaveStep = async () => {
    try {
      this.loading = true;
      this.onSaveToLS();
      const dataLS = JSON.parse(localStorage.getItem(signUpData) || "{}");

      const country = dataLS.country;
      const endDate = moment(new Date())
        .add(dataLS.duration || 0, "days")
        .format("yyyy-MM-DD");

      const formData = new FormData();
      formData.append("schoolName", dataLS.schoolName);
      formData.append("country", country!);
      formData.append("zip", dataLS.zip);
      formData.append("type", dataLS.type);
      formData.append("fundraising", dataLS.fundraising?.replace(/\D/g,''));
      formData.append("businessModel", dataLS.businessModel);
      formData.append("name", dataLS.name);
      let affiliatePartnerId = localStorage.getItem(AFFILIATE_PARTNER_ID);
      if (affiliatePartnerId)
        formData.append("affiliatePartnerId", affiliatePartnerId);
      formData.append("endDate", endDate);

      const data = await apiClient.createEvent(formData);
      if(data) {
        localStorage.removeItem(signUpData);
        localStorage.removeItem(AFFILIATE_PARTNER_ID);
      }


      if (data) {
        window.localStorage.setItem(EVENT_ID, data.id);
      }

      return data;
    } catch (e) {
      console.error("FundraiserInfoFormStore.onSaveStep", e);
    } finally {
      this.loading = false;
    }
  };
}

export default new FundraiserInfoFormStore();
