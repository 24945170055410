import React from "react";
import { Checkbox as MuiCheckbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import styles from "./CheckBox.module.scss";

interface IProps {
  checked?: boolean;
  text?: string;
  disabled?: boolean;
  classNameText?: string;
  onChange?: () => void;
}

const StyledCheckbox = withStyles({
  root: {
    color: styles.colorMain,

    "&$checked": {
      color: styles.colorMain,
    },
    "&$disabled": {
      color: styles.colorDisabled,
    },
  },
  disabled: {},
  checked: {},
})(MuiCheckbox);

export const Checkbox = ({
  checked,
  disabled,
  text,
  classNameText,
  onChange,
}: IProps) => {
  return (
    <div className={styles.checkboxWrapper}>
      <StyledCheckbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      {text && (
        <span
          className={`${
            disabled ? styles.disabledLabel : styles.label
          } ${classNameText}`}
          onClick={() => {
            if (onChange && !disabled) onChange();
          }}
        >
          {text}
        </span>
      )}
    </div>
  );
};
