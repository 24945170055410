import { action, computed, makeAutoObservable, observable } from "mobx";
import { apiClient } from "../api/api";
import { IUserBodyResponse } from "../api/apiResponseTypes";
import EventStore from "./EventStore";
import { User } from "../models/User";
import { EVENT_ID } from "../consts/localStorage";
import AccountSettingsStore from "./AccountSettingsStore";

class UserStore {
  @observable public user?: User;
  @observable public loading: boolean = false;
  @observable
  public item?: IUserBodyResponse;
  @observable public titleName?: string;
  @observable public email?: string;
  @observable public displayName?: string;

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  public setTitleName = (response: IUserBodyResponse) => {
    if (response.firstName || response.lastName) {
      this.titleName = `${response.firstName || ""} ${response.lastName || ""}`;
    } else {
      this.titleName = response?.email;
    }
  };

  @action.bound
  public fetchData = async () => {
    let response: IUserBodyResponse = {};
    try {
      this.loading = true;
      response = await apiClient.getUser();
      this.setTitleName(response);
      this.email = response.email;
      this.displayName = response?.displayName || "";
      if (!localStorage.getItem(EVENT_ID) && response.events?.length) {
        EventStore.setEventId(response?.events[0].id);
      } else {
        EventStore.setEventId(Number(localStorage.getItem(EVENT_ID)));
      }

      this.user = new User(response);
      this.item = response;
      AccountSettingsStore.onMountData();
      return response;
    } catch (e) {
      console.error("UserStore.fetchData", e);
      return null;
    } finally {
      this.loading = false;
    }
  };
}

export default new UserStore();
