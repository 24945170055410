import React from "react";
import strings from "../../../../i18n/strings";
import { Button } from "../../../components/common";
import { ReactComponent as CloseIcon } from "../../../../images/icons/fundraiser/closeFundraiser.svg";

import styles from "./FundraiserClose.module.scss";

interface IProps {
  onCloseClick: () => void;
}

const FundraiserClose = ({ onCloseClick }: IProps) => (
  <div className={styles.wrapper}>
    <div className={styles.titleWrapper}>
      <div className="contentTitle">{strings.fundraiser_close.title}</div>
      <div className={styles.description}>
        {strings.fundraiser_close.description}
      </div>
    </div>
    
    <Button
      border
      borderColor="red"
      textColor="red"
      startIcon={<CloseIcon />}
      onClick={onCloseClick}
    >
      {strings.fundraiser_close.close_fundraiser}
    </Button>
  </div>
);

export default FundraiserClose;
