import { observer } from "mobx-react-lite";
import strings from "../../../../i18n/strings";
import {
  Button,
  Input,
  Checkbox,
  AvatarWidget,
} from "../../../components/common";
import { ReactComponent as ArrowRightIcon } from "../../../../images/icons/arrows/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../images/icons/arrows/arrow-left.svg";

import styles from "./ProfileInfoForm.module.scss";
import { useHistory } from "react-router";
import { Path } from "../../../consts/router";
import { useEffect, useCallback } from "react";
import SignUpStore from "../../../store/Registration/SignUpStore";
import ProfileInfoFormStore from "../../../store/Registration/ProfileInfoFormStore";
import AvatarsStore from "../../../store/AvatarsStore";
import UserStore from "../../../store/UserStore";
import { ACCESS_TOKEN } from "../../../consts/localStorage";

const ProfileInfoForm = observer(() => {
  const history = useHistory();

  const onRouteNextStep = useCallback(async () => {
    const data = await ProfileInfoFormStore.onSave();

    if (!ProfileInfoFormStore.avatar || !data) {
      return;
    }

    history.push(Path.signUpFinish);
  }, [history]);

  const listener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onRouteNextStep();
      }
    },
    [onRouteNextStep]
  );

  useEffect(() => {
    if (
      window.localStorage.getItem(ACCESS_TOKEN) &&
      UserStore.item &&
      UserStore.item.firstName &&
      UserStore.item.lastName
    ) {
      ProfileInfoFormStore.handleChangeAvatar(UserStore.item.avatar || "");
      ProfileInfoFormStore.handleChangeDisplayName(UserStore.item.displayName || "");
      ProfileInfoFormStore.handleChangeFirstName(UserStore.item.firstName);
      ProfileInfoFormStore.handleChangeLastName(UserStore.item.lastName);
      onRouteNextStep();
    }
  });

  useEffect(() => {
    AvatarsStore.fetchItems();
    SignUpStore.setSignUpStep(3);

    window.addEventListener("keypress", listener);

    return () => {
      window.removeEventListener("keypress", listener);
    };
  }, [listener]);

  const onRouteBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>
          {strings.RegistrationForms.profileInfo.title}
        </div>

        <div className={styles.descriptionWrpper}>
          <AvatarWidget
            currentUrlAvatar={
              ProfileInfoFormStore.avatar || UserStore.user?.avatar
            }
            loading={false}
            onOpenDialog={ProfileInfoFormStore.handleOpenAvatarsDialog}
            isOpenDialog={ProfileInfoFormStore.isOpenAvatarChangeDialog}
            onSave={ProfileInfoFormStore.handleChangeAvatar}
            onCloseDialog={ProfileInfoFormStore.handleCloseAvatarsDialog}
          />

          <div className={styles.description}>
            {strings.RegistrationForms.profileInfo.description}
          </div>
        </div>
      </div>

      <div className={styles.bodyWrapper}>
        <Input
          label={strings.RegistrationForms.profileInfo.display_name}
          value={ProfileInfoFormStore.displayName}
          onChange={ProfileInfoFormStore.handleChangeDisplayName}
        />

        <div className={styles.flexWrapper}>
          <Input
            width={225}
            label={strings.RegistrationForms.profileInfo.first_name}
            value={ProfileInfoFormStore.firstName}
            onChange={ProfileInfoFormStore.handleChangeFirstName}
          />

          <Input
            width={225}
            label={strings.RegistrationForms.profileInfo.last_name}
            value={ProfileInfoFormStore.lastName}
            onChange={ProfileInfoFormStore.handleChangeLastName}
          />
        </div>

        <div className={styles.checkBoxWrapper}>
          <Checkbox
            checked={ProfileInfoFormStore.checkedPrivacyPolicy}
            onChange={ProfileInfoFormStore.handleChangeCheckedPrivacyPolicy}
          />

          <div className={styles.textValue}>
            {strings.formatString(
              strings.RegistrationForms.profileInfo.confirm_policy,
              <a
                href={strings.links.terms_of_use}
                target="_blank"
                rel="noreferrer"
              >
                {strings.RegistrationForms.signUp.terms_of_service}
              </a>,
              <a
                href={strings.links.privacy_policy}
                target="_blank"
                rel="noreferrer"
              >
                {strings.RegistrationForms.signUp.privacy_police}
              </a>
            )}
          </div>
        </div>
      </div>

      <div className={styles.footerWrapper}>
        <div onClick={onRouteBack} className={styles.back}>
          <ArrowLeftIcon fill={styles.blue} />
          <span>{strings.buttons.back}</span>
        </div>

        <Button
          width={170}
          type="primary"
          loading={ProfileInfoFormStore.loading}
          disabled={ProfileInfoFormStore.validForm()}
          endIcon={
            ProfileInfoFormStore.loading ? (
              <></>
            ) : (
              <ArrowRightIcon fill="white" />
            )
          }
          onClick={onRouteNextStep}
        >
          {strings.buttons.continue}
        </Button>
      </div>
    </div>
  );
});

export default ProfileInfoForm;
