import { action, computed, makeAutoObservable, observable } from "mobx";
import strings from "../../../i18n/strings";
import { apiClient } from "../../api/api";
import { Path } from "../../consts/router";
import { setItemToSignUpLS } from "../../utils/common";
import SnackBarStore from "../SnackBarStore";

export enum PricingModelEnum {
  FEE = 'fee',
  TIPS = 'tips',
  FLAT = 'flat'
}

class SignUpStore {
  @observable public signUpStep: number = 0;

  @observable public loading?: boolean;

  @observable public email: string = '';

  @observable public password: string = '';

  @observable public repeatPassword: string = '';

  @observable public showForm: 'signUp' | 'confirmEmail' = 'signUp';

  @observable public pricingModeValue: PricingModelEnum = PricingModelEnum.FEE;

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  public showStepper = (location: string) => {
    return (
      location === Path.signUp ||
      // location === Path.signUpPricingModel ||
      location === Path.signUpFundraiserInfo ||
      location === Path.signUpSchoolInfo ||
      location === Path.signUpProfileInfo ||
      location === Path.signUpFinish
    );
  };

  @action.bound
  public setSignUpStep = (step: number) => (this.signUpStep = step);

  @action.bound
  public changeEmail = (value: string) => this.email = value;

  @action.bound
  public changePassword = (value: string) => this.password = value;

  @action.bound
  public changeRepeatPassword = (value: string) => this.repeatPassword = value;

  @action.bound
  signUp = async () => {
    try {
      this.loading = true;

      const formData = new FormData();
      formData.append('email', this.email!);
      formData.append('password', this.password!);
      formData.append('inviteCode', 'ea');

      if (this.password !== this.repeatPassword) {
        SnackBarStore.setSnackbar(strings.RegistrationForms.signUp.validation_message.passwords_do_not_match, 'error');

        return;
      }

      const data = await apiClient.signUp(formData);
      
      if (data.verificationId) {
        this.setShowForm('confirmEmail');
      }
    }
    catch (e) {
      console.error("SignInStore.login", e);
    }
    finally {
      this.loading = false;
    }
  }

  @action.bound
  setShowForm = (mode: 'signUp' | 'confirmEmail') => this.showForm = mode;

  @action.bound
  setPricingModeValue = (value: PricingModelEnum) => {
    this.pricingModeValue = value;
  }

  @action.bound
  onNextStep = () => {
    setItemToSignUpLS({
      businessModel: this.pricingModeValue
    });
  }
}

export default new SignUpStore();
