import React from 'react';
import {FormControl, MenuItem, InputLabel, Select as MuiSelect, withStyles, Theme, createStyles} from "@material-ui/core";
import InputBase from '@material-ui/core/InputBase';
import styles from './Select.module.scss';

export interface IOption {
  value: string | number,
  label: string,
  disabled?: boolean
}

interface IProps {
  className?: string;
  label?: string;
  width?: number | string;
  placeholder?: string;
  value?: string | number | null;
  options?: Array<IOption>;
  onChange?: (value: any) => void;
  border?: 'round' | 'squar';
  bgColor?: 'darkBlue' | 'white';
  fontColor?: "primary" | "white";
  size?: 'small' | 'medium';
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    input: {
      borderRadius: 4,
      height: '19px',
      paddingTop: '10px',
      paddingLeft: '15px',
      position: 'relative',
      border: `1px solid ${styles.borderColor}`,
      fontSize: 14,
      fontFamily: 'Montserrat',
      '&:focus': {
        borderRadius: 4,
        height: '18px',
        border: `2px solid ${styles.borderColorFocused}`,
      },
    },
  }),
)(InputBase);

const StyledInputLabel = withStyles({
  root: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: styles.labelTextColor,
    fontSize: '14px',
    backgroundColor: 'white',
    marginLeft: -5,
    padding: '0 8px',
  },
})(InputLabel);

export const Select = (
  {
    className,
    label,
    value,
    options,
    placeholder,
    size = 'small',
    width = '100%',
    onChange
  }: IProps) => {

  return (
    <FormControl className={className} variant='outlined' style={{width}} size={size} >
      <StyledInputLabel id="select-outlined-label">{label}</StyledInputLabel>
      <MuiSelect
        id="simple-select-outlined"
        labelId="select-outlined-label"
        label={label}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        input={<BootstrapInput />}
      >
        {options && options.map(option => <MenuItem key={option.value} value={option.value} >{option.label}</MenuItem>)}
      </MuiSelect>
    </FormControl>
  )
}
