import React from "react";
import { observer } from "mobx-react-lite";
import { ReactComponent as MailIcon } from "../../../images/announcement/mail.svg";
import strings from "../../../i18n/strings";
import styles from "./Announcement.module.scss";

const AnnouncementInfo = observer(() => (
  <div className={styles.infoWrapper}>
    <MailIcon />

    <div className={styles.description}>{strings.announcement.description}</div>
  </div>
));

export default AnnouncementInfo;
