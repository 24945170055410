import { action, computed, makeAutoObservable, observable } from "mobx";
import strings from "../../../i18n/strings";
import { apiClient } from "../../api/api";
import { ITeamsResponse } from "../../api/apiResponseTypes";
import { grades } from "../../consts/TeamMembers";
import { ITeamsFormData } from "../../interfaces/ITeams";
import DialogsStore from "../DialogsStore";
import SnackBarStore from "../SnackBarStore";
import TeamStore from "./TeamStore";

class TeamEditStore {
  @observable public loading: boolean = false;
  @observable public loadingData: boolean = false;

  @observable public data: ITeamsFormData = {};
  @observable public selectedIdTeam?: number | string;
  @observable public teamBySelectedId?: ITeamsResponse;

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  public selectIdTeam = (id: number | string) => {
    this.selectedIdTeam = id;
    this.teamBySelectedId = TeamStore.items.find(
      (team) => team.id.toString() === id.toString()
    );
  };

  @computed
  onPressEditTeam = async (idTeam?: number | string) => {
    idTeam ? await this.editTeam(idTeam) : await this.createNewTeam();
  };

  @action.bound
  public handleChangeEditData = (value: string, key: keyof ITeamsFormData) => {
     this.data[key] = value;

    if (key === "lastName" || key === "firstName") {
      this.data.ownerName = `${this.data.firstName} ${this.data.lastName || ''}`;
    }
  };

  @action.bound
  createNewTeam = async () => {
    this.loading = true;

    try {
      const formData = new FormData();

      Object.keys(this.data).forEach((key) =>
        //@ts-ignore
        formData.append(key, this.data[key])
      );

      if(!this.data.ownerName) formData.append('ownerName', '');

      await apiClient.createNewTeam(formData).then((response) => {
        if (!response) {
          throw Error;
        }
      });

      SnackBarStore.setSnackbar(
        strings.teams.alerts.team_create_success,
        "success"
      );
    } catch (e) {
      console.error("TeamEditStore.createNewTeam", e);
    } finally {
      this.loading = false;
    }
  };

  @action.bound
  deleteTeamById = async () => {
    this.loading = true;

    try {
      await apiClient.deleteTeamById(this.selectedIdTeam!);
      await TeamStore.fetchItemsList();

      SnackBarStore.setSnackbar(
        strings.teams.alerts.team_delete_success,
        "success"
      );
    } catch (e) {
      console.error("TeamEditStore.deleteTeamById", e);
    } finally {
      this.loading = false;
      DialogsStore.onClose();
    }
  };

  @action.bound
  editTeam = async (idTeam: number | string) => {
    this.loading = true;

    try {
      const formData = new FormData();

      Object.keys(this.data).forEach((key) => {
          //@ts-ignore
          if (this.data[key] !== undefined)
            //@ts-ignore
            formData.append(key, this.data[key])
        }
      );

      if(!this.data.ownerEmail) formData.append('ownerEmail', '');

      await apiClient.editTeam(idTeam, formData).then((response) => {
        if (!response) {
          throw Error;
        }
      });

      SnackBarStore.setSnackbar(
        strings.teams.alerts.team_edit_success,
        "success"
      );
    } catch (e) {
      console.error("TeamEditStore.createNewTeam", e);
    } finally {
      this.loading = false;
    }
  };

  @action.bound
  getTeamById = async (idTeam: number | string) => {
    try {
      this.loadingData = true;
      const data = await apiClient.getTeamById(idTeam);

      this.data.grade = data.grade;
      this.data.gradeDisplay = grades.find(
        (val) => val.value === data.grade.toString()
      )?.label;
      this.data.ownerName = `${data.owner.firstName} ${data.owner.lastName}`;
      this.data.firstName = data.owner.firstName;
      this.data.lastName = data.owner.lastName;
      this.data.ownerEmail = data.owner.email;
      this.data.name = data.name;
      this.data.size = data.targetSize;
      this.data.fundraisingPerMember = data.goals.fundraisingPerMember;
      this.data.readingMinsPerMember = data.goals.readingMinsPerMember;
      this.data.readingBooksPerMember = data.goals.readingBooksPerMember;
      this.data.activeAt = data.owner.activeAt;
    } catch (e) {
      console.error("TeamEditStore.getTeamById", e);
    } finally {
      this.loadingData = false;
    }
  };

  @action.bound
  cleanData = () => (this.data = {});

  //нужный небольшой костыль
  @action.bound
  setDefaultData = () =>
    (this.data = {
      ownerName: " ",
      lastName: " ",
      firstName: " ",
      ownerEmail: " ",
      size: " ",
      name: " ",
      gradeDisplay: " ",
      grade: " ",
      fundraisingPerMember: " ",
      readingBooksPerMember: " ",
      readingMinsPerMember: " ",
    });
}

export default new TeamEditStore();
