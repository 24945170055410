import { Divider } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import strings from "../../../../i18n/strings";
import { Button, InfoCard } from "../../../components/common";
import AppStore from "../../../store/AppStore";
import { ReactComponent as HelperIcon } from "../../../../images/icons/helper.svg";
import { ReactComponent as StripeIcon } from "../../../../images/icons/stripe.svg";
import styles from "../Overview.module.scss";
import OverviewStore from "../../../store/OverviewStore";
import EventStore from "../../../store/EventStore";
import { transformNumberValue } from "../../../utils/common";

const FundraiserEmptyWidget = observer(() => (
  <>
    <div className={styles.widgetsWrapper}>
      <InfoCard
        title={
          <div>
            <span className={styles.raisedFromNonePaid}>
              {AppStore.currency + 0}
            </span>
            <span className={styles.raisedFromNonePaidGoal}>
              {` / ${transformNumberValue(EventStore.event?.fundraising)}`}
            </span>
          </div>
        }
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.raised_from}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.donationRaised}
      />
    </div>

    <div className={styles.emptyStripeWrapper}>
      <Divider className={styles.divider} />

      <div className={styles.stripeWrapper}>
        <div className={styles.gridWrapper}>
          <StripeIcon />

          <div className={styles.title}>
            {strings.overview.empty_widget.title}
          </div>

          <div className={styles.description}>
            {strings.overview.empty_widget.description1}
          </div>
        </div>

        <Button
          type="primary"
          width={210}
          onClick={OverviewStore.handleRedirectToStripe}
        >
          {strings.overview.empty_widget.connect_stripe}
        </Button>
      </div>
    </div>
  </>
));

export default FundraiserEmptyWidget;
