import { observer } from "mobx-react-lite";
import React from "react";
import { Dialog } from "../..";
import AvatarsStore from "../../../../store/AvatarsStore";

import AvatarDialogContent from "./AvatarDialogContent";
import AvatarDialogTitle from "./AvatarDialogTitle";

interface IProps {
  isOpen: boolean;
  currentAvatar: string;
  role?: string;
  onSave: (url: string) => void;
  onClose: () => void;
}

export const AvatarDialog = observer((props: IProps) => {
  const { isOpen, currentAvatar, role, onClose, onSave } = props;

  const onUpdateAvatars = () => {
    AvatarsStore.fetchItems(role);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      dialogTitle={
        <AvatarDialogTitle role={role} onUpdateAvatars={onUpdateAvatars} />
      }
      dialogContent={
        <AvatarDialogContent
          onSave={onSave}
          onClose={onClose}
          currentAvatar={currentAvatar}
        />
      }
    />
  );
});

export default AvatarDialog;
