import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { ReactComponent as LeftArrowIcon } from "../../../../images/icons/arrows/arrow-left.svg";
import { ReactComponent as HelperIcon } from "../../../../images/icons/helper.svg";
import styles from "./TeamEdit.module.scss";
import strings from "../../../../i18n/strings";
import {
  Button,
  Input,
  Loader,
  Select,
  Tooltip,
} from "../../../components/common";
import { useHistory, useParams } from "react-router";
import TeamEditStore from "../../../store/TeamsStores/TeamEditStore";
import { grades } from "../../../consts/TeamMembers";
import SnackBarStore from "../../../store/SnackBarStore";
import AppStore from "../../../store/AppStore";
import EventStore from "../../../store/EventStore";
import { formatCurrency } from "../../../utils/common";
import TeamResendInviteDialog from "../TeamsDialog/TeamResendInviteDialog";

const TeamEdit = observer(() => {
  const history = useHistory();
  const params: { id?: string } = useParams();

  useEffect(() => {
    if (params.id) {
      AppStore.setLayout();
      TeamEditStore.setDefaultData();
      TeamEditStore.selectIdTeam(params.id);
      TeamEditStore.getTeamById(params.id);
    } else {
      AppStore.setLayout();
    }

    return () => {
      TeamEditStore.cleanData();
    };
  }, [params.id]);

  const onBack = () => history.goBack();

  const onSave = async () => {
    await TeamEditStore.onPressEditTeam(params.id);

    if (SnackBarStore.snackBarSeverity === "success") {
      onBack();
    }
  };

  const isValid = !(
    TeamEditStore.data.name &&
    TeamEditStore.data.grade !== undefined &&
    TeamEditStore.data.size
  );

  const isEditMode = !!params.id;
  const teamName = TeamEditStore.data.name;
  const headerTooltip = isEditMode
    ? strings.teams.edit_form.title_edit_tooltip
    : strings.teams.edit_form.title_create_tooltip;

  const headerText = isEditMode
    ? teamName
    : strings.teams.edit_form.create_team;

  return (
    <div className="screenWrapper">
      <div className="content">
        <div className="contentWrapper">
          <>
            {TeamEditStore.loadingData ? (
              <Loader />
            ) : (
              <div className={styles.wrapper}>
                <Button componentType="iconButton" onClick={onBack}>
                  <LeftArrowIcon fill={styles.blue} />
                </Button>

                <div className={styles.bodyWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={styles.headerWrapper}>
                      <span className={styles.title}>{headerText}</span>

                      <Tooltip title={headerTooltip}>
                        <HelperIcon fill={styles.blue} />
                      </Tooltip>
                    </div>
                  </div>

                  <div className={styles.contentWrapper}>
                    <div className={styles.section}>
                      <Tooltip
                        title={
                          strings.teams.edit_form.team_name_grade_size_tooltip
                        }
                      >
                        <div className={styles.contentSubTitleWrapper}>
                          <span className={styles.contentSubTitle}>
                            {strings.teams.edit_form.team_name_grade_size}
                          </span>
                          <HelperIcon fill={styles.tickStroke} />
                        </div>
                      </Tooltip>

                      <div className={styles.controlsWrapper}>
                        <Input
                          width={320}
                          label={strings.teams.edit_form.team_name}
                          onChange={(value) =>
                            TeamEditStore.handleChangeEditData(value, "name")
                          }
                          value={TeamEditStore.data.name || ""}
                        />

                        <Select
                          width={180}
                          options={grades}
                          label={strings.teams.edit_form.team_grade}
                          value={TeamEditStore.data.grade}
                          onChange={(e) =>
                            TeamEditStore.handleChangeEditData(
                              e.target.value,
                              "grade"
                            )
                          }
                        />

                        <Input
                          width={200}
                          label={strings.teams.edit_form.of_students}
                          onChange={(value) =>
                            TeamEditStore.handleChangeEditData(value, "size")
                          }
                          value={TeamEditStore.data.size}
                        />
                      </div>
                    </div>

                    <div className={styles.section}>
                      <Tooltip
                        title={strings.teams.edit_form.team_goals_tooltip}
                      >
                        <div className={styles.contentSubTitleWrapper}>
                          <span className={styles.contentSubTitle}>
                            {strings.teams.edit_form.team_goals}
                          </span>
                          <HelperIcon fill={styles.tickStroke} />
                        </div>
                      </Tooltip>

                      <div className={styles.controlsWrapper}>
                        <Input
                          width={235}
                          showCustomPlaceholder
                          label={strings.teams.edit_form.student_goal}
                          placeholder={EventStore.event?.fundraisingPerMember}
                          onChange={(value) =>
                            TeamEditStore.handleChangeEditData(
                              value,
                              "fundraisingPerMember"
                            )
                          }
                          value={TeamEditStore.data.fundraisingPerMember}
                          endAdornment={formatCurrency(
                            EventStore.event?.currency
                          )}
                        />
                        <Input
                          width={235}
                          showCustomPlaceholder
                          label={strings.teams.edit_form.minutes_per_student}
                          placeholder={EventStore.event?.readingMinsPerMember}
                          onChange={(value) =>
                            TeamEditStore.handleChangeEditData(
                              value,
                              "readingMinsPerMember"
                            )
                          }
                          value={TeamEditStore.data.readingMinsPerMember}
                          endAdornment={"M"}
                        />
                        <Input
                          width={230}
                          showCustomPlaceholder
                          label={strings.teams.edit_form.books_per_student}
                          placeholder={EventStore.event?.readingBooksPerMember}
                          onChange={(value) =>
                            TeamEditStore.handleChangeEditData(
                              value,
                              "readingBooksPerMember"
                            )
                          }
                          value={TeamEditStore.data.readingBooksPerMember}
                          endAdornment={"B"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.footer}>
                    <Button
                      width={140}
                      type="primary"
                      onClick={onSave}
                      loading={TeamEditStore.loading}
                      disabled={isValid}
                    >
                      {params.id
                        ? strings.buttons.save
                        : strings.teams.edit_form.create_team}
                    </Button>

                    <Button disabled={TeamEditStore.loading} onClick={onBack}>
                      {strings.buttons.cancel}
                    </Button>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            )}

            <TeamResendInviteDialog />
          </>
        </div>
      </div>
    </div>
  );
});

export default TeamEdit;
