import React from 'react';
import strings from '../../../../i18n/strings';
import {ReactComponent as GroupIcon} from '../../../../images/icons/students/group.svg';

import styles from './InviteDialog.module.scss';

const InviteDialogTitle = () => (
  <div className={styles.titleWrapper}>
    <GroupIcon />

    <span className={styles.title}>{strings.students.invite_dialog.title}</span>
    
    <span className={styles.description}>{strings.students.invite_dialog.title_description}</span>
  </div>
)

export default InviteDialogTitle;
