import { observer } from "mobx-react-lite";
import React from "react";
import strings from "../../../../i18n/strings";
import { InfoCard, Progress } from "../../../components/common";
import AppStore from "../../../store/AppStore";
import EventStore from "../../../store/EventStore";
import OverviewStore from "../../../store/OverviewStore";
import TeamMembersStore from "../../../store/TeamsStores/TeamMembersStore";
import { ReactComponent as HelperIcon } from "../../../../images/icons/helper.svg";
import { FundraiserReportChart } from "../ReportCharts/FundraiserReportChart";

import styles from "../Overview.module.scss";
import StripeStatus from "./StripeStatus";
import FundraiserDetailsStore from "../../../store/FundraiserStores/FundraiserDetailsStore";
import { transformNumberValue } from "../../../utils/common";

const FundraiserPaidWidget = observer(() => (
  <>
    <div className={styles.widgetsWrapper}>
      <div className={styles.progressWrapper}>
        <Progress value={OverviewStore.getRaisedPercent()} width="90px" />

        <InfoCard
          title={
            <div>
              <span className={styles.raisedFrom}>
                {`${AppStore.currency}${transformNumberValue(
                  EventStore.event?.raisedSum,
                  3
                )}`}
              </span>
              <span>
                {` / ${transformNumberValue(EventStore.event?.fundraising)}`}
              </span>
            </div>
          }
          description={
            <div className={styles.receivedDonation}>
              {strings.overview.raised_from}
              <HelperIcon fill={styles.tickStroke} />
            </div>
          }
          tooltip={strings.formatString(
            strings.overview.tips.donationRaised,
            `${AppStore.currency + EventStore.event?.fundraising}`
          )}
        />
      </div>

      <InfoCard
        title={FundraiserDetailsStore.avgDonation()}
        description={
          <div className={styles.receivedDonation}>
            {strings.overview.avg_donations_size}
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.avg_donation_size}
      />

      <InfoCard
        title={FundraiserDetailsStore.avgPerStudent()}
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.avg_per_student}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.avg_per_student}
      />

      <InfoCard
        title={`${TeamMembersStore.getPercentDonationStudents().toFixed(0)}%`}
        description={
          <div className={styles.receivedDonation}>
            <span>{strings.overview.received_a_donation}</span>
            <HelperIcon fill={styles.tickStroke} />
          </div>
        }
        tooltip={strings.overview.tips.received_a_donation}
      />
    </div>

    <div className={styles.chartWrapper}>
      {OverviewStore.getPaymentChartData().length > 0 ? (
        <FundraiserReportChart data={OverviewStore.getPaymentChartData()} />
      ) : (
        <></>
      )}
    </div>

    <StripeStatus />
  </>
));

export default FundraiserPaidWidget;
