import { action, computed, makeAutoObservable, observable } from "mobx";
import { apiClient } from "../../api/api";
import { ACCESS_TOKEN, EVENT_ID } from "../../consts/localStorage";
import AppStore from "../AppStore";

class SignInStore {
  @observable public loading: boolean = false;

  @observable public email?: string;

  @observable public password?: string;

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  public validForm = () => !this.password || !this.email;

  @action.bound
  public login = async () => {
    try {
      this.loading = true;

      const formData = new FormData();
      formData.append("email", this.email!);
      formData.append("password", this.password!);

      const data = await apiClient.login(formData);

      if (data.accessToken) {
        if (data.accessToken !== window.localStorage.getItem(ACCESS_TOKEN)) {
          window.localStorage.removeItem(EVENT_ID);
        }

        window.localStorage.setItem(ACCESS_TOKEN, data.accessToken);
        AppStore.setLayout();
      }

      return true;
    } catch (e) {
      console.error("SignInStore.login", e);
    } finally {
      this.loading = false;
    }
  };

  @action.bound
  public logout = () => {
    localStorage.clear();
  };

  @action.bound
  public handleChangeEmail = (value: string) => (this.email = value);

  @action.bound
  public handleChangePassword = (value: string) => (this.password = value);
}

export default new SignInStore();
