import { observer } from "mobx-react-lite";
import React from "react";
import strings from "../../../../i18n/strings";
import { Button, Dialog } from "../../../components/common";
import DialogsStore from "../../../store/DialogsStore";
import DonationsStore from "../../../store/DonationsStore";

import styles from "./DonationDialog.module.scss";

const DonationConfirmDeleteCommentDialog = observer(() => (
  <Dialog
    isOpen={DialogsStore.isOpenDonationDeleteCommentConfirmDialog()}
    onClose={DialogsStore.onClose}
    dialogTitle={
      <div className={styles.title}>
        {strings.donations.dialog_confirm_delete_comment.title}
      </div>
    }
    dialogContent={
      <div className={styles.bodyWrapper}>
        <div>{strings.donations.dialog_confirm_delete_comment.message}</div>

        <div className={styles.footerWrapper}>
          <Button onClick={DialogsStore.onClose}>
            {strings.buttons.cancel}
          </Button>

          <Button
            type="accent"
            width={150}
            loading={DonationsStore.loadingDeleteComment}
            onClick={DonationsStore.onDeleteDonorComment}
          >
            {strings.buttons.delete}
          </Button>
        </div>
      </div>
    }
  />
));

export default DonationConfirmDeleteCommentDialog;
