import { computed, makeAutoObservable, observable } from "mobx";
import strings from "../../../i18n/strings";
import { apiClient } from "../../api/api";
import {
  IGoalsFormDataStudent,
  IProfileFormDataStudent,
  IStudent,
} from "../../interfaces/IStudentData";
import DialogsStore from "../DialogsStore";
import EventStore from "../EventStore";
import SnackBarStore from "../SnackBarStore";

class StudentStore {
  @observable public data?: IStudent;
  @observable public formProfileData?: IProfileFormDataStudent;
  @observable public formGoalsData?: IGoalsFormDataStudent;
  @observable public firstAndLastName: string = "";
  @observable public loading: boolean = false;
  @observable public loadingLoginBtn: boolean = false;
  @observable public saveLoading: boolean = false;
  @observable public disabledStateSaveBtn: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  public fetchStudentInfo = async (id: string) => {
    try {
      this.loading = true;
      this.disabledStateSaveBtn = true;
      const data = await apiClient.getStudentDataById(id);
      this.data = data;
      this.firstAndLastName = `${this.data?.user.firstName} ${this.data?.user.lastName}`;

      if (this.data) {
        this.formGoalsData = {
          memberId: this.data.id.toString(),
          fundraisingPerMember: this.data.team.goals.fundraisingPerMember.toString(),
          readingBooksPerMember: this.data.team.goals.readingBooksPerMember.toString(),
          readingMinsPerMember: this.data.team.goals.readingMinsPerMember.toString(),
        };

        this.formProfileData = {
          displayName: this.data.user.displayName,
          firstName: this.data.user.firstName,
          lastName: this.data.user.lastName,
          avatar: this.data.user.avatar,
          teamId: this.data.team.id.toString(),
        };
      }
    } catch (e) {
      console.error("StudentStore.fetchTeamMember", e);
    } finally {
      this.loading = false;
    }
  };

  @computed
  public onLoginByStudentId = async () => {
    try {
      if (this.data) {
        this.loadingLoginBtn = true;
        const loginData = await apiClient.loginByStudentId(this.data.user.id);

        window.open(loginData.url);
      }
    } catch (e) {
      console.error("StudentStore.onLoginByStudentId", e);
    } finally {
      this.loadingLoginBtn = false;
    }
  };

  @computed
  public handleChangeProfileStudentData = (
    value: string,
    key: keyof IProfileFormDataStudent
  ) => {
    this.disabledStateSaveBtn = false;
    if (this.formProfileData) {
      if (key === "firstName") {
        if (!value) {
          this.disabledStateSaveBtn = true;
        }
        this.firstAndLastName = value;
        const name = this.firstAndLastName.replace("  ", " ").split(" ");
        this.formProfileData.firstName = name[0] || "";
        this.formProfileData.lastName = name[1] || "";

        return;
      }

      this.formProfileData[key] = value;
      DialogsStore.onClose();
    }
  };

  @computed
  public handleChangeGoals = (
    value: string,
    key: keyof IGoalsFormDataStudent
  ) => {
    this.disabledStateSaveBtn = false;
    if (this.formGoalsData) {
      this.formGoalsData[key] = value;
    }
  };

  @computed
  public onSave = async () => {
    try {
      this.saveLoading = true;
      const bodyGoals = new FormData();
      const bodyProfile = new FormData();

      if (this.formGoalsData && this.formProfileData && this.data) {
        const {
          readingMinsPerMember,
          fundraisingPerMember,
          readingBooksPerMember,
          memberId,
        } = this.formGoalsData;

        const {
          teamId,
          avatar,
          displayName,
          lastName,
          firstName,
        } = this.formProfileData;

        bodyGoals.append("memberId", memberId);
        bodyGoals.append("fundraisingPerMember", fundraisingPerMember);
        bodyGoals.append("readingBooksPerMember", readingBooksPerMember);
        bodyGoals.append("readingMinsPerMember", readingMinsPerMember);

        bodyProfile.append("teamId", teamId);
        bodyProfile.append("displayName", displayName);
        bodyProfile.append("lastName", lastName);
        bodyProfile.append("firstName", firstName);
        bodyProfile.append("avatar", avatar);

        await apiClient.updateStudentGoals(EventStore.eventId, bodyGoals);
        await apiClient.updateStudentProfile(this.data.user.id, bodyProfile);

        SnackBarStore.setSnackbar(strings.students.edit_form.update_success);
      }
    } catch (e) {
      console.error("StudentStore.onSave", e);
    } finally {
      this.saveLoading = false;
    }
  };
}

export default new StudentStore();
