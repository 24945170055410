import React from "react";

import clsx from "clsx";
import { Stepper as MuiStepper } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { ReactComponent as CompletedIcon } from "../../../../images/stepper/completedIcon.svg";
import {
  makeStyles,
  StepConnector,
  StepIconProps,
  withStyles,
} from "@material-ui/core";

import styles from "./Stepper.module.scss";

interface IProps {
  content: string[];
  activeStep: number;
}

const StyledMuiStepper = withStyles({
  root: {
    backgroundColor: "transparent",
    padding: 0,

    "& .MuiStepLabel-label": {
      color: styles.defaultLabelColor,
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "14px",
    },

    "& .MuiStepLabel-label.MuiStepLabel-completed": {
      color: styles.activeLabelColor,
      fontWeight: 500,
    },

    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: styles.activeLabelColor,
      fontWeight: 600,
    },
  },
})(MuiStepper);

const StyledConnector = withStyles({
  root: {
    padding: "2px 5px",
    margin: 0,
  },
  active: {
    "& $line": {
      borderColor: styles.activeStepConnectorColor,
    },
  },
  completed: {
    "& $line": {
      borderColor: styles.activeStepConnectorColor,
    },
  },
  line: {
    borderColor: styles.defaultStepConnectorColor,
    borderTopWidth: 3,
    borderRadius: 1,
    minHeight: 30,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: styles.defaultStepCircleColor,
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  circle: {
    width: 11,
    height: 11,
    borderRadius: "50%",
    backgroundColor: styles.defaultStepCircleColor,
  },
  active: {
    width: 18,
    height: 18,
    marginLeft: "-3px",
    borderRadius: "50%",
    backgroundColor: styles.activeStepCircleColor,
  },
  completed: {
    borderRadius: "50%",
    width: 15,
    height: 15,
    zIndex: 1,
    fontSize: 18,
    backgroundImage: styles.imageBg,
    //backgroundColor: styles.activeStepCircleColor,
  },
});

const StepIcon = (props: StepIconProps) => {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <CompletedIcon className={styles.completedIcon}/> : <div className={classes.circle} />}
    </div>
  );
};

export const Stepper = ({ content, activeStep }: IProps) => {
  return (
    <StyledMuiStepper
      orientation="vertical"
      activeStep={activeStep}
      connector={<StyledConnector />}
    >
      {content.map((item) => (
        <Step key={item}>
          <StepLabel StepIconComponent={StepIcon}>{item}</StepLabel>
        </Step>
      ))}
    </StyledMuiStepper>
  );
};

export default Stepper;
