import React from 'react';
import {observer} from 'mobx-react-lite';
import {Select} from '../../../components/common';

import styles from './MiniForms.module.scss';
import {language} from '../../../consts/language';
import AccountSettingsStore from '../../../store/AccountSettingsStore';

const LanguageForm = observer(() => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.bodyWrapper}>
        <Select
          width={150}
          options={language}
          value={AccountSettingsStore.language}
          onChange={(value) => AccountSettingsStore.setLanguage(value.target.value)}
        />
      </div>
    </div>
  )
})

export default LanguageForm;
