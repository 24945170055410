import React from 'react';
import { Menu } from '@material-ui/core';
import { ReactComponent as EmptyAvatarIcon } from '../../../../images/icons/avatarEmpty.svg';

import { ReactComponent as ArrowDownIcon } from '../../../../images/icons/arrows/arrow-down.svg';
import styles from './ProfileMenu.module.scss'
import { observer } from 'mobx-react-lite';

interface IProps {
  avatar?: string;
  name?: string;
  children: JSX.Element[];
}

export const ProfileMenu = observer(({ avatar, name, children }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.containerWrapper} aria-controls="simple-menu" onClick={(e) => handleClick(e)}>
        <div className={styles.avatarWrapper}>
          {avatar
            ? <img src={avatar} alt="avatar" />
            : <EmptyAvatarIcon />
          }
        </div>

        <div className={styles.userName}>{name}</div>

        <ArrowDownIcon className={Boolean(anchorEl) ? styles.rotateArrow : ''} />
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div onClick={handleClose}>
          {children}
        </div>
      </Menu>
    </div>
  )
})
