import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import strings from "../../../i18n/strings";
import {Button, ComboBox, Input, Loader, Tooltip,} from "../../components/common";
import {ReactComponent as DownloadIcon} from "../../../images/icons/students/download.svg";
import {ReactComponent as HelperIcon} from "../../../images/icons/helper.svg";
import {ReactComponent as PlusIcon} from "../../../images/icons/plus.svg";
import {ReactComponent as SearchIcon} from "../../../images/icons/search.svg";
import DonationsStore from "../../store/DonationsStore";
import DonationsTable from "./DonationsTable/DonationsTable";

import styles from "./Donations.module.scss";
import EventStore from "../../store/EventStore";
import {throttle} from "lodash";
import TeamStore from "../../store/TeamsStores/TeamStore";
import UserStore from "../../store/UserStore";

const Donations = observer(() => {
    useEffect(() => {
        DonationsStore.fetchDataList();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const url = new URL(window.location.href);
        const teamId = url.searchParams.get("teamId");

        if (teamId) {
            DonationsStore.handleChangeFilterByTeamId(teamId);
        }
    }, []);

    const onRedirectDonateNow = () =>
        window.open(`${EventStore.event?.shareLink}/donate`);

    let teams = TeamStore.TeamOptionsWithDonations;
    teams = teams.filter(t => !t.disabled)
    return (
        <div className="screenWrapper">
            <div className="content">
                <div className="contentWrapper">
                    <div className={styles.headerWrapper}>
                        <div className={styles.pageTitleWrapper}>
                            <span className={styles.title}>{strings.donations.title}</span>
                            <Tooltip title={strings.overview.tips.donor_list_title}>
                                <HelperIcon fill={styles.blueMain}/>
                            </Tooltip>
                        </div>

                        <div className={styles.buttonsWrapper}>
                            {DonationsStore.data?.length > 0 && (
                                <Button
                                    border
                                    borderColor="blue"
                                    startIcon={<DownloadIcon fill={styles.blueMain}/>}
                                    onClick={DonationsStore.exportDonations}
                                >
                                    {strings.donations.download_csv}
                                </Button>
                            )}

                            {UserStore.user?.role === 'EA' && EventStore.event?.accountId ? (
                                <Button
                                    type="primary"
                                    onClick={onRedirectDonateNow}
                                    startIcon={<PlusIcon/>}
                                >
                                    {strings.overview.donate_now}
                                </Button>
                            ) : null}
                        </div>
                    </div>

                    <div className={styles.filtersWrapper}>
                        <Input
                            startAdornment={<SearchIcon/>}
                            onChange={throttle(
                                DonationsStore.handleChangeFilterByNameOrEmail,
                                100
                            )}
                            placeholder={strings.donations.filters.search_by_donor_name_email}
                            value={DonationsStore.filterByNameOrEmail}
                            width={"40%"}
                        />
                        <div style={{ width: 10 }}></div>
                        <ComboBox
                            width={"40%"}
                            label={strings.students.filters.team}
                            options={teams}
                            value={DonationsStore.filterByTeamId}
                            onChange={DonationsStore.handleChangeFilterByTeamId}
                        />
                        <div style={{ width: 10 }}></div>

                        <ComboBox
                            width={"20%"}
                            label={strings.donations.table_columns.corp_matching}
                            options={[{ value: 'true', label: 'Yes'}, { value: 'false', label: 'No'}]}
                            value={DonationsStore.filterByCorpMatching}
                            onChange={DonationsStore.handleChangeFilterByCorpMatching}
                        />
                    </div>
                </div>

                <div className={styles.tableWrapper}>
                    {DonationsStore.loading ? (
                        <Loader/>
                    ) : (
                        <DonationsTable data={DonationsStore.filteredData}/>
                    )}
                </div>
            </div>
        </div>
    );
});

export default Donations;
