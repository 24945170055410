import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { ReactComponent as EmailIcon } from '../../../../../images/icons/email.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../images/icons/arrows/arrow-right.svg';
import styles from './ConfirmEmailForm.module.scss';
import strings from '../../../../../i18n/strings';
import SignUpStore from '../../../../store/Registration/SignUpStore';
import { Button } from '../../../../components/common';
import { useHistory } from 'react-router';
import { Path } from '../../../../consts/router';

const ConfirmEmailComponentForm = observer(() => {
  const history = useHistory();

  useEffect(() => {
    SignUpStore.setSignUpStep(0);
  }, [])

  const onRouteNextStep = () => {
    history.push(Path.signUpSchoolInfo);
  }

  return (
    <div className={styles.formWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>
          {strings.RegistrationForms.confirm_email.title}
        </div>

        <div className={styles.descriptionWrpper}>
          <div>{strings.RegistrationForms.confirm_email.description2}</div>
        </div>

        <div className={styles.bodyWrapper}>
          <EmailIcon />
          <div className={styles.email}>{SignUpStore.email}</div>
        </div>

        <div className={styles.footerWrapper}>
          <Button
            width={225}
            type="primary"
            disabled
            loading={SignUpStore.loading}
            endIcon={SignUpStore.loading ? <></> : <ArrowRightIcon fill="white" />}
            onClick={onRouteNextStep}
          >
            {strings.RegistrationForms.confirm_email.wait_for_verification}
          </Button>
        </div>
      </div>
    </div>
  )
})

export default ConfirmEmailComponentForm;
