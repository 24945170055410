import { observer } from "mobx-react-lite";
import React from "react";
import strings from "../../../../i18n/strings";
import { Button, Dialog } from "../../../components/common";
import DialogsStore from "../../../store/DialogsStore";

import styles from "./FundraiserDialogs.module.scss";

interface IProps {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const FundraiserCloseConfirmDialog = observer(
  ({ loading, isOpen, onConfirm, onClose }: IProps) => (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      dialogTitle={
        <div className={styles.title}>
          {strings.fundraiser_close.confirm_dialog.title}
        </div>
      }
      dialogContent={
        <div className={styles.bodyWrapper}>
          <div>{strings.fundraiser_close.confirm_dialog.message}</div>

          <div className={styles.footerWrapper}>
            <Button onClick={DialogsStore.onClose}>
              {strings.buttons.cancel}
            </Button>

            <Button
              type="accent"
              width={150}
              loading={loading}
              onClick={onConfirm}
            >
              {strings.fundraiser_close.confirm_dialog.close_btn}
            </Button>
          </div>
        </div>
      }
    />
  )
);

export default FundraiserCloseConfirmDialog;
