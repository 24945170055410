import {action, computed, makeAutoObservable, observable} from "mobx";

export enum Dialogs {
    AVATAR_SELECTION = "AVATAR_SELECTION",
    TEAM_DELETE_NOTIFICATION = "TEAM_DELETE_NOTIFICATION",
    TEAM_DELETE_CONFIRM = "TEAM_DELETE_CONFIRM",
    STUDENT_DELETE_CONFIRM = "STUDENT_DELETE_CONFIRM",
    CONFIRM_LOGOUT = "CONFIRM_LOGOUT",
    RESEND_INVITE_TEAM_LEADER = "RESEND_INVITE_TEAM_LEADER",
    DONATION_DELETE_COMMENT_CONFIRM = "DONATION_DELETE_COMMENT_CONFIRM",
    FUNDRAISER_CLOSE_CONFIRM = "FUNDRAISER_CLOSE_CONFIRM",
    FUNDRAISER_START_CONFIRM = "FUNDRAISER_START_CONFIRM",
    TEACHER_DELETE_CONFIRM = "TEACHER_DELETE_CONFIRM",
}

class DialogsStore {
    @observable activeDialog?: Dialogs;

    constructor() {
        makeAutoObservable(this);
    }

    @action.bound
    public setActiveDialog = (dialogName: Dialogs) =>
        (this.activeDialog = dialogName);

    @action.bound
    public onClose = () => (this.activeDialog = undefined);

    public isOpen = (dialog: string) => {
        return this.activeDialog === dialog;
    }
    @computed
    public isOpenTeamDeleteNotificationDialog = () =>
        this.activeDialog === Dialogs.TEAM_DELETE_NOTIFICATION;

    @computed
    public isOpenStudentDeleteConfirmDialog = () =>
        this.activeDialog === Dialogs.STUDENT_DELETE_CONFIRM;

    @computed
    public isOpenTeamDeleteConfirmDialog = () =>
        this.activeDialog === Dialogs.TEAM_DELETE_CONFIRM;

    @computed
    public isOpenConfirmLogoutDialog = () =>
        this.activeDialog === Dialogs.CONFIRM_LOGOUT;

    @computed
    public isOpenAvatarSelectionDialog = () =>
        this.activeDialog === Dialogs.AVATAR_SELECTION;

    @computed
    public isOpenResendInviteTeamLeaderDialog = () =>
        this.activeDialog === Dialogs.RESEND_INVITE_TEAM_LEADER;

    @computed
    public isOpenDonationDeleteCommentConfirmDialog = () =>
        this.activeDialog === Dialogs.DONATION_DELETE_COMMENT_CONFIRM;

    @computed
    public isOpenFundraiserCloseConfirmDialog = () =>
        this.activeDialog === Dialogs.FUNDRAISER_CLOSE_CONFIRM;

    @computed
    public isOpenFundraiserStartConfirmDialog = () =>
        this.activeDialog === Dialogs.FUNDRAISER_START_CONFIRM;
}

export default new DialogsStore();
