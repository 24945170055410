import React from "react";
import { Link } from "react-router-dom";
import { Path } from "../../../consts/router";
import strings from "../../../../i18n/strings";

import { ReactComponent as FundraiserIcon } from "../../../../images/menuIcons/edit_fundraiser.svg";
import { ReactComponent as OverviewIcon } from "../../../../images/menuIcons/overview.svg";
import { ReactComponent as StudentsIcon } from "../../../../images/menuIcons/students.svg";
import { ReactComponent as TeamsIcon } from "../../../../images/menuIcons/contacts.svg";
import { ReactComponent as DonationsIcon } from "../../../../images/menuIcons/donations.svg";
import { ReactComponent as SidebarMenuIcon } from "../../../../images/icons/sidebarMenu.svg";
import { ReactComponent as TeacherIcon } from "../../../../images/menuIcons/teachers.svg";
import { ReactComponent as FaqToolkitIcon } from "../../../../images/menuIcons/faqToolkit.svg";
import { ReactComponent as AnnouncementIcon } from "../../../../images/menuIcons/announcement.svg";
import styles from "./Sidebar.module.scss";
import AppStore from "../../../store/AppStore";
import { observer } from "mobx-react-lite";
import UserStore from "../../../store/UserStore";
import EventStore from "../../../store/EventStore";

interface IProps {
  onClose?: () => void;
  onMenuClick?: () => void;
  showOnlyIcons?: boolean;
}

const MenuItemList = observer(
  ({ onClose, onMenuClick, showOnlyIcons }: IProps) => {
    const getRouteStyle = (path: string) => {
      if (window.location.pathname.startsWith(path)) {
        return styles.selectedRoute;
      }

      return styles.route;
    };

    const onRoute = (path: string) => {
      AppStore.getBreadCrumbByPath(path);
      onClose && onClose();
    };

    return (
      <div className={styles.menuItem}>
        <div>
          {showOnlyIcons && (
            <div onClick={onMenuClick} className={styles.menuBtn}>
              <SidebarMenuIcon/>
            </div>
          )}

          <Link
            to={Path.main}
            onClick={() => onRoute(Path.main)}
            className={getRouteStyle(Path.main)}
          >
            <OverviewIcon/>
            <div hidden={showOnlyIcons}>{strings.sidebar.overview}</div>
          </Link>

          <Link
            to={Path.teams}
            onClick={() => onRoute(Path.teams)}
            className={getRouteStyle(Path.teams)}
          >
            <TeamsIcon/>
            <div hidden={showOnlyIcons}>{strings.sidebar.teams}</div>
          </Link>

          {UserStore.user?.role === 'EA' && EventStore.event?.id &&
              <Link
                  to={Path.teachers}
                  onClick={() => onRoute(Path.teachers)}
                  className={getRouteStyle(Path.teachers)}
              >
                <TeacherIcon/>
                <div hidden={showOnlyIcons}>{strings.sidebar.teachers}</div>
              </Link>}

          <Link
            to={Path.students}
            onClick={() => onRoute(Path.students)}
            className={getRouteStyle(Path.students)}
          >
            <StudentsIcon/>
            <div hidden={showOnlyIcons}>{strings.sidebar.students}</div>
          </Link>

          <Link
            to={Path.donations}
            onClick={() => onRoute(Path.donations)}
            className={getRouteStyle(Path.donations)}
          >
            <DonationsIcon/>
            <div hidden={showOnlyIcons}>{strings.sidebar.donations}</div>
          </Link>

          {UserStore.user?.role === 'EA' &&
              <Link
                  to={Path.fundraiser}
                  onClick={() => onRoute(Path.fundraiser)}
                  className={`${getRouteStyle(Path.fundraiser)} ${
                    showOnlyIcons
                      ? styles.fundraiserSettingsLink
                      : styles.fundraiserSettingsLinkPadding
                  }`}
              >
                  <FundraiserIcon/>
                  <div hidden={showOnlyIcons}>{strings.sidebar.fundraiser}</div>
              </Link>}

          {UserStore.user?.role === 'EA' &&
              <Link
                  to={Path.announcement}
                  onClick={() => onRoute(Path.announcement)}
                  className={getRouteStyle(Path.announcement)}
              >
                  <AnnouncementIcon/>
                  <div hidden={showOnlyIcons}>{strings.sidebar.announcement}</div>
              </Link>}

          <a
            href={strings.links.faq_and_toolkit}
            target="_blank"
            rel="noopener noreferrer"
            className={getRouteStyle("faq")}
          >
            <FaqToolkitIcon style={{ marginLeft: 1 }}/>

            <div hidden={showOnlyIcons}>{strings.sidebar.faq_and_toolkit}</div>
          </a>
        </div>
      </div>
    );
  }
);

export default MenuItemList;
