import { action, makeAutoObservable, observable } from "mobx";
import { apiClient } from "../../api/api";
import { EVENT_ID } from "../../consts/localStorage";
import UserStore from "../UserStore";

class ProfileInfoFormStore {
  @observable public loading?: boolean;

  @observable public isOpenAvatarChangeDialog: boolean = false;

  @observable public checkedPrivacyPolicy?: boolean;

  @observable public displayName?: string;

  @observable public firstName?: string;

  @observable public lastName?: string;

  @observable public avatar?: string;

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound
  public handleOpenAvatarsDialog = () => (this.isOpenAvatarChangeDialog = true);

  @action.bound
  public handleCloseAvatarsDialog = () =>
    (this.isOpenAvatarChangeDialog = false);

  @action.bound
  public handleChangeAvatar = (url: string) => {
    this.avatar = url;
    this.handleCloseAvatarsDialog();
  };

  @action.bound
  public onSave = async () => {
    this.loading = true;
    try {
      const formData = new FormData();

      if (!this.avatar) {
        this.handleOpenAvatarsDialog();

        return;
      }

      formData.append("avatar", this.avatar!);
      formData.append("firstName", this.firstName!);
      formData.append("lastName", this.lastName!);
      formData.append("displayName", this.displayName!);
      this.handleCloseAvatarsDialog();

      const data = await apiClient.updateMeUser(formData);

      const lsEventId = window.localStorage.getItem(EVENT_ID);

      if (
        data.events.length &&
        !data.events.find(
          (item: { id: number }) => item.id.toString() === lsEventId
        )
      ) {
        window.localStorage.setItem(EVENT_ID, data.events[0].id);
      }
      UserStore.fetchData();
      return data;
    } catch (e) {
      console.error("ProfileInfoFormStore.saveAvatar", e);
    } finally {
      this.loading = false;
    }
  };

  @action.bound
  public handleChangeCheckedPrivacyPolicy = () =>
    (this.checkedPrivacyPolicy = !this.checkedPrivacyPolicy);

  @action.bound
  public handleChangeDisplayName = (value: string) =>
    (this.displayName = value);

  @action.bound
  public handleChangeFirstName = (value: string) => (this.firstName = value);

  @action.bound
  public handleChangeLastName = (value: string) => (this.lastName = value);

  @action.bound
  public validForm = () =>
    !(
      this.displayName &&
      this.firstName &&
      this.lastName &&
      this.checkedPrivacyPolicy
    );
}

export default new ProfileInfoFormStore();
