import { action, computed, makeAutoObservable } from "mobx";
import { apiClient } from "../api/api";
import { IAvatarsResponse } from "../api/apiResponseTypes";

export enum AvatarGenderEnum {
  Boys = "Boys",
  Girls = "Girls",
  Others = "Others",
}

class AvatarStore {
  items?: IAvatarsResponse[];
  loading: boolean = false;
  type: AvatarGenderEnum = AvatarGenderEnum.Others;

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound
  public fetchItems = async (role?: string) => {
    try {
      let data = [];
      this.loading = true;
      if (role !== "TM") {
        data = await apiClient.getAvatars();
      } else {
        if (this.type === AvatarGenderEnum.Boys) {
          data = await apiClient.getAvatarsBoys();
        }

        if (this.type === AvatarGenderEnum.Girls) {
          data = await apiClient.getAvatarsGirls();
        }

        if (this.type === AvatarGenderEnum.Others) {
          data = await apiClient.getAvatarsOthers();
        }
      }

      this.items = data;
    } catch (e) {
      console.error("AvatarStore.fetchItems", e);
    } finally {
      this.loading = false;
    }
  };

  @computed
  public handleChangeAvatarType = (value: AvatarGenderEnum) => {
    this.type = value;
  };
}

export default new AvatarStore();
