import { observer } from "mobx-react-lite";
import React from "react";
import strings from "../../../../../../i18n/strings";
import DialogsStore from "../../../../../store/DialogsStore";
import { Button, Dialog } from "../../../../common";

import styles from "./HeaderDialogs.module.scss";

interface IProps {
  onStart: () => void;
}

const ConfirmStartNewFundraiserDialog = observer(({ onStart }: IProps) => {
  return (
    <Dialog
      isOpen={DialogsStore.isOpenFundraiserStartConfirmDialog()}
      onClose={DialogsStore.onClose}
      dialogTitle={
        <div className={styles.title}>
          {strings.header.confirm_start_fnd_dialog.title}
        </div>
      }
      dialogContent={
        <div className={styles.bodyWrapper}>
          <div>{strings.header.confirm_start_fnd_dialog.message}</div>

          <div className={styles.footerWrapper}>
            <Button onClick={DialogsStore.onClose}>
              {strings.buttons.cancel}
            </Button>

            <Button type="primary" width={150} onClick={onStart}>
              {strings.header.confirm_start_fnd_dialog.okBtn}
            </Button>
          </div>
        </div>
      }
    />
  );
});

export default ConfirmStartNewFundraiserDialog;
