import moment from "moment";
import { apiClient } from "../api/api";
import { signUpData } from "../consts/localStorage";

import styles from "./common.module.scss";

export const dayToEnd = (end?: number) => {
  if (!end) {
    return 0;
  }
  if (end < Date.now()) return 0;
  return moment(end).diff(moment(new Date()), "days");
};

export const getSelectOptions = (
  options: string[]
): { value: string; label: string }[] => {
  return options.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
};

// from A - 65 ASCI code  to Z - 91 ASCI code
export const getAvatarBgColorByName = (name: string) => {
  const symbol = name?.toUpperCase().charCodeAt(0);
  switch (symbol % 5) {
    case 0:
      return styles.blueBgAvatar;
    case 1:
      return styles.redBgAvatar;
    case 2:
      return styles.purpleBgAvatar;
    case 3:
      return styles.darkBluBgAvatar;
    case 4:
      return styles.greenBgAvatar;

    default:
      return styles.default;
  }
};

export const formatCurrency = (currency?: string) => {
  if (!currency) return "";

  currency = currency.toUpperCase();

  if ("USD" === currency) return "$";
  if ("GBP" === currency) return "£";
  if ("EUR" === currency) return "€";
  if ("AUD" === currency) return "$";
  if ("CAD" === currency) return "$";

  return currency;
};

export const validZipCode = (zip: string, country: string) => {
  const code = zip;
  const state = country;
  let valid = true;
  let ukPostalRe = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)| (([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
  let usPostalRe = /^\d{5}(?:[-\s]\d{4})?$/;
  if (code) {
    if (state === "United Kingdom") {
      valid = ukPostalRe.test(code.trim());
    } else if (state === "United States") {
      valid = usPostalRe.test(code.trim());
    }
  }

  return valid;
};

export const onRedirectToAuth = (provider: "facebook" | "google") => {
  window.location.href = apiClient.authUrlWithProvider(provider);
};

export const setItemToSignUpLS = (obj: any) => {
  const signUpDataLS = JSON.parse(localStorage.getItem(signUpData) || "{}");
  localStorage.setItem(
    signUpData,
    JSON.stringify(Object.assign(signUpDataLS, obj))
  );
};

export const getItemSignUpFromLS = () => {
  return JSON.parse(localStorage.getItem(signUpData) || "{}");
};

export const transformNumberValue = (value?: number, fixedCount?: number) => {
  if (!value) {
    return '0';
  }

  if (value >= 10000) {
    if (fixedCount) {
      return `${Number((value / 1000).toFixed(fixedCount)).toLocaleString()}K`;
    } else {
      return `${(value / 1000).toLocaleString()}K`;
    }
  } else {
    return value.toLocaleString();
  }
};


export const replaceLinks = (text?: string) => {
  return text ? text.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>') : '';
}
