import {observer} from "mobx-react-lite";
import React from "react";
import strings from "../../../../i18n/strings";
import Avatar from "@material-ui/core/Avatar";

import styles from "./TeachersTable.module.scss";
import {getAvatarBgColorByName,} from "../../../utils/common";
import {ReactComponent as VerifiedIcon} from "../../../../images/icons/checkCircle.svg";
import TeachersStore from "../../../store/TeamsStores/TeachersStore";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import {Path} from "../../../consts/router";
import {ReactComponent as MoreIcon} from "../../../../images/icons/more.svg";

import {MenuItem, Tooltip} from "../../../components/common";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import DialogsStore, {Dialogs} from "../../../store/DialogsStore";
import {IconButton, Menu} from "@material-ui/core";
import {ITeacher} from "../../../api/apiResponseTypes";

const TeachersTable = observer(() => {
    const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
    const [selected, setSelected] = React.useState<null | ITeacher>(null);

    const handleClick = (event: any, item: ITeacher) => {
        setAnchorEl(event.currentTarget);
        setSelected(item)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <table className="tableWithHover">
                <thead>
                <tr>
                    <th className={styles.teamLeadNameColumn}>
                        <span>{strings.teachers.table_columns.name}</span>
                    </th>

                    <th>
                        <span>{strings.teachers.table_columns.teams}</span>
                    </th>

                    <th>
                        <span>{strings.teachers.table_columns.active_at}</span>
                    </th>

                    <th>
                        <span>{strings.teachers.table_columns.registered_at}</span>
                    </th>

                </tr>
                </thead>

                <tbody>
                <>
                    {TeachersStore.data?.length ? (
                        <>
                            {TeachersStore.data.map((item) => (
                                <tr key={item.id}>
                                    <td className={styles.teamLeadNameCell}>
                                        <Avatar
                                            style={{
                                                backgroundColor: getAvatarBgColorByName(item.firstName?.substr(0, 1)?.toUpperCase()),
                                            }}
                                        >
                                            {`${item.firstName?.substr(0, 1).toUpperCase()}`}
                                        </Avatar>

                                        <div className={styles.nameWrapper}>
                                            <div className={styles.name}>{`${item.firstName} ${item.lastName}`}</div>
                                            <div className={styles.email}>{item.email}
                                                {item.emailVerified &&
                                                    <Tooltip title={`Verified`}><VerifiedIcon
                                                        className={styles.pendingIcon}/>
                                                    </Tooltip>}
                                            </div>
                                        </div>

                                    </td>


                                    <td>
                                        {item.teams.map(team =>
                                            <div key={team.id}>
                                                &bull; <Link
                                                to={`${Path.teams}/${team.id}`}>{team.name} ({team.gradeDisplay})</Link>
                                            </div>)
                                        }
                                    </td>

                                    <td>
                                        {moment(item.activeAt).fromNow()}
                                    </td>
                                    <td>
                                        {moment(item.createdAt).format("MM/DD/YYYY")}
                                    </td>
                                    <td>
                                        <IconButton onClick={(e) => handleClick(e, item)}>
                                            <MoreIcon />
                                        </IconButton>
                                    </td>
                                </tr>
                            ))}
                        </>
                    ) : (
                        <div className="noDataTable">
                            <>
                                <div>{strings.teachers.no_data}</div>
                            </>
                        </div>
                    )}
                </>
                </tbody>
            </table>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <div onClick={handleClose}>
                    <MenuItem
                        onClick={() =>
                            DialogsStore.setActiveDialog(Dialogs.TEACHER_DELETE_CONFIRM)
                        }
                    >
                        {strings.teachers.delete_dialog.confirm_btn}
                    </MenuItem>
                </div>
            </Menu>
            <ConfirmDeleteDialog user={selected}/>

        </>
    );
});

export default TeachersTable;
