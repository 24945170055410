import { observer } from "mobx-react-lite";
import React from "react";
import { RadioGroup } from "../..";
import strings from "../../../../../i18n/strings";

import { ReactComponent as RedirectIcon } from "../../../../../images/icons/redirect.svg";
import AvatarsStore, { AvatarGenderEnum } from "../../../../store/AvatarsStore";
import EventStore from "../../../../store/EventStore";
import styles from "./AvatarDialog.module.scss";

interface IProps {
  role?: string;
  onUpdateAvatars: () => void;
}



const AvatarDialogTitle = observer(({ role, onUpdateAvatars }: IProps) => {

  const RadioGroupOptions = [
    {
      label: strings.account_settings.avatar_dialog.boys,
      value: AvatarGenderEnum.Boys,
    },
    {
      label: strings.account_settings.avatar_dialog.girls,
      value: AvatarGenderEnum.Girls,
    },
    {
      label: strings.account_settings.avatar_dialog.others,
      value: AvatarGenderEnum.Others,
    },
  ];
  
  const classNameWrapper =
    role === "TM" ? styles.titleWrapperTM : styles.titleWrapper;

  return (
    <div className={classNameWrapper}>
      <span className={styles.title}>
        {strings.account_settings.avatar_dialog.title}
      </span>

      {role === "TM" ? (
        <RadioGroup
          className={styles.RadioGroupWrapper}
          value={AvatarsStore.type}
          defaultValue={AvatarsStore.type}
          onChange={(e) => {
            AvatarsStore.handleChangeAvatarType(
              e.target.value as AvatarGenderEnum
            );
            onUpdateAvatars();
          }}
          options={RadioGroupOptions}
        />
      ) : (
        <div className={styles.description}>
          <span>{strings.account_settings.avatar_dialog.description}</span>
          &nbsp;
          <a
            href={EventStore.event?.shareLink}
            target="_blank"
            rel="noreferrer"
          >
            {strings.account_settings.avatar_dialog.donation_page}
            <RedirectIcon fill={styles.redirectSvgColor} />
          </a>
        </div>
      )}
    </div>
  );
});

export default AvatarDialogTitle;
