import React from 'react';
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import {DialogTitle as MuiDialogTitle} from "@material-ui/core";
import {Button} from '../../Button/Button';
import {ReactComponent as CloseIcon} from '../../../../../images/icons/close.svg';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      margin: 0,
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  });

interface IProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  showCloseIcon?: boolean;
  onClose?: () => void;
}

export const DialogTitle = withStyles(styles)((props: IProps) => {
  const {children, classes, onClose, showCloseIcon, ...other} = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <>
        {children}
        {onClose && showCloseIcon ? (
          <Button componentType='iconButton' onClick={onClose} aria-label="close" className={classes.closeButton}>
            <CloseIcon />
          </Button>
        ) : null}
      </>
    </MuiDialogTitle>
  )
});
