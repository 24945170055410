import {observer} from 'mobx-react-lite';
import React from 'react';
import strings from '../../../../i18n/strings';
import {Button, Dialog} from '../../../components/common';
import DialogsStore from '../../../store/DialogsStore';
import TeamEditStore from '../../../store/TeamsStores/TeamEditStore';

import styles from './TeamDeleteDialog.module.scss';

const TeamConfirmDeleteDialog = observer(() => (
  <Dialog
    isOpen={DialogsStore.isOpenTeamDeleteConfirmDialog()}
    onClose={DialogsStore.onClose}
    dialogTitle={<div className={styles.title}>{strings.teams.dialogs.confirm_delete_title}</div>}
    dialogContent={
      <div className={styles.bodyWrapper}>
        <div>{strings.teams.dialogs.confirm_delete_message}</div>

        <div className={styles.footerWrapper}>
          <Button onClick={DialogsStore.onClose}>
            {strings.buttons.cancel}
          </Button>

          <Button
            type="accent"
            width={150}
            loading={TeamEditStore.loading}
            onClick={TeamEditStore.deleteTeamById}

          >
            {strings.teams.dialogs.delete_team}
          </Button>
        </div>
      </div>
    }
  />
))

export default TeamConfirmDeleteDialog;
