import React, {useState} from 'react';
import {Modal} from "@material-ui/core";

import styles from './ModalUploader.module.scss';
import {Button, Input} from '../../common';
import strings from '../../../../i18n/strings';

interface IProps {
  type: "video" | 'image';
  open: boolean;
  isLoadingUploadImage?: boolean;
  isLoadingDeleteImage?: boolean;
  isLoadingUploadVideo?: boolean;
  url?: string;
  handleClose?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  onUploadImage?: (FileList: FileList | null) => void;
  onUploadVideo?: (url?: string) => void;
  onChangeVideoUrl?: (url: string) => void;
}

const ModalUploader = ({
  type,
  open,
  url,
  isLoadingUploadImage,
  isLoadingDeleteImage,
  isLoadingUploadVideo,
  onDelete,
  handleClose,
  onUploadImage,
  onUploadVideo,
  onCancel,
  onChangeVideoUrl
}: IProps) => {
  const [videoUrl, setVideoUrl] = useState(url);
  const imageDescription = strings.fundraiser_details.modal_upload_image_description;
  const videoDescription = strings.fundraiser_details.modal_upload_video_description;

  const ImageModalFooterControls = () => (
    <>
      <Button onClick={handleClose} disabled={isLoadingUploadImage || isLoadingDeleteImage}>{strings.buttons.cancel}</Button>
      {url && <Button onClick={onDeletePhoto} textColor='red' disabled={isLoadingUploadImage} loading={isLoadingDeleteImage}>{strings.buttons.delete}</Button>}
      {url
        ? <Button uploader onUpload={(FileList) => handleUploadPhoto(FileList)} disabled={isLoadingDeleteImage} loading={isLoadingUploadImage}>{strings.buttons.change}</Button>
        : <Button uploader onUpload={(FileList) => handleUploadPhoto(FileList)} loading={isLoadingUploadImage}>{strings.buttons.upload}</Button>
      }
    </>
  )

  const onDeletePhoto = async () => {
    onDelete && await onDelete();
    handleClose && handleClose();
  }

  const handleUploadPhoto = async (FileList: FileList | null) => {
    onUploadImage && await onUploadImage(FileList);
    handleClose && handleClose();
  }

  const onCancelChangeVideoUrl = () => {
    onCancel && onCancel();
    handleClose && handleClose();
  }

  const handleClickSaveVideo = async () => {
    onChangeVideoUrl && onChangeVideoUrl(videoUrl || '')
    onUploadVideo && await onUploadVideo(videoUrl)
    handleClose && handleClose();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.modalTitle}>
          {strings.formatString(strings.fundraiser_details.modal_upload_title, type)}
        </div>

        <div className={styles.modalDesctiption}>
          {type === 'video' ? videoDescription : imageDescription}
          {type === 'video' &&
            <Input
              label={strings.fundraiser_details.modal_upload_video_link}
              value={videoUrl}
              onChange={(value) => setVideoUrl(value)}
            />}
        </div>

        <div className={styles.footer}>
          {type === 'image'
            ? <ImageModalFooterControls />
            : (
              <>
                <Button onClick={onCancelChangeVideoUrl} disabled={isLoadingUploadVideo}>{strings.buttons.cancel}</Button>
                <Button onClick={handleClickSaveVideo} loading={isLoadingUploadVideo}>{strings.buttons.save}</Button>
              </>
            )
          }
        </div>
      </div>
    </Modal>
  )
}

export default ModalUploader;
