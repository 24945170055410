import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory } from 'react-router';
import strings from '../../../../../../i18n/strings';
import { Path } from '../../../../../consts/router';
import AppStore from '../../../../../store/AppStore';
import DialogsStore from '../../../../../store/DialogsStore';
import SignInStore from '../../../../../store/Registration/SignInStore';
import { Button, Dialog } from '../../../../common';


import styles from './HeaderDialogs.module.scss';

const ConfirmLogoutDialog = observer(() => {
  const history = useHistory();

  const onLogout = () => {
    SignInStore.logout();
    history.push(Path.signIn);
    AppStore.setLayout();
    DialogsStore.onClose();
  }

  return (
    <Dialog
      isOpen={DialogsStore.isOpenConfirmLogoutDialog()}
      onClose={DialogsStore.onClose}
      dialogTitle={<div className={styles.title}>{strings.profile_menu.logout_confirm_dialog.title}</div>}
      dialogContent={
        <div className={styles.bodyWrapper}>
          <div>{strings.profile_menu.logout_confirm_dialog.description}</div>

          <div className={styles.footerWrapper}>
            <Button onClick={DialogsStore.onClose}>
              {strings.buttons.cancel}
            </Button>

            <Button
              type="primary"
              width={150}
              onClick={onLogout}
            >
              {strings.profile_menu.log_out}
            </Button>
          </div>
        </div>
      }
    />
  )
})

export default ConfirmLogoutDialog;