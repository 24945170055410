import strings from "../../i18n/strings";

export const signUpTimelint = [
  strings.RegistrationForms.signUp.timeline.create_an_account,
  // strings.RegistrationForms.signUp.timeline.choose_a_pricing_model,
  strings.RegistrationForms.signUp.timeline.provide_your_school_info,
  strings.RegistrationForms.signUp.timeline.provide_fundraiser_info,
  strings.RegistrationForms.signUp.timeline.provide_your_profile_info,
  strings.RegistrationForms.signUp.timeline.complete_registration,
];

export const OrganizationTypes = [
  { label: "PTA School", value: "PTA School" },
  { label: "PTO School", value: "PTO School" },
  { label: "Private School", value: "Private School" },
  { label: "Library", value: "Library" },
  { label: "Other", value: "Other" },
];

export const countries = [
  { value: "US", label: "United States" },
  { value: "CA", label: "Canada" },
  { value: "AU", label: "Australia" },
  { value: "UK", label: "United Kingdom" },
  { value: "AT", label: "Austria" },
  { value: "BE", label: "Belgium" },
  { value: "BG", label: "Bulgaria" },
  { value: "HR", label: "Croatia" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "EE", label: "Estonia" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "DE", label: "Germany" },
  { value: "GR", label: "Greece" },
  { value: "HU", label: "Hungary" },
  { value: "IE", label: "Ireland" },
  { value: "IT", label: "Italy" },
  { value: "LV", label: "Latvia" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MT", label: "Malta" },
  { value: "NL", label: "Netherlands" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "RO", label: "Romania" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "ES", label: "Spain" },
  { value: "SE", label: "Sweden" },
];

export const durations = [
  { label: "1 month", value: 31 },
  { label: "2 months", value: 60 },
  { label: "3 months", value: 90 },
  { label: "4 months", value: 120 },
  { label: "5 months", value: 150 },
  { label: "6 months", value: 180 },
];
