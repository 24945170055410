import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";

import styles from "./SignUpForm.module.scss";
import strings from "../../../../../i18n/strings";
import { Button, Input } from "../../../../components/common";
import { Divider } from "@material-ui/core";
import { ReactComponent as ArrowRightIcon } from "../../../../../images/icons/arrows/arrow-right.svg";
import { ReactComponent as FaceBookIcon } from "../../../../../images/signIn/faceBookIcon.svg";
import { ReactComponent as GoogleIcon } from "../../../../../images/signIn/googleIcon.svg";
import { Link } from "react-router-dom";
import { Path } from "../../../../consts/router";
import SignUpStore from "../../../../store/Registration/SignUpStore";
import { onRedirectToAuth } from "../../../../utils/common";

const SignUpComponentForm = observer(() => {

  const listener = useCallback((e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onRouteNextStep();
    }
  }, []);

  useEffect(() => {
    SignUpStore.setSignUpStep(0);

    window.addEventListener("keypress", listener);

    return () => {
      window.removeEventListener("keypress", listener);
    };
  }, [listener]);

  const onRouteNextStep = () => {
    SignUpStore.signUp();
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>
          {strings.RegistrationForms.signUp.title}
        </div>

        <div className={styles.descriptionWrpper}>
          <div>{strings.RegistrationForms.signUp.description1}</div>

          <div>
            {strings.formatString(
              strings.RegistrationForms.signUp.description2,
              <Link to={Path.signIn}>
                {strings.RegistrationForms.signUp.log_in}
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className={styles.bodyWrapper}>
        <div className={styles.loginMethodsWrapper}>
          <Button
            width={225}
            buttonColor={styles.facebook_btnColor}
            textColor="white"
            startIcon={<FaceBookIcon />}
            onClick={() => onRedirectToAuth("facebook")}
          >
            {strings.RegistrationForms.signUp.signUp_with_facebook}
          </Button>

          <Button
            width={225}
            type="accent"
            startIcon={<GoogleIcon />}
            onClick={() => onRedirectToAuth("google")}
          >
            {strings.RegistrationForms.signUp.signUp_with_google}
          </Button>
        </div>

        <div className={styles.dividerWrapper}>
          <Divider className="divider" />
          <div className={styles.or}>{strings.RegistrationForms.signIn.or}</div>
        </div>

        <div className={styles.signUpCreateWrapper}>
          <Input
            label={strings.RegistrationForms.signUp.email}
            placeholder={strings.RegistrationForms.signUp.email}
            value={SignUpStore.email}
            onChange={SignUpStore.changeEmail}
          />

          <div className={styles.passwordWrapper}>
            <Input
              label={strings.RegistrationForms.signUp.password}
              placeholder={strings.RegistrationForms.signUp.password}
              type="password"
              value={SignUpStore.password}
              onChange={SignUpStore.changePassword}
            />

            <Input
              label={strings.RegistrationForms.signUp.repeat_password}
              placeholder={strings.RegistrationForms.signUp.repeat_password}
              type="password"
              value={SignUpStore.repeatPassword}
              onChange={SignUpStore.changeRepeatPassword}
            />
          </div>
        </div>
      </div>

      <div className={styles.footerWrapper}>
        <div>
          <span className={styles.registeringDescription}>
            {strings.formatString(
              strings.RegistrationForms.signUp.registering_description,
              <a
                href={strings.links.terms_of_use}
                target="_blank"
                rel="noreferrer"
              >
                {strings.RegistrationForms.signUp.terms_of_service}
              </a>,
              <a
                href={strings.links.privacy_policy}
                target="_blank"
                rel="noreferrer"
              >
                {strings.RegistrationForms.signUp.privacy_police}
              </a>
            )}
          </span>

          <Button
            width={225}
            type="primary"
            loading={SignUpStore.loading}
            endIcon={
              SignUpStore.loading ? <></> : <ArrowRightIcon fill="white" />
            }
            onClick={onRouteNextStep}
          >
            {strings.RegistrationForms.signUp.signUp_with_email}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default SignUpComponentForm;
