import {action, makeAutoObservable, observable} from "mobx";
import {apiClient} from "../../api/api";
import EventStore from "../EventStore";
import {ITeacher} from "../../api/apiResponseTypes";
import DialogsStore from "../DialogsStore";
import SnackBarStore from "../SnackBarStore";
import strings from "../../../i18n/strings";

class TeachersStore {
    @observable public data?: ITeacher[] | undefined;
    @observable public loading: boolean = false;
    @observable public loadingDelete: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    public fetchList = async () => {
        try {
            this.loading = true;
            this.data = await apiClient.getTeachers(EventStore.eventId);
        } catch (e) {
            console.error("TeachersStore.fetchList", e);
        } finally {
            this.loading = false;
        }
    };

    @action
    public delete = async (userId: number) => {
        try {
            this.loadingDelete = true;
            this.data = await apiClient.deleteTeacher(EventStore.eventId, userId);
            DialogsStore.onClose();
            SnackBarStore.setSnackbar(
                strings.teachers.delete_dialog.success,
                "success"
            );
            this.fetchList()
        } catch (e) {
            console.error("TeachersStore.delete", e);
        } finally {
            this.loadingDelete = false;
        }
    };


}

export default new TeachersStore();
