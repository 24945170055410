import {observer} from 'mobx-react-lite';
import React from 'react';
import strings from '../../../../i18n/strings';
import {Button, Dialog} from '../../../components/common';
import DialogsStore from '../../../store/DialogsStore';

import styles from './TeamDeleteDialog.module.scss';

const TeamNotificationDeleteDialog = observer(() => (
  <Dialog 
    isOpen={DialogsStore.isOpenTeamDeleteNotificationDialog()}
    onClose={DialogsStore.onClose}
    dialogTitle={<div className={styles.title}>{strings.teams.dialogs.notification_delete_title}</div>}
    dialogContent={
      <div className={styles.bodyWrapper}>
        <div>{strings.teams.dialogs.notification_delete_message}</div>

        <div className={styles.footerWrapper}>
          <Button type="primary" onClick={DialogsStore.onClose}>{strings.buttons.ok}</Button>
        </div>
      </div>
    }
  />
))

export default TeamNotificationDeleteDialog;
