import React from "react";
import moment from 'moment';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

import styles from './ReportChart.module.scss';
import { FULL_DATE_FORMAT } from "../../../consts/dateFormats";

export const FundraiserReportChart = ({ data }) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <p className={styles.label}>{`Date: ${moment(payload[0].payload.date).format(FULL_DATE_FORMAT)}`}</p>
          <p className={styles.label}>{`Amount: $${payload[0].payload.amount}`}</p>
        </div>
      );
    }
  
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={160}>
      <BarChart
        data={data}>
        <XAxis 
        dataKey="displayDate" 
        tickLine={{ stroke: 'transporent' }} 
        axisLine={{ stroke: 'transporent' }}
        tick={{fill: styles.tickStroke, fontWeight: 600, fontSize: 12}}
        />
        <Tooltip cursor={{fill: styles.gray}} content={<CustomTooltip />}/>
        <Bar
          dataKey="amount"
          fill={styles.blue}
          stackId="a"
          barSize={12}
          radius={3}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
