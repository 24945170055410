import React, {useState} from 'react';
import {Button} from '../../components/common';

import {ReactComponent as EditIcon} from '../../../images/icons/editPen.svg';
import styles from './MediaContainer.module.scss';
import ModalUploader from './ModalUpload/ModalUploader';

interface IProps {
  type: 'video' | 'image';
  url?: string;
  isLoadingUploadImage?: boolean;
  isLoadingDeleteImage?: boolean;
  isLoadingUploadVideo?: boolean;
  onUploadImage?: (FileList: FileList | null) => void;
  onUploadVideo?: (url?: string) => void;
  onChangeVideoUrl?: (url?: string) => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

const MediaUploader = ({
  type,
  url,
  isLoadingUploadImage,
  isLoadingDeleteImage,
  isLoadingUploadVideo,
  onDelete,
  onUploadImage,
  onChangeVideoUrl,
  onUploadVideo,
  onCancel
}: IProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.mediaUploader}>
      {type === 'video'
        ? (
          <Button
            type="primary"
            onClick={() => setOpen(true)}
            componentType='iconButton'
          >
            <EditIcon fill="white"/>
          </Button>
        )
        : (
          <Button
            type="primary"
            onClick={() => setOpen(true)}
            onUpload={onUploadImage}
            componentType='iconButton'
          >
            <EditIcon fill="white"/>
          </Button>
        )
      }

      <ModalUploader
        open={open}
        type={type}
        url={url}
        isLoadingUploadImage={isLoadingUploadImage}
        isLoadingDeleteImage={isLoadingDeleteImage}
        onDelete={onDelete}
        handleClose={() => setOpen(false)}
        onUploadImage={onUploadImage}
        onChangeVideoUrl={onChangeVideoUrl}
        onUploadVideo={onUploadVideo}
        onCancel={onCancel}
        isLoadingUploadVideo={isLoadingUploadVideo}
      />
    </div>
  )
}

export default MediaUploader;
