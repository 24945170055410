import React from 'react';
import {observer} from 'mobx-react-lite';
import strings from '../../../../i18n/strings';
import {Button, Input} from '../../../components/common';

import styles from './MiniForms.module.scss';
import AccountSettingsStore from '../../../store/AccountSettingsStore';

const EmailForm = observer(() => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.bodyWrapper}>
        <Input
          width={200}
          label={strings.account_settings.email}
          placeholder={strings.account_settings.email}
          value={AccountSettingsStore.data.email}
          onChange={(val) => AccountSettingsStore.handleChangeData(val, 'email')}
        />
      </div>

      <div className={styles.footerWrapper}>
        <Button
          type='primary'
          width={90}
          onClick={AccountSettingsStore.saveEmail}
          loading={AccountSettingsStore.loading}

        >
          {strings.buttons.save}
        </Button>

        <Button
          disabled={AccountSettingsStore.loading}
          onClick={() => AccountSettingsStore.clearState()}>
          {strings.buttons.cancel}
        </Button>
      </div>
    </div>
  )
})

export default EmailForm;
