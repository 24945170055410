import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import strings from "../../../i18n/strings";
import {Button, Loader, Tooltip} from "../../components/common";
import {ReactComponent as ArrowRightIcon} from "../../../images/icons/arrows/arrow-right.svg";
import {ReactComponent as HelperIcon} from "../../../images/icons/helper.svg";
import EventStore from "../../store/EventStore";
import {useHistory} from "react-router-dom";

import styles from "./Overview.module.scss";
import FundraiserPaidWidget from "./FundraiserReportWidgets/FundraiserPaidWidget";
import {ReactComponent as DownloadIcon} from "../../../images/icons/students/download.svg";
import FundraiserEmptyWidget from "./FundraiserReportWidgets/FundraiserEmptyWidget";
import FundraiserUnpaidWidget from "./FundraiserReportWidgets/FundraiserUnpaidWidget";
import ReadingFullWidget from "./ReadingReportWidgets/ReadingFullWidget";
import ReadingEmptyWidget from "./ReadingReportWidgets/ReadingEmptyWidget";
import ReadingUnfullWidget from "./ReadingReportWidgets/ReadingUnfullWidget";
import {Path} from "../../consts/router";
import DonationsStore from "../../store/DonationsStore";
import DownloadReportModal from "./ReadingReportWidgets/DownloadReportModal";
import UserStore from "../../store/UserStore";
import OverviewStore from "../../store/OverviewStore";

const Overview = observer(() => {
    const history = useHistory();
    const [downloadReportDialogOpen, setDownloadReportDialogOpen] = useState(false);

    useEffect(() => {
        OverviewStore.fetchPaymentItemsList();
        OverviewStore.fetchReadingItemsList();
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="screenWrapper">


            <div className="content">
                <>


                    {UserStore.user?.role === 'EA' &&
                        <div className="contentWithShadowWrapper" style={{minHeight: 350}}>
                            <div className={styles.headerWrapper}>
                                <div className={styles.titleWrapper}>
                  <span className={styles.title}>
                    {strings.overview.fundraiser_report}
                  </span>
                                    <Tooltip title={strings.overview.tips.fundraiser_report}>
                                        <HelperIcon fill={styles.blue}/>
                                    </Tooltip>
                                </div>

                                {EventStore.event?.accountId &&
                                EventStore.event.donationsCount > 0 ? (
                                    <div>
                                        <Button
                                            className={styles.downloadButton}
                                            startIcon={<DownloadIcon fill={styles.blue}/>}
                                            borderColor="blue"
                                            border
                                            onClick={DonationsStore.exportDonations}
                                        >
                                            {strings.donations.download_csv}
                                        </Button>

                                        <Button
                                            type="primary"
                                            endIcon={<ArrowRightIcon/>}
                                            onClick={() => history.push(Path.donations)}
                                        >
                                            {strings.overview.all_donations}
                                        </Button>
                                    </div>
                                ) : EventStore.event?.accountId ? (
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            window.open(`${EventStore.event?.shareLink}/donate`)
                                        }
                                    >
                                        {strings.overview.donate_now}
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </div>

                            {EventStore.loading || !EventStore.event ? (
                                    <Loader/>
                                ) :
                                <>
                                    {EventStore.event?.accountId &&
                                    EventStore.event.donationsCount > 0 ? (
                                        <FundraiserPaidWidget/>
                                    ) : EventStore.event?.accountId ? (
                                        <FundraiserUnpaidWidget/>
                                    ) : (
                                        <FundraiserEmptyWidget/>
                                    )
                                    }
                                </>
                            }
                        </div>}

                    {UserStore.user?.role === 'TL' &&
                        <div className="contentWithShadowWrapper" style={{minHeight: 250}}>
                            <div className={styles.headerWrapper}>
                                <div className={styles.titleWrapper}>
                                  <span className={styles.title}>
                                    {strings.overview.teacher.title}
                                  </span>
                                </div>
                            </div>
                            <br/>
                            <div className={styles.summary}>
                                {strings.overview.teacher.text}
                            </div>
                            <div>
                                <br/>
                                <Button
                                    type="primary"
                                    onClick={() => history.push(Path.teams)}
                                >
                                    {strings.teams.head.title}
                                </Button>
                            </div>
                        </div>
                    }
                </>
            </div>


            {UserStore.user?.role === 'EA' || OverviewStore.readingItems?.length ?
                <div className="content">
                    {EventStore.loading || !EventStore.event ? (
                        <Loader/>
                    ) : (
                        <>
                            <div className="contentWithShadowWrapper">
                                <div className={styles.headerWrapper}>
                                    <div className={styles.titleWrapper}>
                  <span className={styles.title}>
                    {strings.overview.reading_report}
                  </span>
                                        <Tooltip
                                            title={strings.overview.tips.reading_report}
                                            bgColor="purple"
                                        >
                                            <HelperIcon fill={styles.purple}/>
                                        </Tooltip>
                                    </div>


                                    {EventStore.event?.membersCount !== 0 ? (
                                        <div>

                                            <DownloadReportModal open={downloadReportDialogOpen}
                                                                 eventCreated={EventStore.event?.created}
                                                                 onClose={() => setDownloadReportDialogOpen(false)}/>

                                            <Button
                                                border
                                                className={styles.downloadButton}
                                                startIcon={<DownloadIcon fill={styles.purple}/>}
                                                borderColor="purple"
                                                textColor="purple"
                                                onClick={() => setDownloadReportDialogOpen(true)}
                                            >
                                                {strings.overview.student_report}
                                            </Button>

                                            <Button
                                                type="purple"
                                                endIcon={<ArrowRightIcon/>}
                                                onClick={() => history.push(Path.students)}
                                            >
                                                {strings.overview.student_leaderboard}
                                            </Button>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>

                                {EventStore.event?.membersCount !== 0 &&
                                EventStore.event?.minsSum ? (
                                    <ReadingFullWidget/>
                                ) : EventStore.event?.membersCount !== 0 ? (
                                    <ReadingUnfullWidget/>
                                ) : (
                                    <ReadingEmptyWidget/>
                                )}
                            </div>
                        </>
                    )}
                </div> : ''}


        </div>
    );
});

export default Overview;
