import React from "react";
import { observer } from "mobx-react-lite";
import styles from "./Announcement.module.scss";
import strings from "../../../i18n/strings";
import { Checkbox, Input, TextArea } from "../../components/common";
import AnnouncementStore from "../../store/AnnouncementStore";
import TeamMembersStore from "../../store/TeamsStores/TeamMembersStore";
import DonationsStore from "../../store/DonationsStore";
import TeachersStore from "../../store/TeamsStores/TeachersStore";

const AnnouncementForm = observer(() => (
  <div>
    <div className={styles.emailContentWrapper}>
      <div className={styles.textFieldsWrapper}>
        <Input
          label={strings.announcement.subject}
          value={AnnouncementStore.subject}
          onChange={AnnouncementStore.onChangeSubject}
        />

        <TextArea
          label={strings.announcement.message}
          rows={6}
          className={styles.messageField}
          value={AnnouncementStore.message}
          onChange={AnnouncementStore.onChangeMessage}
        />
      </div>
    </div>

    <div className={styles.recepientsWrapper}>
      <span className={styles.title}>{strings.announcement.recepients}</span>

      <div className={styles.checkBoxWrapper}>
        <Checkbox
          checked={AnnouncementStore.isCheckedMe}
          onChange={AnnouncementStore.onCheckMe}
          text={strings.announcement.me_recepient}
          classNameText={styles.text}
        />

        <Checkbox
          checked={AnnouncementStore.isCheckedTeachers}
          onChange={AnnouncementStore.onCheckTeachers}
          text={strings
            .formatString(
              strings.announcement.teachers_recepients,
              TeachersStore.data?.length || 0
            )
            .toString()}
          classNameText={styles.text}
        />


        <Checkbox
          checked={AnnouncementStore.isCheckedStudents}
          onChange={AnnouncementStore.onCheckStudents}
          text={strings
            .formatString(
              strings.announcement.students_recepients,
              TeamMembersStore.data?.length || 0
            )
            .toString()}
          classNameText={styles.text}
        />

        <Checkbox
          checked={AnnouncementStore.isCheckedDonors}
          onChange={AnnouncementStore.onCheckDonors}
          text={strings.formatString(
            strings.announcement.donors_recepients,
            DonationsStore.getDonorsCount()
          ).toString()}
          classNameText={styles.text}
        />
      </div>
    </div>
  </div>
));

export default AnnouncementForm;
