import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import EventStore from "../../../store/EventStore";
import UserStore from "../../../store/UserStore";

import logo from "../../../../images/png/logo-black.png";
import styles from "./PrintCodes.module.scss";
import strings from "../../../../i18n/strings";
import SnackBarStore from "../../../store/SnackBarStore";

const PrintCodesLetter = observer(() => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteCode = urlParams.get("code");

    EventStore.fetchEventDataByInviteCode(inviteCode || "")
      .then((response) => {
        if (response === "success") {
          setTimeout(() => {
            window.print();
          }, 3000);
        } else {
          SnackBarStore.setSnackbar(
            strings
              .formatString(strings.letter.error_invite_code, inviteCode || "")
              .toString(),
            "error"
          );
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div id="print" className={styles.wrapper}>
      <div className={styles.printContent}>
        <h1>
          <img src={logo} alt="logo" />
        </h1>

        <br />
        <p className={`${styles.title} ${styles.schoolName}`}>
          {strings.letter.title}
        </p>

        <p className={styles.description}>
          {strings.formatString(
            strings.letter.description1,
            <span>{EventStore.event?.schoolName}</span>
          )}
        </p>

        <p className={styles.description}>
          {strings.formatString(
            strings.letter.description2,
            <span>{EventStore.event?.schoolName}</span>
          )}
        </p>

        <p className={styles.description}>{strings.letter.description3}</p>

        <p className={styles.description}>
          {strings.formatString(
            strings.letter.description41,
            <b>{EventStore.event?.readingMins.toLocaleString()}</b>
          )}{" "}
          <br />
          {strings.formatString(
            strings.letter.description42,
            <b>{`${
              EventStore.event?.currency === "USD" ? "$" : "£"
            }${EventStore.event?.fundraising.toLocaleString()}`}</b>
          )}
        </p>

        <p className={styles.description}>
          {strings.letter.description51}
          <br />
          {strings.formatString(
            strings.letter.description52,
            <b>{EventStore.event?.name}</b>
          )}
          <br />
          {strings.formatString(
            strings.letter.description53,
            <b style={{ color: "red" }}>{EventStore.event?.inviteCode}</b>
          )}
        </p>

        <p className={styles.title}>
          {strings.letter.title2}
          <br />
          <br />
        </p>

        <p className={styles.description}>
          <img
            src={`/api/v1/events/${EventStore.event?.inviteCode}/qrcode.png`}
            alt=""
            style={{ marginRight: 20, width: 80, height: 80, float: "left" }}
          />
          {strings.letter.description61} <br />
          {strings.letter.description62} <br />
          {strings.formatString(
            strings.letter.description63,
            <a href="https://apps.apple.com/us/app/id1521707966">Apple App</a>,
            <a href="https://play.google.com/store/apps/details?id=com.readformyschool">
              Google Play
            </a>
          )}
          <br />
          <br />
        </p>

        <p style={{ clear: "both" }}></p>

        <br />

        <p className={styles.description}>
          {strings.formatString(
            strings.letter.description7,
            <span>{EventStore.event?.schoolName}</span>,
            <>{UserStore.user?.email}</>
          )}
        </p>

        <p className={styles.description}>
          {strings.formatString(
            strings.letter.description8,
            <span>{EventStore.event?.schoolName}</span>
          )}
        </p>
        <p>
          {strings.letter.footer}
          <br />
          <b>{EventStore.event?.schoolName}</b>
        </p>
      </div>
    </div>
  );
});

export default PrintCodesLetter;
