import {IOption} from "../components/common/Select/Select";

export const language: Array<IOption> = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Spanish',
    value: 'es'
  }
];
