export enum Path {
  main = "/main",
  fundraiser = "/fundraiser",
  fundraiserInvite = "/fundraiser/invite",
  fundraiserInviteLetter = "/fundraiser/invite-letter",

  teams = "/teams",
  teamsEdit = "/teams/:id",
  teamsCreate = "/teams/new",

  teachers = "/teachers",

  students = "/students",
  studentsEdit = "/students/:id",

  donations = "/donations",
  donation_page = "./donation-page",

  announcement = "/announcement",

  account = "/account",
  login = "/login",

  signIn = "/sign-in",
  signUp = "/sign-up/create-account",
  signUpPricingModel = "/sign-up/pricing-model",
  signUpSchoolInfo = "/sign-up/school-info",
  signUpFundraiserInfo = "/sign-up/fundraiser-info",
  signUpProfileInfo = "/sign-up/profile-info",
  signUpFinish = "/sign-up/finish",

  fogotPassword = "/sign-in/forgot-password",
}
