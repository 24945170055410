import React from 'react';
import {observer} from "mobx-react-lite";
import {Divider} from '@material-ui/core';
import strings from '../../../../i18n/strings';
import EventStore from '../../../store/EventStore';
import {Button} from '../../../components/common';

import {ReactComponent as StripeIcon} from '../../../../images/icons/stripe.svg';
import {ReactComponent as CheckCircleIcon} from '../../../../images/icons/checkCircle.svg';
import styles from '../Overview.module.scss';


const StripeStatus = observer(() => (
  <div className={styles.stripeStatusWrapper}>
    <Divider className={styles.divider} />

    <div className={styles.stripeWrapper}>
      <div className={styles.flexWrapper}>
        <StripeIcon />

        <div className={styles.statusWrapper}>
          <span className={styles.title}>{strings.overview.status}</span>
          <CheckCircleIcon />
          <span className={styles.value}>{strings.overview.connected}</span>
        </div>

        <div className={styles.accountWrapper}>
          <span className={styles.title}>{strings.overview.account}</span>
          <span className={styles.value}>{EventStore.event?.stripeAccount?.displayName}</span>
        </div>
      </div>

      <a target="_blank" rel="noreferrer" href="https://dashboard.stripe.com/dashboard">
        <Button width={190} border>
          {strings.overview.stripe_account}
        </Button>
      </a>
    </div>
  </div>
))

export default StripeStatus;
