import { action, computed, makeAutoObservable, observable } from "mobx";
import strings from "../../i18n/strings";
import { apiClient } from "../api/api";
import { AccountMiniForm } from "../consts/accountSettings";
import { language } from "../consts/language";
import { LANGUAGE } from "../consts/localStorage";
import { IAccountSettingsForm } from "../interfaces/IAccountSettings";
import SnackBarStore from "./SnackBarStore";
import UserStore from "./UserStore";

class AccountSettingsStore {
  @observable public editedForm?: AccountMiniForm;

  @observable public data: IAccountSettingsForm = {};

  @observable public loading: boolean = false;

  //TODO разнести по разным сторам надо будет
  @observable public avatarLoading: boolean = false;

  @observable public language: string = localStorage.getItem(LANGUAGE) || "en";

  @observable public isOpenAvatarChangeDialog: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  public visibleEditMode = (name: AccountMiniForm) => this.editedForm === name;

  @computed
  public getFullName = () =>
    `${this.data.firstName || ""} ${this.data.lastName || ""}`;

  @computed
  public getLanguage = () =>
    language.find((lang) => lang.value === this.language)?.label;

  @action.bound
  setLanguage = (value: string) => {
    this.language = value;
    localStorage.setItem(LANGUAGE, value);
    window.location.reload();
  };

  @action.bound
  public selectEditedForm = (name: AccountMiniForm) => {
    if (this.editedForm === name) {
      this.clearState();
      return;
    }

    this.editedForm = name;
    this.onMountData();
  };

  @action.bound
  public handleOpenAvatarsDialog = () => (this.isOpenAvatarChangeDialog = true);

  @action.bound
  public handleCloseAvatarsDialog = () =>
    (this.isOpenAvatarChangeDialog = false);

  @action.bound
  public onMountData = () => {
    this.data.displayName = UserStore.user?.displayName;
    this.data.firstName = UserStore.user?.firstName;
    this.data.lastName = UserStore.user?.lastName;
    this.data.email = UserStore.user?.email;
    this.data.password = "";
    this.data.repeatPassword = "";
  };

  @action.bound
  public clearState = () => {
    this.editedForm = undefined;
    this.isOpenAvatarChangeDialog = false;
    // this.onMountData();
  };

  @action.bound
  public handleChangeData = (
    value: string,
    key: keyof IAccountSettingsForm
  ) => {
    this.data[key] = value;
  };

  @action.bound
  public saveSettings = async () => {
    this.loading = true;
    const formData = new FormData();
    let alertText = "";

    if (this.editedForm === AccountMiniForm.FULL_NAME) {
      formData.append("firstName", this.data.firstName!);
      formData.append("lastName", this.data.lastName!);
      alertText = strings.account_settings.alerts.fullName_change_success;
    }

    if (this.editedForm === AccountMiniForm.DISPLAY_NAME) {
      formData.append("displayName", this.data.displayName!);
      alertText = strings.account_settings.alerts.displayName_change_success;
    }

    try {
      const response = await apiClient.updateUser(
        UserStore.user?.id!,
        formData
      );
      await UserStore.fetchData();

      this.loading = false;
      SnackBarStore.setSnackbar(alertText, "success");

      if (response) {
        this.clearState();
      }
    } catch (e) {
      console.error("AccountSettingsStore.saveFullName", e);
    } finally {
      this.loading = false;
    }
  };

  @action.bound
  public saveAvatar = async (url: string) => {
    this.avatarLoading = true;
    try {
      const formData = new FormData();
      formData.append("avatar", url);
      this.handleCloseAvatarsDialog();

      await apiClient.updateUser(UserStore.user?.id!, formData);
      SnackBarStore.setSnackbar(
        strings.account_settings.alerts.avatar_change_success,
        "success"
      );

      await UserStore.fetchData();
    } catch (e) {
      console.error("AccountSettingsStore.saveAvatar", e);
    } finally {
      this.avatarLoading = false;
    }
  };

  @action.bound
  public saveEmail = async () => {
    this.loading = true;

    try {
      const formData = new FormData();
      formData.append("email", this.data.email!);
      const response = await apiClient.updateEmail(formData);
      await UserStore.fetchData();

      this.loading = false;

      if (response) {
        this.clearState();
      }
    } catch (e) {
      console.error("AccountSettingsStore.saveEmail", e);
    } finally {
      this.loading = false;
    }
  };

  @action.bound
  public savePassword = async () => {
    if (this.data.password !== this.data.repeatPassword) {
      SnackBarStore.setSnackbar(
        strings.account_settings.error_pass_confirm,
        "error"
      );

      return;
    }

    try {
      this.loading = true;
      const formData = new FormData();
      formData.append("password", this.data.password!);
      const response = await apiClient.updatePassword(formData);

      this.loading = false;

      if (response) {
        this.clearState();
      }
    } catch (e) {
      console.error("AccountSettingsStore.savePassword", e);
    } finally {
      this.loading = false;
    }
  };
}

export default new AccountSettingsStore();
