import {action, computed, makeAutoObservable, observable} from "mobx";
import strings from "../../i18n/strings";
import {apiClient} from "../api/api";
import {IDonationsResponse} from "../api/apiResponseTypes";
import DialogsStore from "./DialogsStore";
import EventStore from "./EventStore";
import SnackBarStore from "./SnackBarStore";
import TeamMembersStore from "./TeamsStores/TeamMembersStore";

class DonationsStore {
    @observable public loading: boolean = false;
    @observable public loadingDeleteComment: boolean = false;

    @observable public data: IDonationsResponse[] = [];
    @observable public filteredData: IDonationsResponse[] = [];

    @observable public filterByNameOrEmail?: string;
    @observable public filterByTeamId?: number | string = "Any";
    @observable public filterByCorpMatching?: string = "Any"

    @observable public selectedItem?: IDonationsResponse;

    constructor() {
        makeAutoObservable(this);
    }

    @computed
    public fetchDataList = async () => {
        try {
            this.loading = true;
            this.setUrlParams();
            let data: IDonationsResponse[] = await apiClient.getDonations(
                EventStore.eventId
            );

            this.data = data;
            this.filteredData = data;
            this.getFilteredData();
        } catch (e) {
            console.error("DonationsStore.fetchdataList", e);
        } finally {
            this.loading = false;
        }
    };

    @computed
    public setUrlParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const searchBy = urlParams.get("searchBy");
        const teamId = urlParams.get("teamId");

        this.filterByNameOrEmail = searchBy?.replace("+", " ") || "";
        this.filterByTeamId = teamId || "";
    };

    @computed
    public exportDonations = async () => {
        try {
            const data = await apiClient.getDonationsCsv(EventStore.eventId);
            saveAs(
                new Blob([data], {type: "text/csv"}),
                `members_${EventStore.event?.name
                    .replace(/\s+/g, "-")
                    ?.toLowerCase()}.csv`
            );
        } catch (e) {
            console.error("DonationsStore.exportDonations", e);
        }
    };

    @computed
    public getTotal = () => {
        return this.data.reduce((total, p) => p.amount + total, 0)
    };


    @computed
    public handleChangeFilterByNameOrEmail = (value: string) => {
        this.filterByNameOrEmail = value;
        const url = new URL(window.location.href);

        if (
            url.searchParams.get("searchBy") !== value.toString() &&
            value !== null
        ) {
            url.searchParams.set("searchBy", value.toString());
            window.history.pushState("data", "title", url.href);
        }

        if (!value) {
            url.searchParams.delete("searchBy");
            window.history.pushState("data", "title", url.href);
        }

        this.getFilteredData();
    };

    @computed
    public getDonorsCount = () => {
        const uniqDonors = new Set();
        this.data.map((item) => uniqDonors.add(item.donorEmail));

        return uniqDonors.size;
    };

    @action.bound
    public handleChangeFilterByTeamId = (value: string | number) => {
        this.filterByTeamId = value || "Any";
        const url = new URL(window.location.href);

        if (
            value &&
            url.searchParams.get("teamId") !== value.toString() &&
            value !== "Any"
        ) {
            url.searchParams.set("teamId", value.toString());
            window.history.pushState("data", "title", url.href);
        }

        if (value === "Any") {
            url.searchParams.delete("teamId");
            window.history.pushState("data", "title", url.href);
        }

        this.getFilteredData();
    };

    @action.bound
    public handleChangeFilterByCorpMatching = (value: string | undefined) => {
        this.filterByCorpMatching = value || "Any";
        this.getFilteredData();
    };


    @action.bound
    public clearAllFilters = () => {
        this.handleChangeFilterByNameOrEmail("");
        this.handleChangeFilterByCorpMatching("Any");
    };

    @action.bound
    public selectItem = (item: IDonationsResponse) => (this.selectedItem = item);

    @action.bound
    public onDeleteDonorComment = async () => {
        try {
            this.loadingDeleteComment = true;
            await apiClient.deleteDonorComment(
                EventStore.eventId.toString(),
                this.selectedItem?.id.toString()!
            );

            SnackBarStore.setSnackbar(
                strings.donations.dialog_confirm_delete_comment.success_deleted,
                "success"
            );
            DialogsStore.onClose();
        } catch (e) {
            console.error("DonationsStore.onDeleteDonorComment", e);
        } finally {
            this.loadingDeleteComment = false;
        }
    };

    @action.bound
    public getFilteredData = () => {
        this.setUrlParams();
        let filteredData = this.data.filter((item) => {
            if (this.filterByNameOrEmail) {
                return (
                    item.donorEmail?.toLowerCase() ===
                    this.filterByNameOrEmail?.toLowerCase() ||
                    item.donorName
                        .toLocaleLowerCase()
                        .startsWith(this.filterByNameOrEmail?.toLowerCase()) ||
                    item.beneficiary?.displayName
                        .toLocaleLowerCase()
                        .startsWith(this.filterByNameOrEmail?.toLowerCase()) ||
                    TeamMembersStore.data
                        .find(
                            (member) =>
                                `${member.user.firstName?.toLowerCase()} ${member.user.lastName?.toLowerCase()}` ===
                                item.beneficiary?.displayName?.toLowerCase()
                        )
                        ?.user.email?.toLowerCase() ===
                    this.filterByNameOrEmail?.toLowerCase()
                );
            }

            return true;
        });

        if (this.filterByTeamId && this.filterByTeamId !== "Any") {
            filteredData = filteredData.filter(
                (item) => item.team?.id.toString() === this.filterByTeamId?.toString()
            );
        }

        if (this.filterByCorpMatching !== "Any") {
            filteredData = filteredData.filter(
                (item) => item.matchCorp === (this.filterByCorpMatching === 'true')
            );
        }

        this.filteredData = filteredData;
    };
}

export default new DonationsStore();
