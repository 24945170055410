import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import TeachersTable from "./TeachersTable/TeachersTable";
import strings from "../../../i18n/strings";
import {Button, Loader, Tooltip} from "../../components/common";
import {ReactComponent as CopyIcon} from "../../../images/icons/copy.svg";
import {ReactComponent as HelperIcon} from "../../../images/icons/helper.svg";

import styles from "./Teachers.module.scss";
import TeachersStore from "../../store/TeamsStores/TeachersStore";
import SnackBarStore from "../../store/SnackBarStore";
import EventStore from "../../store/EventStore";
import moment from "moment";
import TeamStore from "../../store/TeamsStores/TeamStore";

const Teachers = observer(() => {
  useEffect(() => {
    TeachersStore.fetchList();
    window.scrollTo(0, 0);
  }, []);

  const inviteLink = strings
    .formatString(
      window.location.host.startsWith('dev-') ?
        strings.students.invite_dialog.link_dev :
        strings.students.invite_dialog.link, `${EventStore.event?.teacherInviteCode}`)
    .toString();
  const copyToBufferLink = () => {
    navigator.clipboard.writeText(inviteLink);
    SnackBarStore.setSnackbar(
      strings.students.invite_dialog.copy_success,
      "success"
    );
  };

  return (
    <div className="screenWrapper">
      <div className="content">
        <div className="contentWrapper">
          <div className={styles.headerWrapper}>
            <div className={styles.pageTitleWrapper}>
              <div className={styles.titleWithTooltip}>
                <span className={styles.title}>{strings.teachers.head.title}</span>
                <Tooltip
                  title={strings.teachers.head.tooltip}
                  link={strings.teachers.head.tooltip_link}
                >
                  <HelperIcon fill={styles.blueMain}/>
                </Tooltip>
              </div>
              <span className={styles.subTitle}>{strings.teachers.head.sub_title}</span>

            </div>

              {TeamStore.items.length > 0 &&
                  <div className={styles.buttons}>
                      <Button
                          type="primary"
                          startIcon={<CopyIcon/>}
                          onClick={copyToBufferLink}
                      >
                          {strings.teachers.head.invite_btn}
                      </Button>

                      <span className={styles.subTitle}>{strings.teachers.head.link_expires} <strong
                          className={styles.expiresAt}>{moment(EventStore.event?.teacherInviteExpiresAt).fromNow()}.
                            </strong></span>
                  </div>}
          </div>
        </div>

        <div className={styles.tableWrapper}>
          {TeachersStore.loading ? <Loader/> : <TeachersTable/>}
        </div>
      </div>
    </div>
  );
});

export default Teachers;
