import {action, makeAutoObservable, observable} from "mobx";


class SnackBarStore {
  @observable public isOpenSnackBar: boolean = false;

  @observable public snackBarSeverity?: 'error' | 'info' | 'success' | 'warning';

  @observable public snackBarAlertText?: string;

  constructor() {
    makeAutoObservable(this)
  }

  @action.bound
  public setSnackbar = (alertText: string, type?: 'error' | 'info' | 'success' | 'warning') => {
    this.snackBarSeverity = type;
    this.snackBarAlertText = alertText;
    this.handleShowSnackBar();
  }

  @action.bound
  handleShowSnackBar = () => this.isOpenSnackBar = true;

  @action.bound
  handleCloseSnackBar = () => {
    this.isOpenSnackBar = false;
    this.snackBarAlertText = undefined;
  }
}

export default new SnackBarStore();
