import LocalizedStrings from "react-localization";
import {LANGUAGE} from "../js/consts/localStorage";
import en from "./strings_en.json";
import es from "./strings_es.json";

function createLocalesStrings() {
  let paramLang = new URLSearchParams(window.location.search).get("lang");
  if (paramLang) localStorage.setItem(LANGUAGE, paramLang);

  var storedLang = localStorage.getItem(LANGUAGE);
  let strings = new LocalizedStrings({en, es});
  strings.setLanguage(storedLang ? storedLang : 'en');
  console.log("created LocalizedStrings (" + storedLang + ")")
  return strings;
}

const strings = createLocalesStrings();

export default strings;