import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import FundraiserDetailsView from "./FundraiserDetails/FundraiserDetailsView";
import FundraiserDetailsEdit from "./FundraiserDetails/FundraiserDetailsEdit";
import { FundraiserOptions } from "./FundraiserOptions/FundraiserOptions";
import EventStore from "../../store/EventStore";
import { FundraserGoalsView } from "./FundraiserGoals/FundraiserGoalsView";
import { FundraserGoalsEdit } from "./FundraiserGoals/FundraiserGoalsEdit";
import FundraiserDetailsStore from "../../store/FundraiserStores/FundraiserDetailsStore";
import FundraiserGoalsStore from "../../store/FundraiserStores/FundraiserGoalsStore";
import FundraiserOptionsStore from "../../store/FundraiserStores/FundraiserOptionsStore";
import FundraiserClose from "./FundraiserClose/FundraiserClose";
import DialogsStore, { Dialogs } from "../../store/DialogsStore";
import FundraiserCloseConfirmDialog from "./FundraiserDialogs/FundraiserCloseConfirmDialog";
import FundraiserCloseStore from "../../store/FundraiserStores/FundraiserCloseStore";

const Fundraiser = observer(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isShowCloseBlock =
    FundraiserGoalsStore.isEditMode || FundraiserDetailsStore.isEditMode;

  const onOpenConfirmCloseFundraiserDialog = () =>
    DialogsStore.setActiveDialog(Dialogs.FUNDRAISER_CLOSE_CONFIRM);

  const onConfirmCloseFundraiser = async () => {
    await FundraiserCloseStore.onCloseFundraiser();
    window.scrollTo(0, 0);
  }

  const isClosedFundraiser = EventStore.event?.closed;

  return (
    <div className="screenWrapper">
      {EventStore.event && (
        <>
          <div className="content">
            <div className="contentWithShadowWrapper">
              {FundraiserDetailsStore.isEditMode ? (
                <FundraiserDetailsEdit
                  onChangeViewMode={
                    FundraiserDetailsStore.onSwitchDeteilsEditMode
                  }
                  eventData={EventStore.event}
                  isLoading={FundraiserDetailsStore.isLoading}
                  onSave={FundraiserDetailsStore.updateEventData}
                />
              ) : (
                <FundraiserDetailsView
                  isClosed={isClosedFundraiser}
                  onChangeViewMode={
                    FundraiserDetailsStore.onSwitchDeteilsEditMode
                  }
                  eventData={EventStore.event}
                />
              )}
            </div>
          </div>

          <div className="content">
            <div className="contentWrapper">
              {FundraiserGoalsStore.isEditMode ? (
                <FundraserGoalsEdit
                  data={EventStore.event}
                  onChangeViewMode={FundraiserGoalsStore.onSwitchGoalsEditMode}
                  onSave={FundraiserGoalsStore.updateGoals}
                  isLoading={FundraiserGoalsStore.isLoading}
                />
              ) : (
                <FundraserGoalsView
                  isClosed={isClosedFundraiser}
                  data={EventStore.event}
                  onChangeViewMode={FundraiserGoalsStore.onSwitchGoalsEditMode}
                />
              )}
            </div>
          </div>

          <div className="content">
            <div className="contentWrapper">
              <FundraiserOptions
                isClosed={isClosedFundraiser}
                eventData={EventStore.event}
                onChange={FundraiserOptionsStore.updateOptions}
              />
            </div>
          </div>

          {isShowCloseBlock && (
            <div className="content">
              <div className="contentWrapper">
                <FundraiserClose
                  onCloseClick={onOpenConfirmCloseFundraiserDialog}
                />
              </div>
            </div>
          )}
        </>
      )}

      <FundraiserCloseConfirmDialog
        loading={FundraiserCloseStore.loading}
        isOpen={DialogsStore.isOpenFundraiserCloseConfirmDialog()}
        onConfirm={onConfirmCloseFundraiser}
        onClose={DialogsStore.onClose}
      />
    </div>
  );
});

export default Fundraiser;
