import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react-lite";
import TeamsTable from "./TeamsTable/TeamsTable";
import strings from "../../../i18n/strings";
import {Button, Loader, Tooltip} from "../../components/common";
import {ReactComponent as PlusIcon} from "../../../images/icons/plus.svg";
import {ReactComponent as HelperIcon} from "../../../images/icons/helper.svg";
import {ReactComponent as TeachersIcon} from "../../../images/menuIcons/teachers.svg";

import styles from "./Teams.module.scss";
import {Path} from "../../consts/router";
import AppStore from "../../store/AppStore";
import TeamStore from "../../store/TeamsStores/TeamStore";
import UserStore from "../../store/UserStore";
import EventStore from "../../store/EventStore";

const Teams = observer(() => {
    useEffect(() => {
        AppStore.setLayout();
        TeamStore.fetchItemsList();
        window.scrollTo(0, 0);
    }, []);
    const history = useHistory();

    const routeCreateTeam = () => {
        history.push(Path.teamsCreate);
    };

    return (
        <div className="screenWrapper">
            <div className="content">
                <div className="contentWrapper">
                    <div className={styles.headerWrapper}>
                        <div className={styles.pageTitleWrapper}>
                            <div className={styles.titleWithTooltip}>
                                <span className={styles.title}>{strings.teams.head.title}</span>
                                <Tooltip
                                    title={strings.teams.head.tooltip}
                                    link={strings.teams.head.tooltip_link}
                                >
                                    <HelperIcon fill={styles.blueMain}/>
                                </Tooltip>
                            </div>
                            <span className={styles.subTitle}>{strings.teams.head.sub_title}</span>
                        </div>

                        <div className={styles.headerControls}>
                        {UserStore.user?.role === 'EA' && EventStore.event?.id  &&
                            <Button
                                border

                                startIcon={<TeachersIcon  className={styles.teacherIcon}/>}
                                onClick={() => history.push(Path.teachers)}
                            >
                                {strings.teachers.head.title}
                            </Button>}

                        {UserStore.user?.role === 'EA' &&
                            <Button
                                type="primary"
                                startIcon={<PlusIcon/>}
                                onClick={routeCreateTeam}
                            >
                                {strings.teams.head.create_btn}
                            </Button>}
                        </div>
                    </div>
                </div>

                <div className={styles.tableWrapper}>
                    {TeamStore.loading ? <Loader/> : <TeamsTable/>}
                </div>
            </div>
        </div>
    );
});

export default Teams;
