import { action, computed, makeAutoObservable, observable } from "mobx";
import { clone, isEqual } from "lodash";
import { apiClient } from "../../api/api";
import { ITeamMembersResponse } from "../../api/apiResponseTypes";
import { RegistrationTypeEnum, SortColEnum, SortDirectionEnum, } from "../../consts/TeamMembers";
import { saveAs } from "file-saver";
import EventStore from "../EventStore";
import DialogsStore from "../DialogsStore";
import SnackBarStore from "../SnackBarStore";
import strings from "../../../i18n/strings";

class TeamMembersStore {
  @observable public data: ITeamMembersResponse[] = [];
  @observable public filteredData: ITeamMembersResponse[] = [];
  @observable public loading: boolean = false;
  @observable public isExportLoading: boolean = false;
  @observable public registred?: number;
  @observable public filterByName?: string;
  @observable public filterByRegistrationType: RegistrationTypeEnum =
    RegistrationTypeEnum.ANY;
  @observable public filterByTeamId: number | string = "Any";
  @observable public filterByAchievementId: number | string = "Any";
  @observable public filterTeamLabel: string = "Any";
  @observable public activeSortCol?: SortColEnum = SortColEnum.DONATIONS;
  @observable public sortDirection?: SortDirectionEnum =
    SortDirectionEnum.DESCENDING;
  @observable public selectedIdMember?: number;
  @observable public selectedIdObject?: number;
  @observable public isOpenInviteDialog: boolean = false;
  @observable public loadingDelete: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  public fetchTeamMember = async () => {
    try {
      this.loading = true;
      this.setUrlParams();
      const data = await apiClient.getTeamMembers(EventStore.eventId);

      if (!isEqual(this.data, data)) {
        this.data = data;
        this.filteredData = data;
        this.sortByActiveSortCol();
        this.getFilteredData();
      }
    } catch (e) {
      console.error("TeamMembersStore.fetchTeamMember", e);
    } finally {
      this.loading = false;
    }
  };

  @computed
  public onDeleteStudent = async () => {
    try {
      this.loadingDelete = true;
      await apiClient.deleteStudentById(this.selectedIdMember!);
      this.fetchTeamMember();
      DialogsStore.onClose();
      SnackBarStore.setSnackbar(
        strings.students.alerts.student_delete_success,
        "success"
      );
    } catch (e) {
      console.error("StudentStore.onDeleteStudent", e);
    } finally {
      this.loadingDelete = false;
    }
  };

  @computed
  public clearFilters = () => {
    this.filterByTeamId = 'Any';
    this.filterByName = '';
  }

  @computed
  public getRegistredCount = (): number =>
    this.data?.reduce((acc, cur) => (acc = acc + cur.team.targetSize), 0);

  @computed
  public getStudentCount = (): number => this.data?.length;

  @computed
  public getPercentDonationStudents = (): number =>
    (this.data?.filter((item) => item.progress.raisedSum > 0)?.length /
      this.data?.length) *
    100 || 0;

  @computed
  public getPercentReadingStudents = (): number =>
    (this.data?.filter((item) => item.progress.recordsCount > 0)?.length /
      this.data?.length) *
    100 || 0;

  @action.bound
  public deleteUser = (idUser: number) => {
    return `deleted ${idUser}`;
  };

  @action.bound
  public handleChangeFilterByName = (value: string) => {
    this.filterByName = value;
    this.getFilteredData();
  };

  @action.bound
  public handleChangeFilterByRegistrationType = (e: any) => {
    this.filterByRegistrationType = e.target.value;
    this.getFilteredData();
  };

  @action.bound
  public handleChangeFilterByAchievementId = (value: any) => {
    this.filterByAchievementId = value;
    this.getFilteredData();
  };

  @action.bound
  public handleChangeFilterByTeamId = (value: string) => {
    this.filterByTeamId = value || "Any";
    const url = new URL(window.location.href);

    if (
      value &&
      url.searchParams.get("teamId") !== value.toString() &&
      value !== "Any"
    ) {
      url.searchParams.set("teamId", value.toString());
      window.history.pushState("data", "title", url.href);
    }

    if (value === "Any") {
      url.searchParams.delete("teamId");
      window.history.pushState("data", "title", url.href);
    }
    this.getFilteredData();
  };

  @computed
  public setUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const teamId = urlParams.get("teamId");

    this.filterByTeamId = teamId || "";
  };

  @computed
  public getFilteredData = () => {
    let filteredData = clone(this.data);
    this.setUrlParams();

    if (
      this.filterByName ||
      this.filterByRegistrationType !== "Any" ||
      this.filterByAchievementId !== "Any" ||
      (this.filterByTeamId && this.filterByTeamId !== "Any")
    ) {
      if (this.filterByName) {
        filteredData = filteredData?.filter(
          (item) =>
            `${item.user.firstName} ${item.user.lastName}`
              .toLowerCase()
              .indexOf(this.filterByName?.toLocaleLowerCase()!) === 0 ||
            `${item.user.lastName}`.toLowerCase()
              .indexOf(this.filterByName?.toLocaleLowerCase()!) === 0
        );
      }

      if (this.filterByAchievementId && this.filterByAchievementId !== "Any") {
        console.log(this.filterByAchievementId)
        filteredData = filteredData?.filter(
          (item) =>
            item.achievements.includes(Number.parseInt(this.filterByAchievementId+''))
        );
      }

      if (
        this.filterByRegistrationType &&
        this.filterByRegistrationType !== RegistrationTypeEnum.ANY
      ) {
        filteredData = filteredData?.filter((item) => {
          if (
            this.filterByRegistrationType ===
            RegistrationTypeEnum.SELF_REGiSTRATION
          ) {
            return !!item.user.email;
          }

          if (
            this.filterByRegistrationType ===
            RegistrationTypeEnum.MANAGED_PROFILE
          ) {
            return !item.user.email;
          }

          return true;
        });
      }

      if (this.filterByTeamId && this.filterByTeamId !== "Any") {
        filteredData = filteredData?.filter((item) => {
          return item.team.id.toString() === this.filterByTeamId.toString();
        });
      }
    } else {
      filteredData = this.data;
    }

    this.filteredData = filteredData;
    this.sortByActiveSortCol();
  };

  @computed
  public exportTeamMembers = async (from?: number, to?: number) => {
    try {
      this.isExportLoading = true;
      const data = await apiClient.getEventTeamMembersCsv(EventStore.eventId, from, to);
      saveAs(
        new Blob([data], {type: "text/csv"}),
        `report_${EventStore.event?.name
          .replace(/\s+/g, "-")
          .toLowerCase()}.csv`
      );
    } catch (e) {
      console.error("TeamMembersStore.exportTeamMembers", e);
    } finally {
      this.isExportLoading = false;
    }
  };

  @computed
  public selectIdMember = (id: number) => (this.selectedIdMember = id);

  @computed
  public onSelectIdObject = (id: number) => (this.selectedIdObject = id);

  @action.bound
  setActiveSortCol = (col: SortColEnum) => {
    if (this.activeSortCol === col) {
      if (this.sortDirection === SortDirectionEnum.DESCENDING) {
        this.setSortDirection(SortDirectionEnum.ASCENDING);
      } else {
        this.setSortDirection(SortDirectionEnum.DESCENDING);
      }
    } else {
      this.activeSortCol = col;
      this.setSortDirection(SortDirectionEnum.DESCENDING);
    }

    this.sortByActiveSortCol();
  };

  @action.bound
  setSortDirection = (direction: SortDirectionEnum) => {
    this.sortDirection = direction;
  };

  @action.bound
  public sortByActiveSortCol = () => {
    if (this.activeSortCol === SortColEnum.NAME) {
      if (this.sortDirection === SortDirectionEnum.DESCENDING) {
        this.filteredData?.sort((a, b) =>
          `${a.user.firstName} ${a.user.lastName}`.localeCompare(
            `${b.user.firstName} ${b.user.lastName}`
          )
        );
      }

      if (this.sortDirection === SortDirectionEnum.ASCENDING) {
        this.filteredData?.sort((a, b) =>
          `${b.user.firstName} ${b.user.lastName}`.localeCompare(
            `${a.user.firstName} ${a.user.lastName}`
          )
        );
      }
    }

    if (this.activeSortCol === SortColEnum.MINUTES) {
      if (this.sortDirection === SortDirectionEnum.ASCENDING) {
        this.filteredData?.sort(
          (a, b) => a.progress.minsSum - b.progress.minsSum
        );
      }

      if (this.sortDirection === SortDirectionEnum.DESCENDING) {
        this.filteredData?.sort(
          (a, b) => b.progress.minsSum - a.progress.minsSum
        );
      }
    }

    if (this.activeSortCol === SortColEnum.ACHIEVEMENTS) {
      if (this.sortDirection === SortDirectionEnum.ASCENDING) {
        this.filteredData?.sort(
          (a, b) => a.achievements.length - b.achievements.length
        );
      }

      if (this.sortDirection === SortDirectionEnum.DESCENDING) {
        this.filteredData?.sort(
          (a, b) => b.achievements.length - a.achievements.length
        );
      }
    }

    if (this.activeSortCol === SortColEnum.BOOKS) {
      if (this.sortDirection === SortDirectionEnum.ASCENDING) {
        this.filteredData?.sort(
          (a, b) => a.progress.booksSum - b.progress.booksSum
        );
      }

      if (this.sortDirection === SortDirectionEnum.DESCENDING) {
        this.filteredData?.sort(
          (a, b) => b.progress.booksSum - a.progress.booksSum
        );
      }
    }

    if (this.activeSortCol === SortColEnum.DONATIONS) {
      if (this.sortDirection === SortDirectionEnum.ASCENDING) {
        this.filteredData?.sort(
          (a, b) => a.progress.raisedSum - b.progress.raisedSum
        );
      }

      if (this.sortDirection === SortDirectionEnum.DESCENDING) {
        this.filteredData?.sort(
          (a, b) => b.progress.raisedSum - a.progress.raisedSum
        );
      }
    }

    if (this.activeSortCol === SortColEnum.GRADE) {
      if (this.sortDirection === SortDirectionEnum.ASCENDING) {
        this.filteredData?.sort((a, b) => a.team.grade - b.team.grade);
      }

      if (this.sortDirection === SortDirectionEnum.DESCENDING) {
        this.filteredData?.sort((a, b) => b.team.grade - a.team.grade);
      }
    }

    if (this.activeSortCol === SortColEnum.ACTIVE_AT) {
      if (this.sortDirection === SortDirectionEnum.ASCENDING) {
        this.filteredData?.sort(
          (a, b) => (a.user.activeAt || 0) - (b.user.activeAt || 0)
        );
      }

      if (this.sortDirection === SortDirectionEnum.DESCENDING) {
        this.filteredData?.sort(
          (a, b) => (b.user.activeAt || 0) - (a.user.activeAt || 0)
        );
      }
    }
  };

  @action.bound
  public handleOpenInviteDialog = () => (this.isOpenInviteDialog = true);

  @action.bound
  public handleCloseInviteDialog = () => (this.isOpenInviteDialog = false);
}

export default new TeamMembersStore();
